export function extractContentMimeTypeFromData(content: any) {
  const parts = content.split(",");
  const mimeType = parts[0].split(":")[1].split(";")[0];
  return mimeType;
}
export function getFileExtension(filepath: string): string {
  const parts = filepath.split(".");
  return parts.length > 1 ? parts[parts.length - 1].toLowerCase() : "";
}

export function isImageFile(filename: string): boolean {
  const imageExtensions = ["jpg", "jpeg", "png", "gif", "bmp", "webp", "svg"];
  const ext = getFileExtension(filename);
  return imageExtensions.includes(ext);
}

export function getMimeType(filepath: string): string {
  const extension = getFileExtension(filepath);
  switch (extension) {
    // Video types
    case "mp4":
      return "video/mp4";
    case "webm":
      return "video/webm";
    case "ogg":
      return "video/ogg";
    case "mov":
      return "video/quicktime";
    case "avi":
      return "video/x-msvideo";
    case "wmv":
      return "video/x-ms-wmv";
    case "mpeg":
    case "mpg":
      return "video/mpeg";
    case "flv":
      return "video/x-flv";
    case "mkv":
      return "video/x-matroska";

    // Audio types
    case "mp3":
      return "audio/mpeg";
    case "wav":
      return "audio/wav";
    case "aac":
      return "audio/aac";
    case "flac":
      return "audio/flac";
    case "wma":
      return "audio/x-ms-wma";
    case "aiff":
      return "audio/aiff";
    case "m4a":
      return "audio/x-m4a";
    case "wv":
      return "audio/x-wavpack";

    // Image types
    case "jpg":
    case "jpeg":
      return "image/jpeg";
    case "png":
      return "image/png";
    case "gif":
      return "image/gif";
    case "bmp":
      return "image/bmp";
    case "svg":
      return "image/svg+xml";
    case "tiff":
    case "tif":
      return "image/tiff";
    case "webp":
      return "image/webp";

    // Text types
    case "txt":
      return "text/plain";
    case "html":
    case "htm":
      return "text/html";
    case "css":
      return "text/css";
    case "js":
      return "text/javascript";
    case "json":
      return "application/json";
    case "xml":
      return "application/xml";

    default:
      return "application/octet-stream"; // Default MIME type for unknown files
  }
}

export function extractFileExtensionFromData(content: any) {
  //this is faulty but we have to assume until we can figure it out
  let fileExtension;
  const mimeType = extractContentMimeTypeFromData(content);
  // video types
  if (mimeType === "video/mp4") {
    fileExtension = ".mp4";
  } else if (mimeType === "video/webm") {
    fileExtension = ".webm";
  } else if (mimeType === "video/ogg") {
    fileExtension = ".ogg";
  } else if (mimeType === "video/quicktime") {
    fileExtension = ".mov";
  } else if (mimeType === "video/x-msvideo") {
    fileExtension = ".avi";
  } else if (mimeType === "video/x-ms-wmv") {
    fileExtension = ".wmv";
  } else if (mimeType === "video/mpeg") {
    fileExtension = ".mpeg";
  } else if (mimeType === "video/x-flv") {
    fileExtension = ".flv";
  } else if (mimeType === "video/x-matroska") {
    fileExtension = ".mkv";
  }
  // audio types
  else if (mimeType === "audio/mpeg") {
    fileExtension = ".mp3";
  } else if (mimeType === "audio/wav") {
    fileExtension = ".wav";
  } else if (mimeType === "audio/aac") {
    fileExtension = ".aac";
  } else if (mimeType === "audio/ogg") {
    fileExtension = ".ogg";
  } else if (mimeType === "audio/flac") {
    fileExtension = ".flac";
  } else if (mimeType === "audio/mp4") {
    fileExtension = ".mp4";
  } else if (mimeType === "audio/x-ms-wma") {
    fileExtension = ".wma";
  } else if (mimeType === "audio/webm") {
    fileExtension = ".webm";
  } else if (mimeType === "audio/aiff") {
    fileExtension = ".aiff";
  } else if (mimeType === "audio/x-m4a") {
    fileExtension = ".m4a";
  } else if (mimeType === "audio/x-wavpack") {
    fileExtension = ".wv";
  }
  //Image types
  else if (mimeType === "image/jpeg") {
    fileExtension = ".jpg";
  } else if (mimeType === "image/png") {
    fileExtension = ".png";
  } else if (mimeType === "image/gif") {
    fileExtension = ".gif";
  } else if (mimeType === "image/bmp") {
    fileExtension = ".bmp";
  } else if (mimeType === "image/svg+xml") {
    fileExtension = ".svg";
  } else if (mimeType === "image/tiff") {
    fileExtension = ".tiff";
  } else if (mimeType === "image/webp") {
    fileExtension = ".webp";
  }
  //Text types
  else if (mimeType === "text/plain") {
    fileExtension = ".txt";
  } else if (mimeType === "text/html") {
    fileExtension = ".html";
  } else if (mimeType === "text/css") {
    fileExtension = ".css";
  } else if (mimeType === "text/javascript") {
    fileExtension = ".js";
  } else if (mimeType === "application/json") {
    fileExtension = ".json";
  } else if (mimeType === "application/xml") {
    fileExtension = ".xml";
  } else {
    // Default extension if the MIME type is not recognized
    fileExtension = ".unknown";
  }
  return fileExtension;
}
