namespace CoreConstants {
  export const ComfyTokenCacheDurationTimeInMilS = 7 * 60 * 60 * 24 * 1000; // 1 week
  export const S3CacheDurationTimeInMilS = 60 * 60 * 24 * 1000; // 24 hours
  export const S3PrivateCacheDurationTimeInMilS = 60 * 60 * 1 * 1000; // 1 hour
  export const fechUserByIDCacheDurationTimeInMilS = 30 * 1000; // 30 seconds
  export const fechPublicTasksCacheDurationTimeInMilS = 60 * 1000; // 60 seconds
  export const fechTasksCacheDurationTimeInMilS = 5 * 60 * 1000; // 5 minutes
  export const fetchCurrentUserCacheDurationTimeInMilS = 1 * 60 * 1000; // 1 minute
  export const fetchSocialManPostsCacheDurationTimeInMilS = 5 * 60 * 1000; // 5 minutes
}

export default CoreConstants;
