export function getBasePath() {
  if (typeof window !== "undefined")
    return `${window.location.protocol}//${window.location.host}`;
  return "";
}
export function getLoginPath() {
  return `/login`;
}
export function getSignupPath() {
  return `/signup`;
}
export function getHomePath() {
  return `/home`;
}
export function getUserPath(userID: string | undefined = undefined) {
  if (userID == undefined) return "/user";
  return `/user/${userID}`;
}
export function getPresetPath(presetID: string) {
  return `${getHomePath()}/preset/${presetID}`;
}
export function getShareblePresetPath(presetID: string) {
  return `/preset/${presetID}`;
}
export function subscriptionPricingPath() {
  return `/pricing`;
}
export function socialBaseManPath() {
  return `/socialman`;
}
export function socialManHomePath() {
  return `${socialBaseManPath()}/home`;
}
export function socialManPostViewPath(postID: string) {
  return `${socialManHomePath()}/${postID}`;
}
export function socialManPostReViewPath(postID: string) {
  return `${socialManHomePath()}/${postID}/review`;
}
