/* tslint:disable */
/* eslint-disable */
//  This file was automatically generated and should not be edited.

export type User = {
  MLTaskExecutionResults?: ModelMLTaskExecutionResultConnection | null,
  MLTasksWorkspaces?: ModelMLTasksWorkspaceConnection | null,
  ModelInputPresets?: ModelModelInputPresetConnection | null,
  SocialManPosts?: ModelSocialManPostConnection | null,
  completedTasksCount?: number | null,
  createdAt: string,
  createdPresetCount?: number | null,
  credits?: number | null,
  currentSubscriptionPlan?: SubscriptionPlan | null,
  email?: string | null,
  facebook?: FacebookData | null,
  google?: GoogleData | null,
  id: string,
  linkedin?: LinkedinData | null,
  name?: string | null,
  periodEndOfLastSubscriptionCredits?: number | null,
  picture?: string | null,
  pinterest?: PinterestData | null,
  stripeConnectAccountID?: string | null,
  stripeConnectAccountSetupComplete?: boolean | null,
  stripeCustomerID?: string | null,
  tiktok?: TiktokData | null,
  twitter?: TwitterData | null,
  updatedAt: string,
  username?: string | null,
};

export type ModelMLTaskExecutionResultConnection = {
  items:  Array<MLTaskExecutionResult | null >,
  nextToken?: string | null,
};

export type MLTaskExecutionResult = {
  ANGRYReactionStats?: number | null,
  CUTEReactionStats?: number | null,
  FUNNYReactionStats?: number | null,
  GROSSReactionStats?: number | null,
  HEARTReactionStats?: number | null,
  POOPReactionStats?: number | null,
  Reactions?: ModelTaskReactionConnection | null,
  SADReactionStats?: number | null,
  SCARYReactionStats?: number | null,
  WOWReactionStats?: number | null,
  createdAt: string,
  creditsUsed?: number | null,
  id: string,
  inputs?:  Array<TaskExecutionResultInput | null > | null,
  mltasksworkspaceID: string,
  modelName?: string | null,
  modelsID: string,
  output?: TaskExecutionResultOutput | null,
  presetUserInput?: ModelInputPresetInputUserValue | null,
  privacyLevel?: TaskPrivacyLevels | null,
  status?: TaskResultStatus | null,
  taskName?: string | null,
  updatedAt: string,
  userID: string,
};

export type ModelTaskReactionConnection = {
  items:  Array<TaskReaction | null >,
  nextToken?: string | null,
};

export type TaskReaction = {
  createdAt: string,
  mltaskexecutionresultID: string,
  type: TaskReactionType,
  updatedAt: string,
  userID: string,
};

export enum TaskReactionType {
  ANGRY = "ANGRY",
  CUTE = "CUTE",
  FUNNY = "FUNNY",
  GROSS = "GROSS",
  HEART = "HEART",
  POOP = "POOP",
  SAD = "SAD",
  SCARY = "SCARY",
  WOW = "WOW",
}


export type TaskExecutionResultInput = {
  metaData?:  Array<TaskExecutionResultInputMetaData | null > | null,
  name?: string | null,
  type?: ModelInputOutputEntryType | null,
  value?: string | null,
};

export type TaskExecutionResultInputMetaData = {
  key?: string | null,
  value?: string | null,
};

export enum ModelInputOutputEntryType {
  NUMBER = "NUMBER",
  NUMBER_FLOAT = "NUMBER_FLOAT",
  NUMBER_INT = "NUMBER_INT",
  TEXT = "TEXT",
  URL_AUDIO = "URL_AUDIO",
  URL_IMAGE = "URL_IMAGE",
  URL_VIDEO = "URL_VIDEO",
}


export type TaskExecutionResultOutput = {
  entries?:  Array<TaskExecutionResultOutputEntry | null > | null,
  message?: string | null,
};

export type TaskExecutionResultOutputEntry = {
  name?: string | null,
  type?: ModelInputOutputEntryType | null,
  value?: string | null,
};

export type ModelInputPresetInputUserValue = {
  presetCreatorName?: string | null,
  presetCreatorUID?: string | null,
  presetID?: string | null,
  presetName?: string | null,
  presetValues?:  Array<ModelInputPresetEntryInputUserValue | null > | null,
};

export type ModelInputPresetEntryInputUserValue = {
  modelInputName?: string | null,
  presetEntryDatas?:  Array<ModelInputPresetEntryInputUserValueEntryData | null > | null,
};

export type ModelInputPresetEntryInputUserValueEntryData = {
  parameterName?: string | null,
  presetOrder?: number | null,
  val?: string | null,
};

export enum TaskPrivacyLevels {
  private = "private",
  public = "public",
}


export enum TaskResultStatus {
  DRAFT = "DRAFT",
  ENQUEUED = "ENQUEUED",
  EXECUTING = "EXECUTING",
  FAILED = "FAILED",
  FINISHED = "FINISHED",
}


export type ModelMLTasksWorkspaceConnection = {
  items:  Array<MLTasksWorkspace | null >,
  nextToken?: string | null,
};

export type MLTasksWorkspace = {
  MLTaskExecutionResults?: ModelMLTaskExecutionResultConnection | null,
  createdAt: string,
  description?: string | null,
  id: string,
  name?: string | null,
  updatedAt: string,
  userID: string,
};

export type ModelModelInputPresetConnection = {
  items:  Array<ModelInputPreset | null >,
  nextToken?: string | null,
};

export type ModelInputPreset = {
  cost?: number | null,
  createdAt: string,
  description?: string | null,
  entries?:  Array<ModelInputPresetEntry | null > | null,
  id: string,
  isOpenSource?: boolean | null,
  isPublic?: boolean | null,
  modelName?: string | null,
  modelsID: string,
  presetName?: string | null,
  taskName?: string | null,
  updatedAt: string,
  usage?: number | null,
  userID: string,
  username?: string | null,
};

export type ModelInputPresetEntry = {
  modelInputName?: string | null,
  modelInputType?: ModelInputOutputEntryType | null,
  presetEntryDatas?:  Array<ModelInputPresetEntryData | null > | null,
};

export type ModelInputPresetEntryData = {
  description?: string | null,
  entryType?: InputPresetEntryType | null,
  metaData?:  Array<TaskExecutionResultInputMetaData | null > | null,
  parameterName?: string | null,
  presetOrder?: number | null,
  val?: string | null,
};

export enum InputPresetEntryType {
  PRESET_INPUT = "PRESET_INPUT",
  USER_INPUT = "USER_INPUT",
}


export type ModelSocialManPostConnection = {
  items:  Array<SocialManPost | null >,
  nextToken?: string | null,
};

export type SocialManPost = {
  createdAt: string,
  description?: string | null,
  enabledFacebook?: boolean | null,
  enabledInstagram?: boolean | null,
  enabledLinkedin?: boolean | null,
  enabledPinterest?: boolean | null,
  enabledTiktok?: boolean | null,
  enabledTwitter?: boolean | null,
  enabledYoutube?: boolean | null,
  facebook?: SocialManPostFacebookData | null,
  id: string,
  instagram?: SocialManPostInstagramData | null,
  linkedin?: SocialManPostLinkedinData | null,
  pinterest?: SocialManPostPinterestData | null,
  postedTime?: number | null,
  postedToFacebook?: boolean | null,
  postedToInstagram?: boolean | null,
  postedToLinkedin?: boolean | null,
  postedToPinterest?: boolean | null,
  postedToTiktok?: boolean | null,
  postedToTwitter?: boolean | null,
  postedToYoutube?: boolean | null,
  s3Key?: string | null,
  s3KeyResized?: string | null,
  scheduledPostTime?: number | null,
  status: SocialManPostStatus,
  tiktok?: SocialManPostTiktokData | null,
  title?: string | null,
  twitter?: SocialManPostTwitterData | null,
  updatedAt: string,
  userID: string,
  youtube?: SocialManPostYoutubeData | null,
};

export type SocialManPostFacebookData = {
  caption: string,
  postID?: string | null,
  postToStory?: boolean | null,
  storyID?: string | null,
  storyURL?: string | null,
  targetPageID?: string | null,
  thumbnail?: string | null,
};

export type SocialManPostInstagramData = {
  caption: string,
  postID?: string | null,
  postToStory?: boolean | null,
  storyID?: string | null,
  targetAccountID?: string | null,
  thumbnail?: string | null,
};

export type SocialManPostLinkedinData = {
  caption?: string | null,
  mediaDescription?: string | null,
  postID?: string | null,
};

export type SocialManPostPinterestData = {
  boardID?: string | null,
  description?: string | null,
  link?: string | null,
  pinID?: string | null,
  thumbnailURL?: string | null,
  title?: string | null,
};

export enum SocialManPostStatus {
  DRAFT = "DRAFT",
  ENQUEUED = "ENQUEUED",
  EXECUTING = "EXECUTING",
  FAILED = "FAILED",
  FINISHED = "FINISHED",
}


export type SocialManPostTiktokData = {
  caption?: string | null,
  contentDisclosureBrandedContent?: boolean | null,
  contentDisclosureEnabled?: boolean | null,
  contentDisclosureYourBrand?: boolean | null,
  postID?: string | null,
  privacy?: SocialManTiktokPrivacyStatus | null,
  title?: string | null,
  usersCanComment?: boolean | null,
  usersCanDuet?: boolean | null,
  usersCanStitch?: boolean | null,
  videoCoverTimestampMs?: number | null,
};

export enum SocialManTiktokPrivacyStatus {
  FOLLOWER_OF_CREATOR = "FOLLOWER_OF_CREATOR",
  MUTUAL_FOLLOW_FRIENDS = "MUTUAL_FOLLOW_FRIENDS",
  PUBLIC_TO_EVERYONE = "PUBLIC_TO_EVERYONE",
  SELF_ONLY = "SELF_ONLY",
}


export type SocialManPostTwitterData = {
  caption: string,
  tweetID?: string | null,
};

export type SocialManPostYoutubeData = {
  category?: string | null,
  description?: string | null,
  privacy?: string | null,
  tags?: string | null,
  targetChannelID?: string | null,
  thumbnailURL?: string | null,
  title?: string | null,
  videoID?: string | null,
};

export enum SubscriptionPlan {
  NO_PLAN = "NO_PLAN",
  SUBSCRIPTION_100000 = "SUBSCRIPTION_100000",
  SUBSCRIPTION_15000 = "SUBSCRIPTION_15000",
  SUBSCRIPTION_5000 = "SUBSCRIPTION_5000",
}


export type FacebookData = {
  instagramsData:  Array<InstagramUserData | null >,
  pagesData:  Array<FacebookPageData | null >,
  userData: FacebookUserData,
};

export type InstagramUserData = {
  accessToken?: string | null,
  biography?: string | null,
  connectedPageID?: string | null,
  expiresOn?: number | null,
  followersCount?: number | null,
  followsCount?: number | null,
  id?: string | null,
  igID?: string | null,
  mediaCount?: number | null,
  name?: string | null,
  profilePictureURL?: string | null,
  username?: string | null,
};

export type FacebookPageData = {
  accessToken?: string | null,
  category?: string | null,
  expiresOn?: number | null,
  id?: string | null,
  name?: string | null,
  picture?: string | null,
};

export type FacebookUserData = {
  accessToken?: string | null,
  expiresOn?: number | null,
  id?: string | null,
  name?: string | null,
  picture?: string | null,
};

export type GoogleData = {
  channelsData:  Array<YoutubeChannelData | null >,
  tokenData: GoogleTokenData,
  userData: GoogleUserData,
};

export type YoutubeChannelData = {
  customUrl?: string | null,
  description?: string | null,
  id?: string | null,
  thumbnail?: string | null,
  title?: string | null,
};

export type GoogleTokenData = {
  accessToken?: string | null,
  expiresOn?: number | null,
  idToken?: string | null,
  refreshToken?: string | null,
  scope?: string | null,
  tokenType?: string | null,
};

export type GoogleUserData = {
  email?: string | null,
  familyName?: string | null,
  givenName?: string | null,
  id?: string | null,
  locale?: string | null,
  name?: string | null,
  picture?: string | null,
  verifiedEmail?: boolean | null,
};

export type LinkedinData = {
  tokenData: LinkedinTokenData,
  userData: LinkedinUserData,
};

export type LinkedinTokenData = {
  accessToken: string,
  accessTokenExpirationDate: number,
  scope: string,
};

export type LinkedinUserData = {
  email?: string | null,
  name?: string | null,
  picture?: string | null,
  sub?: string | null,
};

export type PinterestData = {
  boardsData?:  Array<PinterestUserBoardData | null > | null,
  tokenData: PinterestTokenData,
  userData: PinterestUserData,
};

export type PinterestUserBoardData = {
  createdAt?: string | null,
  description?: string | null,
  followerCount?: number | null,
  id?: string | null,
  imageCoverUrl?: string | null,
  name?: string | null,
  pinCount?: number | null,
  privacy?: string | null,
};

export type PinterestTokenData = {
  accessToken: string,
  accessTokenExpirationDate: number,
  refreshToken: string,
  refreshTokenExpirationDate: number,
  scope: string,
};

export type PinterestUserData = {
  about?: string | null,
  accountType?: string | null,
  boardCount?: number | null,
  businessName?: string | null,
  followerCount?: number | null,
  followingCount?: number | null,
  id?: string | null,
  monthlyViews?: number | null,
  pinCount?: number | null,
  profileImage?: string | null,
  username?: string | null,
  websiteUrl?: string | null,
};

export type TiktokData = {
  tokenData: TiktokTokenData,
  userData: TiktokUserData,
};

export type TiktokTokenData = {
  accessToken: string,
  accessTokenExpirationDate: number,
  refreshToken: string,
  refreshTokenExpirationDate: number,
  scope: string,
};

export type TiktokUserData = {
  avatarLargeURL?: string | null,
  avatarURL?: string | null,
  avatarURL100?: string | null,
  bioDescription?: string | null,
  commentDisabled?: boolean | null,
  displayName?: string | null,
  duetDisabled?: boolean | null,
  followerCount?: number | null,
  followingCount?: number | null,
  isVerified?: boolean | null,
  likesCount?: number | null,
  maxVideoPostDuration_sec?: number | null,
  privacyLevelOptions?: Array< string | null > | null,
  profileDeepLink?: string | null,
  stitchDisabled?: boolean | null,
  username?: string | null,
  videoCount?: number | null,
};

export type TwitterData = {
  tokenData: TwitterTokenData,
  userData: TwitterUserData,
};

export type TwitterTokenData = {
  accessToken?: string | null,
  accessTokenSecret?: string | null,
  codeVerifier?: string | null,
  expiresOn?: number | null,
  oauthTokenSecret?: string | null,
  refreshToken?: string | null,
};

export type TwitterUserData = {
  description?: string | null,
  id?: string | null,
  name?: string | null,
  profilePictureURL?: string | null,
  username?: string | null,
  verifiedType?: TwitterVerifiedType | null,
};

export enum TwitterVerifiedType {
  BLUE = "BLUE",
  BUSINESS = "BUSINESS",
  GOVERNMENT = "GOVERNMENT",
  UNVERIFIED = "UNVERIFIED",
}


export type UpdateUserInput = {
  completedTasksCount?: number | null,
  createdPresetCount?: number | null,
  credits?: number | null,
  currentSubscriptionPlan?: SubscriptionPlan | null,
  email?: string | null,
  facebook?: FacebookDataInput | null,
  google?: GoogleDataInput | null,
  id: string,
  linkedin?: LinkedinDataInput | null,
  name?: string | null,
  periodEndOfLastSubscriptionCredits?: number | null,
  picture?: string | null,
  pinterest?: PinterestDataInput | null,
  stripeConnectAccountID?: string | null,
  stripeConnectAccountSetupComplete?: boolean | null,
  stripeCustomerID?: string | null,
  tiktok?: TiktokDataInput | null,
  twitter?: TwitterDataInput | null,
  username?: string | null,
};

export type FacebookDataInput = {
  instagramsData: Array< InstagramUserDataInput | null >,
  pagesData: Array< FacebookPageDataInput | null >,
  userData: FacebookUserDataInput,
};

export type InstagramUserDataInput = {
  accessToken?: string | null,
  biography?: string | null,
  connectedPageID?: string | null,
  expiresOn?: number | null,
  followersCount?: number | null,
  followsCount?: number | null,
  id?: string | null,
  igID?: string | null,
  mediaCount?: number | null,
  name?: string | null,
  profilePictureURL?: string | null,
  username?: string | null,
};

export type FacebookPageDataInput = {
  accessToken?: string | null,
  category?: string | null,
  expiresOn?: number | null,
  id?: string | null,
  name?: string | null,
  picture?: string | null,
};

export type FacebookUserDataInput = {
  accessToken?: string | null,
  expiresOn?: number | null,
  id?: string | null,
  name?: string | null,
  picture?: string | null,
};

export type GoogleDataInput = {
  channelsData: Array< YoutubeChannelDataInput | null >,
  tokenData: GoogleTokenDataInput,
  userData: GoogleUserDataInput,
};

export type YoutubeChannelDataInput = {
  customUrl?: string | null,
  description?: string | null,
  id?: string | null,
  thumbnail?: string | null,
  title?: string | null,
};

export type GoogleTokenDataInput = {
  accessToken?: string | null,
  expiresOn?: number | null,
  idToken?: string | null,
  refreshToken?: string | null,
  scope?: string | null,
  tokenType?: string | null,
};

export type GoogleUserDataInput = {
  email?: string | null,
  familyName?: string | null,
  givenName?: string | null,
  id?: string | null,
  locale?: string | null,
  name?: string | null,
  picture?: string | null,
  verifiedEmail?: boolean | null,
};

export type LinkedinDataInput = {
  tokenData: LinkedinTokenDataInput,
  userData: LinkedinUserDataInput,
};

export type LinkedinTokenDataInput = {
  accessToken: string,
  accessTokenExpirationDate: number,
  scope: string,
};

export type LinkedinUserDataInput = {
  email?: string | null,
  name?: string | null,
  picture?: string | null,
  sub?: string | null,
};

export type PinterestDataInput = {
  boardsData?: Array< PinterestUserBoardDataInput | null > | null,
  tokenData: PinterestTokenDataInput,
  userData: PinterestUserDataInput,
};

export type PinterestUserBoardDataInput = {
  createdAt?: string | null,
  description?: string | null,
  followerCount?: number | null,
  id?: string | null,
  imageCoverUrl?: string | null,
  name?: string | null,
  pinCount?: number | null,
  privacy?: string | null,
};

export type PinterestTokenDataInput = {
  accessToken: string,
  accessTokenExpirationDate: number,
  refreshToken: string,
  refreshTokenExpirationDate: number,
  scope: string,
};

export type PinterestUserDataInput = {
  about?: string | null,
  accountType?: string | null,
  boardCount?: number | null,
  businessName?: string | null,
  followerCount?: number | null,
  followingCount?: number | null,
  id?: string | null,
  monthlyViews?: number | null,
  pinCount?: number | null,
  profileImage?: string | null,
  username?: string | null,
  websiteUrl?: string | null,
};

export type TiktokDataInput = {
  tokenData: TiktokTokenDataInput,
  userData: TiktokUserDataInput,
};

export type TiktokTokenDataInput = {
  accessToken: string,
  accessTokenExpirationDate: number,
  refreshToken: string,
  refreshTokenExpirationDate: number,
  scope: string,
};

export type TiktokUserDataInput = {
  avatarLargeURL?: string | null,
  avatarURL?: string | null,
  avatarURL100?: string | null,
  bioDescription?: string | null,
  commentDisabled?: boolean | null,
  displayName?: string | null,
  duetDisabled?: boolean | null,
  followerCount?: number | null,
  followingCount?: number | null,
  isVerified?: boolean | null,
  likesCount?: number | null,
  maxVideoPostDuration_sec?: number | null,
  privacyLevelOptions?: Array< string | null > | null,
  profileDeepLink?: string | null,
  stitchDisabled?: boolean | null,
  username?: string | null,
  videoCount?: number | null,
};

export type TwitterDataInput = {
  tokenData: TwitterTokenDataInput,
  userData: TwitterUserDataInput,
};

export type TwitterTokenDataInput = {
  accessToken?: string | null,
  accessTokenSecret?: string | null,
  codeVerifier?: string | null,
  expiresOn?: number | null,
  oauthTokenSecret?: string | null,
  refreshToken?: string | null,
};

export type TwitterUserDataInput = {
  description?: string | null,
  id?: string | null,
  name?: string | null,
  profilePictureURL?: string | null,
  username?: string | null,
  verifiedType?: TwitterVerifiedType | null,
};

export type ModelUserConditionInput = {
  and?: Array< ModelUserConditionInput | null > | null,
  completedTasksCount?: ModelIntInput | null,
  createdPresetCount?: ModelIntInput | null,
  credits?: ModelIntInput | null,
  currentSubscriptionPlan?: ModelSubscriptionPlanInput | null,
  email?: ModelStringInput | null,
  name?: ModelStringInput | null,
  not?: ModelUserConditionInput | null,
  or?: Array< ModelUserConditionInput | null > | null,
  periodEndOfLastSubscriptionCredits?: ModelIntInput | null,
  picture?: ModelStringInput | null,
  stripeConnectAccountID?: ModelStringInput | null,
  stripeConnectAccountSetupComplete?: ModelBooleanInput | null,
  stripeCustomerID?: ModelStringInput | null,
  username?: ModelStringInput | null,
};

export type ModelIntInput = {
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
  between?: Array< number | null > | null,
  eq?: number | null,
  ge?: number | null,
  gt?: number | null,
  le?: number | null,
  lt?: number | null,
  ne?: number | null,
};

export enum ModelAttributeTypes {
  _null = "_null",
  binary = "binary",
  binarySet = "binarySet",
  bool = "bool",
  list = "list",
  map = "map",
  number = "number",
  numberSet = "numberSet",
  string = "string",
  stringSet = "stringSet",
}


export type ModelSubscriptionPlanInput = {
  eq?: SubscriptionPlan | null,
  ne?: SubscriptionPlan | null,
};

export type ModelStringInput = {
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
  beginsWith?: string | null,
  between?: Array< string | null > | null,
  contains?: string | null,
  eq?: string | null,
  ge?: string | null,
  gt?: string | null,
  le?: string | null,
  lt?: string | null,
  ne?: string | null,
  notContains?: string | null,
  size?: ModelSizeInput | null,
};

export type ModelSizeInput = {
  between?: Array< number | null > | null,
  eq?: number | null,
  ge?: number | null,
  gt?: number | null,
  le?: number | null,
  lt?: number | null,
  ne?: number | null,
};

export type ModelBooleanInput = {
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
  eq?: boolean | null,
  ne?: boolean | null,
};

export type CreateModelsInput = {
  id?: string | null,
  inputs?: ModelInputInput | null,
  isPublic: boolean,
  mltaskdataID: string,
  name?: string | null,
  outputs?: ModelOutputInput | null,
};

export type ModelInputInput = {
  optional?: Array< ModelInputEntryInput | null > | null,
  required?: Array< ModelInputEntryInput | null > | null,
};

export type ModelInputEntryInput = {
  constraints?: Array< ModelInputConstraintInput | null > | null,
  defaultValue?: string | null,
  description?: string | null,
  examples?: Array< string | null > | null,
  name: string,
  optionStep?: number | null,
  optionType?: OptionType | null,
  options?: Array< ModelInputOptionsDataInput | null > | null,
  type: ModelInputOutputEntryType,
};

export type ModelInputConstraintInput = {
  type?: ModelInputConstraintType | null,
  value?: string | null,
};

export enum ModelInputConstraintType {
  IMAGE_HEIGHT = "IMAGE_HEIGHT",
  IMAGE_RATIO = "IMAGE_RATIO",
  IMAGE_WIDTH = "IMAGE_WIDTH",
  MAX_FILE_SIZE_MB = "MAX_FILE_SIZE_MB",
  MAX_STRING_LENGTH = "MAX_STRING_LENGTH",
  MAX_TOKENS = "MAX_TOKENS",
  MAX_VALUE = "MAX_VALUE",
  MIN_VALUE = "MIN_VALUE",
  REGEX = "REGEX",
}


export enum OptionType {
  DROP_DOWN = "DROP_DOWN",
  RANGE = "RANGE",
  STRICT_SLIDER = "STRICT_SLIDER",
  TOGGLE_GROUP_SINGLE = "TOGGLE_GROUP_SINGLE",
}


export type ModelInputOptionsDataInput = {
  description?: string | null,
  name?: string | null,
  value?: string | null,
};

export type ModelOutputInput = {
  entries?: Array< ModelOutputEntryInput | null > | null,
};

export type ModelOutputEntryInput = {
  name?: string | null,
  type?: ModelInputOutputEntryType | null,
};

export type ModelModelsConditionInput = {
  and?: Array< ModelModelsConditionInput | null > | null,
  isPublic?: ModelBooleanInput | null,
  mltaskdataID?: ModelIDInput | null,
  name?: ModelStringInput | null,
  not?: ModelModelsConditionInput | null,
  or?: Array< ModelModelsConditionInput | null > | null,
};

export type ModelIDInput = {
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
  beginsWith?: string | null,
  between?: Array< string | null > | null,
  contains?: string | null,
  eq?: string | null,
  ge?: string | null,
  gt?: string | null,
  le?: string | null,
  lt?: string | null,
  ne?: string | null,
  notContains?: string | null,
  size?: ModelSizeInput | null,
};

export type Models = {
  MLTaskExecutionResults?: ModelMLTaskExecutionResultConnection | null,
  ModelInputPresets?: ModelModelInputPresetConnection | null,
  createdAt: string,
  id: string,
  inputs?: ModelInput | null,
  isPublic: boolean,
  mltaskdataID: string,
  name?: string | null,
  outputs?: ModelOutput | null,
  updatedAt: string,
};

export type ModelInput = {
  optional?:  Array<ModelInputEntry | null > | null,
  required?:  Array<ModelInputEntry | null > | null,
};

export type ModelInputEntry = {
  constraints?:  Array<ModelInputConstraint | null > | null,
  defaultValue?: string | null,
  description?: string | null,
  examples?: Array< string | null > | null,
  name: string,
  optionStep?: number | null,
  optionType?: OptionType | null,
  options?:  Array<ModelInputOptionsData | null > | null,
  type: ModelInputOutputEntryType,
};

export type ModelInputConstraint = {
  type?: ModelInputConstraintType | null,
  value?: string | null,
};

export type ModelInputOptionsData = {
  description?: string | null,
  name?: string | null,
  value?: string | null,
};

export type ModelOutput = {
  entries?:  Array<ModelOutputEntry | null > | null,
};

export type ModelOutputEntry = {
  name?: string | null,
  type?: ModelInputOutputEntryType | null,
};

export type CreateModelInputPresetInput = {
  cost?: number | null,
  description?: string | null,
  entries?: Array< ModelInputPresetEntryInput | null > | null,
  id?: string | null,
  isOpenSource?: boolean | null,
  isPublic?: boolean | null,
  modelName?: string | null,
  modelsID: string,
  presetName?: string | null,
  taskName?: string | null,
  usage?: number | null,
  userID: string,
  username?: string | null,
};

export type ModelInputPresetEntryInput = {
  modelInputName?: string | null,
  modelInputType?: ModelInputOutputEntryType | null,
  presetEntryDatas?: Array< ModelInputPresetEntryDataInput | null > | null,
};

export type ModelInputPresetEntryDataInput = {
  description?: string | null,
  entryType?: InputPresetEntryType | null,
  metaData?: Array< TaskExecutionResultInputMetaDataInput | null > | null,
  parameterName?: string | null,
  presetOrder?: number | null,
  val?: string | null,
};

export type TaskExecutionResultInputMetaDataInput = {
  key?: string | null,
  value?: string | null,
};

export type ModelModelInputPresetConditionInput = {
  and?: Array< ModelModelInputPresetConditionInput | null > | null,
  cost?: ModelIntInput | null,
  description?: ModelStringInput | null,
  isOpenSource?: ModelBooleanInput | null,
  isPublic?: ModelBooleanInput | null,
  modelName?: ModelStringInput | null,
  modelsID?: ModelIDInput | null,
  not?: ModelModelInputPresetConditionInput | null,
  or?: Array< ModelModelInputPresetConditionInput | null > | null,
  presetName?: ModelStringInput | null,
  taskName?: ModelStringInput | null,
  usage?: ModelIntInput | null,
  userID?: ModelIDInput | null,
  username?: ModelStringInput | null,
};

export type ModelStringKeyConditionInput = {
  beginsWith?: string | null,
  between?: Array< string | null > | null,
  eq?: string | null,
  ge?: string | null,
  gt?: string | null,
  le?: string | null,
  lt?: string | null,
};

export type ModelSocialManPostFilterInput = {
  and?: Array< ModelSocialManPostFilterInput | null > | null,
  createdAt?: ModelStringInput | null,
  description?: ModelStringInput | null,
  enabledFacebook?: ModelBooleanInput | null,
  enabledInstagram?: ModelBooleanInput | null,
  enabledLinkedin?: ModelBooleanInput | null,
  enabledPinterest?: ModelBooleanInput | null,
  enabledTiktok?: ModelBooleanInput | null,
  enabledTwitter?: ModelBooleanInput | null,
  enabledYoutube?: ModelBooleanInput | null,
  id?: ModelIDInput | null,
  not?: ModelSocialManPostFilterInput | null,
  or?: Array< ModelSocialManPostFilterInput | null > | null,
  postedTime?: ModelIntInput | null,
  postedToFacebook?: ModelBooleanInput | null,
  postedToInstagram?: ModelBooleanInput | null,
  postedToLinkedin?: ModelBooleanInput | null,
  postedToPinterest?: ModelBooleanInput | null,
  postedToTiktok?: ModelBooleanInput | null,
  postedToTwitter?: ModelBooleanInput | null,
  postedToYoutube?: ModelBooleanInput | null,
  s3Key?: ModelStringInput | null,
  s3KeyResized?: ModelStringInput | null,
  scheduledPostTime?: ModelIntInput | null,
  status?: ModelSocialManPostStatusInput | null,
  title?: ModelStringInput | null,
  userID?: ModelIDInput | null,
};

export type ModelSocialManPostStatusInput = {
  eq?: SocialManPostStatus | null,
  ne?: SocialManPostStatus | null,
};

export enum ModelSortDirection {
  ASC = "ASC",
  DESC = "DESC",
}


export type ModelModelInputPresetFilterInput = {
  and?: Array< ModelModelInputPresetFilterInput | null > | null,
  cost?: ModelIntInput | null,
  description?: ModelStringInput | null,
  id?: ModelIDInput | null,
  isOpenSource?: ModelBooleanInput | null,
  isPublic?: ModelBooleanInput | null,
  modelName?: ModelStringInput | null,
  modelsID?: ModelIDInput | null,
  not?: ModelModelInputPresetFilterInput | null,
  or?: Array< ModelModelInputPresetFilterInput | null > | null,
  presetName?: ModelStringInput | null,
  taskName?: ModelStringInput | null,
  usage?: ModelIntInput | null,
  userID?: ModelIDInput | null,
  username?: ModelStringInput | null,
};

export type ModelSubscriptionMLTaskExecutionResultFilterInput = {
  ANGRYReactionStats?: ModelSubscriptionIntInput | null,
  CUTEReactionStats?: ModelSubscriptionIntInput | null,
  FUNNYReactionStats?: ModelSubscriptionIntInput | null,
  GROSSReactionStats?: ModelSubscriptionIntInput | null,
  HEARTReactionStats?: ModelSubscriptionIntInput | null,
  POOPReactionStats?: ModelSubscriptionIntInput | null,
  SADReactionStats?: ModelSubscriptionIntInput | null,
  SCARYReactionStats?: ModelSubscriptionIntInput | null,
  WOWReactionStats?: ModelSubscriptionIntInput | null,
  and?: Array< ModelSubscriptionMLTaskExecutionResultFilterInput | null > | null,
  createdAt?: ModelSubscriptionStringInput | null,
  creditsUsed?: ModelSubscriptionIntInput | null,
  id?: ModelSubscriptionIDInput | null,
  mltasksworkspaceID?: ModelSubscriptionIDInput | null,
  modelName?: ModelSubscriptionStringInput | null,
  modelsID?: ModelSubscriptionIDInput | null,
  or?: Array< ModelSubscriptionMLTaskExecutionResultFilterInput | null > | null,
  privacyLevel?: ModelSubscriptionStringInput | null,
  status?: ModelSubscriptionStringInput | null,
  taskName?: ModelSubscriptionStringInput | null,
};

export type ModelSubscriptionIntInput = {
  between?: Array< number | null > | null,
  eq?: number | null,
  ge?: number | null,
  gt?: number | null,
  in?: Array< number | null > | null,
  le?: number | null,
  lt?: number | null,
  ne?: number | null,
  notIn?: Array< number | null > | null,
};

export type ModelSubscriptionStringInput = {
  beginsWith?: string | null,
  between?: Array< string | null > | null,
  contains?: string | null,
  eq?: string | null,
  ge?: string | null,
  gt?: string | null,
  in?: Array< string | null > | null,
  le?: string | null,
  lt?: string | null,
  ne?: string | null,
  notContains?: string | null,
  notIn?: Array< string | null > | null,
};

export type ModelSubscriptionIDInput = {
  beginsWith?: string | null,
  between?: Array< string | null > | null,
  contains?: string | null,
  eq?: string | null,
  ge?: string | null,
  gt?: string | null,
  in?: Array< string | null > | null,
  le?: string | null,
  lt?: string | null,
  ne?: string | null,
  notContains?: string | null,
  notIn?: Array< string | null > | null,
};

export type MLTaskData = {
  Models?: ModelModelsConnection | null,
  category?: string | null,
  createdAt: string,
  id: string,
  isPublic: boolean,
  name?: string | null,
  updatedAt: string,
};

export type ModelModelsConnection = {
  items:  Array<Models | null >,
  nextToken?: string | null,
};

export type ModelMLTaskDataFilterInput = {
  and?: Array< ModelMLTaskDataFilterInput | null > | null,
  category?: ModelStringInput | null,
  id?: ModelIDInput | null,
  isPublic?: ModelBooleanInput | null,
  name?: ModelStringInput | null,
  not?: ModelMLTaskDataFilterInput | null,
  or?: Array< ModelMLTaskDataFilterInput | null > | null,
};

export type ModelMLTaskDataConnection = {
  items:  Array<MLTaskData | null >,
  nextToken?: string | null,
};

export type ModelMLTaskExecutionResultFilterInput = {
  ANGRYReactionStats?: ModelIntInput | null,
  CUTEReactionStats?: ModelIntInput | null,
  FUNNYReactionStats?: ModelIntInput | null,
  GROSSReactionStats?: ModelIntInput | null,
  HEARTReactionStats?: ModelIntInput | null,
  POOPReactionStats?: ModelIntInput | null,
  SADReactionStats?: ModelIntInput | null,
  SCARYReactionStats?: ModelIntInput | null,
  WOWReactionStats?: ModelIntInput | null,
  and?: Array< ModelMLTaskExecutionResultFilterInput | null > | null,
  createdAt?: ModelStringInput | null,
  creditsUsed?: ModelIntInput | null,
  id?: ModelIDInput | null,
  mltasksworkspaceID?: ModelIDInput | null,
  modelName?: ModelStringInput | null,
  modelsID?: ModelIDInput | null,
  not?: ModelMLTaskExecutionResultFilterInput | null,
  or?: Array< ModelMLTaskExecutionResultFilterInput | null > | null,
  privacyLevel?: ModelTaskPrivacyLevelsInput | null,
  status?: ModelTaskResultStatusInput | null,
  taskName?: ModelStringInput | null,
  userID?: ModelIDInput | null,
};

export type ModelTaskPrivacyLevelsInput = {
  eq?: TaskPrivacyLevels | null,
  ne?: TaskPrivacyLevels | null,
};

export type ModelTaskResultStatusInput = {
  eq?: TaskResultStatus | null,
  ne?: TaskResultStatus | null,
};

export type ModelMLTaskDataConditionInput = {
  and?: Array< ModelMLTaskDataConditionInput | null > | null,
  category?: ModelStringInput | null,
  isPublic?: ModelBooleanInput | null,
  name?: ModelStringInput | null,
  not?: ModelMLTaskDataConditionInput | null,
  or?: Array< ModelMLTaskDataConditionInput | null > | null,
};

export type CreateMLTaskDataInput = {
  category?: string | null,
  id?: string | null,
  isPublic: boolean,
  name?: string | null,
};

export type ModelMLTaskExecutionResultConditionInput = {
  ANGRYReactionStats?: ModelIntInput | null,
  CUTEReactionStats?: ModelIntInput | null,
  FUNNYReactionStats?: ModelIntInput | null,
  GROSSReactionStats?: ModelIntInput | null,
  HEARTReactionStats?: ModelIntInput | null,
  POOPReactionStats?: ModelIntInput | null,
  SADReactionStats?: ModelIntInput | null,
  SCARYReactionStats?: ModelIntInput | null,
  WOWReactionStats?: ModelIntInput | null,
  and?: Array< ModelMLTaskExecutionResultConditionInput | null > | null,
  createdAt?: ModelStringInput | null,
  creditsUsed?: ModelIntInput | null,
  mltasksworkspaceID?: ModelIDInput | null,
  modelName?: ModelStringInput | null,
  modelsID?: ModelIDInput | null,
  not?: ModelMLTaskExecutionResultConditionInput | null,
  or?: Array< ModelMLTaskExecutionResultConditionInput | null > | null,
  privacyLevel?: ModelTaskPrivacyLevelsInput | null,
  status?: ModelTaskResultStatusInput | null,
  taskName?: ModelStringInput | null,
  userID?: ModelIDInput | null,
};

export type CreateMLTaskExecutionResultInput = {
  ANGRYReactionStats?: number | null,
  CUTEReactionStats?: number | null,
  FUNNYReactionStats?: number | null,
  GROSSReactionStats?: number | null,
  HEARTReactionStats?: number | null,
  POOPReactionStats?: number | null,
  SADReactionStats?: number | null,
  SCARYReactionStats?: number | null,
  WOWReactionStats?: number | null,
  createdAt?: string | null,
  creditsUsed?: number | null,
  id?: string | null,
  inputs?: Array< TaskExecutionResultInputInput | null > | null,
  mltasksworkspaceID: string,
  modelName?: string | null,
  modelsID: string,
  output?: TaskExecutionResultOutputInput | null,
  presetUserInput?: ModelInputPresetInputUserValueInput | null,
  privacyLevel?: TaskPrivacyLevels | null,
  status?: TaskResultStatus | null,
  taskName?: string | null,
  userID: string,
};

export type TaskExecutionResultInputInput = {
  metaData?: Array< TaskExecutionResultInputMetaDataInput | null > | null,
  name?: string | null,
  type?: ModelInputOutputEntryType | null,
  value?: string | null,
};

export type TaskExecutionResultOutputInput = {
  entries?: Array< TaskExecutionResultOutputEntryInput | null > | null,
  message?: string | null,
};

export type TaskExecutionResultOutputEntryInput = {
  name?: string | null,
  type?: ModelInputOutputEntryType | null,
  value?: string | null,
};

export type ModelInputPresetInputUserValueInput = {
  presetCreatorName?: string | null,
  presetCreatorUID?: string | null,
  presetID?: string | null,
  presetName?: string | null,
  presetValues?: Array< ModelInputPresetEntryInputUserValueInput | null > | null,
};

export type ModelInputPresetEntryInputUserValueInput = {
  modelInputName?: string | null,
  presetEntryDatas?: Array< ModelInputPresetEntryInputUserValueEntryDataInput | null > | null,
};

export type ModelInputPresetEntryInputUserValueEntryDataInput = {
  parameterName?: string | null,
  presetOrder?: number | null,
  val?: string | null,
};

export type ModelMLTasksWorkspaceConditionInput = {
  and?: Array< ModelMLTasksWorkspaceConditionInput | null > | null,
  createdAt?: ModelStringInput | null,
  description?: ModelStringInput | null,
  name?: ModelStringInput | null,
  not?: ModelMLTasksWorkspaceConditionInput | null,
  or?: Array< ModelMLTasksWorkspaceConditionInput | null > | null,
  userID?: ModelIDInput | null,
};

export type CreateMLTasksWorkspaceInput = {
  createdAt?: string | null,
  description?: string | null,
  id?: string | null,
  name?: string | null,
  userID: string,
};

export type ModelSocialManPostConditionInput = {
  and?: Array< ModelSocialManPostConditionInput | null > | null,
  createdAt?: ModelStringInput | null,
  description?: ModelStringInput | null,
  enabledFacebook?: ModelBooleanInput | null,
  enabledInstagram?: ModelBooleanInput | null,
  enabledLinkedin?: ModelBooleanInput | null,
  enabledPinterest?: ModelBooleanInput | null,
  enabledTiktok?: ModelBooleanInput | null,
  enabledTwitter?: ModelBooleanInput | null,
  enabledYoutube?: ModelBooleanInput | null,
  not?: ModelSocialManPostConditionInput | null,
  or?: Array< ModelSocialManPostConditionInput | null > | null,
  postedTime?: ModelIntInput | null,
  postedToFacebook?: ModelBooleanInput | null,
  postedToInstagram?: ModelBooleanInput | null,
  postedToLinkedin?: ModelBooleanInput | null,
  postedToPinterest?: ModelBooleanInput | null,
  postedToTiktok?: ModelBooleanInput | null,
  postedToTwitter?: ModelBooleanInput | null,
  postedToYoutube?: ModelBooleanInput | null,
  s3Key?: ModelStringInput | null,
  s3KeyResized?: ModelStringInput | null,
  scheduledPostTime?: ModelIntInput | null,
  status?: ModelSocialManPostStatusInput | null,
  title?: ModelStringInput | null,
  userID?: ModelIDInput | null,
};

export type CreateSocialManPostInput = {
  createdAt?: string | null,
  description?: string | null,
  enabledFacebook?: boolean | null,
  enabledInstagram?: boolean | null,
  enabledLinkedin?: boolean | null,
  enabledPinterest?: boolean | null,
  enabledTiktok?: boolean | null,
  enabledTwitter?: boolean | null,
  enabledYoutube?: boolean | null,
  facebook?: SocialManPostFacebookDataInput | null,
  id?: string | null,
  instagram?: SocialManPostInstagramDataInput | null,
  linkedin?: SocialManPostLinkedinDataInput | null,
  pinterest?: SocialManPostPinterestDataInput | null,
  postedTime?: number | null,
  postedToFacebook?: boolean | null,
  postedToInstagram?: boolean | null,
  postedToLinkedin?: boolean | null,
  postedToPinterest?: boolean | null,
  postedToTiktok?: boolean | null,
  postedToTwitter?: boolean | null,
  postedToYoutube?: boolean | null,
  s3Key?: string | null,
  s3KeyResized?: string | null,
  scheduledPostTime?: number | null,
  status: SocialManPostStatus,
  tiktok?: SocialManPostTiktokDataInput | null,
  title?: string | null,
  twitter?: SocialManPostTwitterDataInput | null,
  userID: string,
  youtube?: SocialManPostYoutubeDataInput | null,
};

export type SocialManPostFacebookDataInput = {
  caption: string,
  postID?: string | null,
  postToStory?: boolean | null,
  storyID?: string | null,
  storyURL?: string | null,
  targetPageID?: string | null,
  thumbnail?: string | null,
};

export type SocialManPostInstagramDataInput = {
  caption: string,
  postID?: string | null,
  postToStory?: boolean | null,
  storyID?: string | null,
  targetAccountID?: string | null,
  thumbnail?: string | null,
};

export type SocialManPostLinkedinDataInput = {
  caption?: string | null,
  mediaDescription?: string | null,
  postID?: string | null,
};

export type SocialManPostPinterestDataInput = {
  boardID?: string | null,
  description?: string | null,
  link?: string | null,
  pinID?: string | null,
  thumbnailURL?: string | null,
  title?: string | null,
};

export type SocialManPostTiktokDataInput = {
  caption?: string | null,
  contentDisclosureBrandedContent?: boolean | null,
  contentDisclosureEnabled?: boolean | null,
  contentDisclosureYourBrand?: boolean | null,
  postID?: string | null,
  privacy?: SocialManTiktokPrivacyStatus | null,
  title?: string | null,
  usersCanComment?: boolean | null,
  usersCanDuet?: boolean | null,
  usersCanStitch?: boolean | null,
  videoCoverTimestampMs?: number | null,
};

export type SocialManPostTwitterDataInput = {
  caption: string,
  tweetID?: string | null,
};

export type SocialManPostYoutubeDataInput = {
  category?: string | null,
  description?: string | null,
  privacy?: string | null,
  tags?: string | null,
  targetChannelID?: string | null,
  thumbnailURL?: string | null,
  title?: string | null,
  videoID?: string | null,
};

export type ModelTaskReactionConditionInput = {
  and?: Array< ModelTaskReactionConditionInput | null > | null,
  mltaskexecutionresultID?: ModelIDInput | null,
  not?: ModelTaskReactionConditionInput | null,
  or?: Array< ModelTaskReactionConditionInput | null > | null,
  type?: ModelTaskReactionTypeInput | null,
};

export type ModelTaskReactionTypeInput = {
  eq?: TaskReactionType | null,
  ne?: TaskReactionType | null,
};

export type CreateTaskReactionInput = {
  mltaskexecutionresultID: string,
  type: TaskReactionType,
  userID: string,
};

export type CreateUserInput = {
  completedTasksCount?: number | null,
  createdPresetCount?: number | null,
  credits?: number | null,
  currentSubscriptionPlan?: SubscriptionPlan | null,
  email?: string | null,
  facebook?: FacebookDataInput | null,
  google?: GoogleDataInput | null,
  id?: string | null,
  linkedin?: LinkedinDataInput | null,
  name?: string | null,
  periodEndOfLastSubscriptionCredits?: number | null,
  picture?: string | null,
  pinterest?: PinterestDataInput | null,
  stripeConnectAccountID?: string | null,
  stripeConnectAccountSetupComplete?: boolean | null,
  stripeCustomerID?: string | null,
  tiktok?: TiktokDataInput | null,
  twitter?: TwitterDataInput | null,
  username?: string | null,
};

export type DeleteMLTaskDataInput = {
  id: string,
};

export type DeleteMLTaskExecutionResultInput = {
  id: string,
};

export type DeleteMLTasksWorkspaceInput = {
  id: string,
};

export type DeleteModelInputPresetInput = {
  id: string,
};

export type DeleteModelsInput = {
  id: string,
};

export type DeleteSocialManPostInput = {
  id: string,
};

export type DeleteTaskReactionInput = {
  userID: string,
};

export type DeleteUserInput = {
  id: string,
};

export type UpdateMLTaskDataInput = {
  category?: string | null,
  id: string,
  isPublic?: boolean | null,
  name?: string | null,
};

export type UpdateMLTaskExecutionResultInput = {
  ANGRYReactionStats?: number | null,
  CUTEReactionStats?: number | null,
  FUNNYReactionStats?: number | null,
  GROSSReactionStats?: number | null,
  HEARTReactionStats?: number | null,
  POOPReactionStats?: number | null,
  SADReactionStats?: number | null,
  SCARYReactionStats?: number | null,
  WOWReactionStats?: number | null,
  createdAt?: string | null,
  creditsUsed?: number | null,
  id: string,
  inputs?: Array< TaskExecutionResultInputInput | null > | null,
  mltasksworkspaceID?: string | null,
  modelName?: string | null,
  modelsID?: string | null,
  output?: TaskExecutionResultOutputInput | null,
  presetUserInput?: ModelInputPresetInputUserValueInput | null,
  privacyLevel?: TaskPrivacyLevels | null,
  status?: TaskResultStatus | null,
  taskName?: string | null,
  userID?: string | null,
};

export type UpdateMLTasksWorkspaceInput = {
  createdAt?: string | null,
  description?: string | null,
  id: string,
  name?: string | null,
  userID?: string | null,
};

export type UpdateModelInputPresetInput = {
  cost?: number | null,
  description?: string | null,
  entries?: Array< ModelInputPresetEntryInput | null > | null,
  id: string,
  isOpenSource?: boolean | null,
  isPublic?: boolean | null,
  modelName?: string | null,
  modelsID?: string | null,
  presetName?: string | null,
  taskName?: string | null,
  usage?: number | null,
  userID?: string | null,
  username?: string | null,
};

export type UpdateModelsInput = {
  id: string,
  inputs?: ModelInputInput | null,
  isPublic?: boolean | null,
  mltaskdataID?: string | null,
  name?: string | null,
  outputs?: ModelOutputInput | null,
};

export type UpdateSocialManPostInput = {
  createdAt?: string | null,
  description?: string | null,
  enabledFacebook?: boolean | null,
  enabledInstagram?: boolean | null,
  enabledLinkedin?: boolean | null,
  enabledPinterest?: boolean | null,
  enabledTiktok?: boolean | null,
  enabledTwitter?: boolean | null,
  enabledYoutube?: boolean | null,
  facebook?: SocialManPostFacebookDataInput | null,
  id: string,
  instagram?: SocialManPostInstagramDataInput | null,
  linkedin?: SocialManPostLinkedinDataInput | null,
  pinterest?: SocialManPostPinterestDataInput | null,
  postedTime?: number | null,
  postedToFacebook?: boolean | null,
  postedToInstagram?: boolean | null,
  postedToLinkedin?: boolean | null,
  postedToPinterest?: boolean | null,
  postedToTiktok?: boolean | null,
  postedToTwitter?: boolean | null,
  postedToYoutube?: boolean | null,
  s3Key?: string | null,
  s3KeyResized?: string | null,
  scheduledPostTime?: number | null,
  status?: SocialManPostStatus | null,
  tiktok?: SocialManPostTiktokDataInput | null,
  title?: string | null,
  twitter?: SocialManPostTwitterDataInput | null,
  userID?: string | null,
  youtube?: SocialManPostYoutubeDataInput | null,
};

export type UpdateTaskReactionInput = {
  mltaskexecutionresultID?: string | null,
  type?: TaskReactionType | null,
  userID: string,
};

export type ModelMLTasksWorkspaceFilterInput = {
  and?: Array< ModelMLTasksWorkspaceFilterInput | null > | null,
  createdAt?: ModelStringInput | null,
  description?: ModelStringInput | null,
  id?: ModelIDInput | null,
  name?: ModelStringInput | null,
  not?: ModelMLTasksWorkspaceFilterInput | null,
  or?: Array< ModelMLTasksWorkspaceFilterInput | null > | null,
  userID?: ModelIDInput | null,
};

export type ModelModelsFilterInput = {
  and?: Array< ModelModelsFilterInput | null > | null,
  id?: ModelIDInput | null,
  isPublic?: ModelBooleanInput | null,
  mltaskdataID?: ModelIDInput | null,
  name?: ModelStringInput | null,
  not?: ModelModelsFilterInput | null,
  or?: Array< ModelModelsFilterInput | null > | null,
};

export type ModelTaskReactionFilterInput = {
  and?: Array< ModelTaskReactionFilterInput | null > | null,
  mltaskexecutionresultID?: ModelIDInput | null,
  not?: ModelTaskReactionFilterInput | null,
  or?: Array< ModelTaskReactionFilterInput | null > | null,
  type?: ModelTaskReactionTypeInput | null,
  userID?: ModelIDInput | null,
};

export type ModelUserFilterInput = {
  and?: Array< ModelUserFilterInput | null > | null,
  completedTasksCount?: ModelIntInput | null,
  createdPresetCount?: ModelIntInput | null,
  credits?: ModelIntInput | null,
  currentSubscriptionPlan?: ModelSubscriptionPlanInput | null,
  email?: ModelStringInput | null,
  id?: ModelIDInput | null,
  name?: ModelStringInput | null,
  not?: ModelUserFilterInput | null,
  or?: Array< ModelUserFilterInput | null > | null,
  periodEndOfLastSubscriptionCredits?: ModelIntInput | null,
  picture?: ModelStringInput | null,
  stripeConnectAccountID?: ModelStringInput | null,
  stripeConnectAccountSetupComplete?: ModelBooleanInput | null,
  stripeCustomerID?: ModelStringInput | null,
  username?: ModelStringInput | null,
};

export type ModelUserConnection = {
  items:  Array<User | null >,
  nextToken?: string | null,
};

export type ModelSubscriptionMLTaskDataFilterInput = {
  and?: Array< ModelSubscriptionMLTaskDataFilterInput | null > | null,
  category?: ModelSubscriptionStringInput | null,
  id?: ModelSubscriptionIDInput | null,
  isPublic?: ModelSubscriptionBooleanInput | null,
  name?: ModelSubscriptionStringInput | null,
  or?: Array< ModelSubscriptionMLTaskDataFilterInput | null > | null,
};

export type ModelSubscriptionBooleanInput = {
  eq?: boolean | null,
  ne?: boolean | null,
};

export type ModelSubscriptionMLTasksWorkspaceFilterInput = {
  and?: Array< ModelSubscriptionMLTasksWorkspaceFilterInput | null > | null,
  createdAt?: ModelSubscriptionStringInput | null,
  description?: ModelSubscriptionStringInput | null,
  id?: ModelSubscriptionIDInput | null,
  name?: ModelSubscriptionStringInput | null,
  or?: Array< ModelSubscriptionMLTasksWorkspaceFilterInput | null > | null,
};

export type ModelSubscriptionModelInputPresetFilterInput = {
  and?: Array< ModelSubscriptionModelInputPresetFilterInput | null > | null,
  cost?: ModelSubscriptionIntInput | null,
  description?: ModelSubscriptionStringInput | null,
  id?: ModelSubscriptionIDInput | null,
  isOpenSource?: ModelSubscriptionBooleanInput | null,
  isPublic?: ModelSubscriptionBooleanInput | null,
  modelName?: ModelSubscriptionStringInput | null,
  modelsID?: ModelSubscriptionIDInput | null,
  or?: Array< ModelSubscriptionModelInputPresetFilterInput | null > | null,
  presetName?: ModelSubscriptionStringInput | null,
  taskName?: ModelSubscriptionStringInput | null,
  usage?: ModelSubscriptionIntInput | null,
  username?: ModelSubscriptionStringInput | null,
};

export type ModelSubscriptionModelsFilterInput = {
  and?: Array< ModelSubscriptionModelsFilterInput | null > | null,
  id?: ModelSubscriptionIDInput | null,
  isPublic?: ModelSubscriptionBooleanInput | null,
  mltaskdataID?: ModelSubscriptionIDInput | null,
  name?: ModelSubscriptionStringInput | null,
  or?: Array< ModelSubscriptionModelsFilterInput | null > | null,
};

export type ModelSubscriptionSocialManPostFilterInput = {
  and?: Array< ModelSubscriptionSocialManPostFilterInput | null > | null,
  createdAt?: ModelSubscriptionStringInput | null,
  description?: ModelSubscriptionStringInput | null,
  enabledFacebook?: ModelSubscriptionBooleanInput | null,
  enabledInstagram?: ModelSubscriptionBooleanInput | null,
  enabledLinkedin?: ModelSubscriptionBooleanInput | null,
  enabledPinterest?: ModelSubscriptionBooleanInput | null,
  enabledTiktok?: ModelSubscriptionBooleanInput | null,
  enabledTwitter?: ModelSubscriptionBooleanInput | null,
  enabledYoutube?: ModelSubscriptionBooleanInput | null,
  id?: ModelSubscriptionIDInput | null,
  or?: Array< ModelSubscriptionSocialManPostFilterInput | null > | null,
  postedTime?: ModelSubscriptionIntInput | null,
  postedToFacebook?: ModelSubscriptionBooleanInput | null,
  postedToInstagram?: ModelSubscriptionBooleanInput | null,
  postedToLinkedin?: ModelSubscriptionBooleanInput | null,
  postedToPinterest?: ModelSubscriptionBooleanInput | null,
  postedToTiktok?: ModelSubscriptionBooleanInput | null,
  postedToTwitter?: ModelSubscriptionBooleanInput | null,
  postedToYoutube?: ModelSubscriptionBooleanInput | null,
  s3Key?: ModelSubscriptionStringInput | null,
  s3KeyResized?: ModelSubscriptionStringInput | null,
  scheduledPostTime?: ModelSubscriptionIntInput | null,
  status?: ModelSubscriptionStringInput | null,
  title?: ModelSubscriptionStringInput | null,
};

export type ModelSubscriptionTaskReactionFilterInput = {
  and?: Array< ModelSubscriptionTaskReactionFilterInput | null > | null,
  mltaskexecutionresultID?: ModelSubscriptionIDInput | null,
  or?: Array< ModelSubscriptionTaskReactionFilterInput | null > | null,
  type?: ModelSubscriptionStringInput | null,
  userID?: ModelSubscriptionIDInput | null,
};

export type ModelSubscriptionUserFilterInput = {
  and?: Array< ModelSubscriptionUserFilterInput | null > | null,
  completedTasksCount?: ModelSubscriptionIntInput | null,
  createdPresetCount?: ModelSubscriptionIntInput | null,
  credits?: ModelSubscriptionIntInput | null,
  currentSubscriptionPlan?: ModelSubscriptionStringInput | null,
  email?: ModelSubscriptionStringInput | null,
  name?: ModelSubscriptionStringInput | null,
  or?: Array< ModelSubscriptionUserFilterInput | null > | null,
  periodEndOfLastSubscriptionCredits?: ModelSubscriptionIntInput | null,
  picture?: ModelSubscriptionStringInput | null,
  stripeConnectAccountID?: ModelSubscriptionStringInput | null,
  stripeConnectAccountSetupComplete?: ModelSubscriptionBooleanInput | null,
  stripeCustomerID?: ModelSubscriptionStringInput | null,
  username?: ModelSubscriptionStringInput | null,
};

export type updateUsernameMutationVariables = {
  userID: string,
  username: string,
};

export type updateUsernameMutation = {
  updateUserUsername?:  {
    id: string,
    username?: string | null,
  } | null,
};

export type UpdateUserCustomMutationVariables = {
  input: UpdateUserInput,
  condition?: ModelUserConditionInput | null,
};

export type UpdateUserCustomMutation = {
  updateUser?:  {
    id: string,
    name?: string | null,
    username?: string | null,
    stripeCustomerID?: string | null,
    stripeConnectAccountID?: string | null,
    stripeConnectAccountSetupComplete?: boolean | null,
    email?: string | null,
    picture?: string | null,
    credits?: number | null,
    MLTaskExecutionResults?:  {
      nextToken?: string | null,
    } | null,
    MLTasksWorkspaces?:  {
      nextToken?: string | null,
    } | null,
    ModelInputPresets?:  {
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateUserRemoveNetworkMutationVariables = {
  input: UpdateUserInput,
};

export type UpdateUserRemoveNetworkMutation = {
  updateUser?:  {
    facebook?:  {
      instagramsData:  Array< {
        accessToken?: string | null,
        biography?: string | null,
        connectedPageID?: string | null,
        expiresOn?: number | null,
        followersCount?: number | null,
        followsCount?: number | null,
        id?: string | null,
        igID?: string | null,
        mediaCount?: number | null,
        name?: string | null,
        profilePictureURL?: string | null,
        username?: string | null,
      } | null >,
      pagesData:  Array< {
        accessToken?: string | null,
        category?: string | null,
        expiresOn?: number | null,
        id?: string | null,
        name?: string | null,
        picture?: string | null,
      } | null >,
      userData:  {
        accessToken?: string | null,
        expiresOn?: number | null,
        id?: string | null,
        name?: string | null,
        picture?: string | null,
      },
    } | null,
    google?:  {
      channelsData:  Array< {
        customUrl?: string | null,
        description?: string | null,
        id?: string | null,
        thumbnail?: string | null,
        title?: string | null,
      } | null >,
      tokenData:  {
        accessToken?: string | null,
        expiresOn?: number | null,
        idToken?: string | null,
        refreshToken?: string | null,
        scope?: string | null,
        tokenType?: string | null,
      },
      userData:  {
        email?: string | null,
        familyName?: string | null,
        givenName?: string | null,
        id?: string | null,
        locale?: string | null,
        name?: string | null,
        picture?: string | null,
        verifiedEmail?: boolean | null,
      },
    } | null,
    id: string,
    linkedin?:  {
      tokenData:  {
        accessToken: string,
        accessTokenExpirationDate: number,
        scope: string,
      },
      userData:  {
        email?: string | null,
        name?: string | null,
        picture?: string | null,
        sub?: string | null,
      },
    } | null,
    pinterest?:  {
      boardsData?:  Array< {
        createdAt?: string | null,
        description?: string | null,
        followerCount?: number | null,
        id?: string | null,
        imageCoverUrl?: string | null,
        name?: string | null,
        pinCount?: number | null,
        privacy?: string | null,
      } | null > | null,
      tokenData:  {
        accessToken: string,
        accessTokenExpirationDate: number,
        refreshToken: string,
        refreshTokenExpirationDate: number,
        scope: string,
      },
      userData:  {
        about?: string | null,
        accountType?: string | null,
        boardCount?: number | null,
        businessName?: string | null,
        followerCount?: number | null,
        followingCount?: number | null,
        id?: string | null,
        monthlyViews?: number | null,
        pinCount?: number | null,
        profileImage?: string | null,
        username?: string | null,
        websiteUrl?: string | null,
      },
    } | null,
    tiktok?:  {
      tokenData:  {
        accessToken: string,
        accessTokenExpirationDate: number,
        refreshToken: string,
        refreshTokenExpirationDate: number,
        scope: string,
      },
      userData:  {
        avatarLargeURL?: string | null,
        avatarURL?: string | null,
        avatarURL100?: string | null,
        bioDescription?: string | null,
        commentDisabled?: boolean | null,
        displayName?: string | null,
        duetDisabled?: boolean | null,
        followerCount?: number | null,
        followingCount?: number | null,
        isVerified?: boolean | null,
        likesCount?: number | null,
        maxVideoPostDuration_sec?: number | null,
        privacyLevelOptions?: Array< string | null > | null,
        profileDeepLink?: string | null,
        stitchDisabled?: boolean | null,
        username?: string | null,
        videoCount?: number | null,
      },
    } | null,
    twitter?:  {
      tokenData:  {
        accessToken?: string | null,
        accessTokenSecret?: string | null,
        codeVerifier?: string | null,
        expiresOn?: number | null,
        oauthTokenSecret?: string | null,
        refreshToken?: string | null,
      },
      userData:  {
        description?: string | null,
        id?: string | null,
        name?: string | null,
        profilePictureURL?: string | null,
        username?: string | null,
        verifiedType?: TwitterVerifiedType | null,
      },
    } | null,
  } | null,
};

export type CustomCreateModelsMutationVariables = {
  input: CreateModelsInput,
  condition?: ModelModelsConditionInput | null,
};

export type CustomCreateModelsMutation = {
  createModels?:  {
    isPublic: boolean,
    name?: string | null,
    inputs?:  {
    } | null,
    outputs?:  {
    } | null,
    mltaskdataID: string,
    ModelInputPresets?:  {
      nextToken?: string | null,
    } | null,
    MLTaskExecutionResults?:  {
      nextToken?: string | null,
    } | null,
  } | null,
};

export type CreateModelInputPresetCustomMutationVariables = {
  input: CreateModelInputPresetInput,
  condition?: ModelModelInputPresetConditionInput | null,
};

export type CreateModelInputPresetCustomMutation = {
  createModelInputPreset?:  {
    id: string,
    presetName?: string | null,
    description?: string | null,
    cost?: number | null,
    isPublic?: boolean | null,
    isOpenSource?: boolean | null,
    entries?:  Array< {
      modelInputName?: string | null,
      modelInputType?: ModelInputOutputEntryType | null,
      presetEntryDatas?:  Array< {
        entryType?: InputPresetEntryType | null,
        parameterName?: string | null,
        description?: string | null,
        val?: string | null,
        presetOrder?: number | null,
      } | null > | null,
    } | null > | null,
    modelsID: string,
    userID: string,
    username?: string | null,
    taskName?: string | null,
    modelName?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type listSocialManPostsMinifiedDataByCreatedAtQueryVariables = {
  createdAt?: ModelStringKeyConditionInput | null,
  filter?: ModelSocialManPostFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
  userID: string,
};

export type listSocialManPostsMinifiedDataByCreatedAtQuery = {
  listSocialManPostsByCreatedAt?:  {
    items:  Array< {
      createdAt: string,
      description?: string | null,
      id: string,
      s3Key?: string | null,
      s3KeyResized?: string | null,
      status: SocialManPostStatus,
      title?: string | null,
      updatedAt: string,
      userID: string,
      facebook?:  {
        thumbnail?: string | null,
      } | null,
      youtube?:  {
        thumbnailURL?: string | null,
      } | null,
      instagram?:  {
        thumbnail?: string | null,
      } | null,
      pinterest?:  {
        thumbnailURL?: string | null,
      } | null,
      postedTime?: number | null,
      postedToFacebook?: boolean | null,
      postedToInstagram?: boolean | null,
      postedToPinterest?: boolean | null,
      postedToTiktok?: boolean | null,
      postedToTwitter?: boolean | null,
      postedToYoutube?: boolean | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type getUserFromUsernameQueryVariables = {
  username: string,
};

export type getUserFromUsernameQuery = {
  getUserFromUsername?:  {
    id: string,
    name?: string | null,
    username?: string | null,
    createdPresetCount?: number | null,
    completedTasksCount?: number | null,
    stripeCustomerID?: string | null,
    stripeConnectAccountID?: string | null,
    stripeConnectAccountSetupComplete?: boolean | null,
    email?: string | null,
    picture?: string | null,
    credits?: number | null,
    MLTaskExecutionResults?:  {
      nextToken?: string | null,
    } | null,
    MLTasksWorkspaces?:  {
      nextToken?: string | null,
    } | null,
    ModelInputPresets?:  {
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type GetModelsTaskIDQueryVariables = {
  id: string,
};

export type GetModelsTaskIDQuery = {
  getModels?:  {
    id: string,
    mltaskdataID: string,
  } | null,
};

export type GetModelInputPresetCustomQueryVariables = {
  id: string,
};

export type GetModelInputPresetCustomQuery = {
  getModelInputPreset?:  {
    id: string,
    presetName?: string | null,
    description?: string | null,
    isPublic?: boolean | null,
    isOpenSource?: boolean | null,
    modelsID: string,
    userID: string,
    username?: string | null,
    taskName?: string | null,
    modelName?: string | null,
    cost?: number | null,
    createdAt: string,
    updatedAt: string,
    usage?: number | null,
    entries?:  Array< {
      modelInputName?: string | null,
      modelInputType?: ModelInputOutputEntryType | null,
      presetEntryDatas?:  Array< {
        entryType?: InputPresetEntryType | null,
        parameterName?: string | null,
        description?: string | null,
        val?: string | null,
        presetOrder?: number | null,
        metaData?:  Array< {
          key?: string | null,
          value?: string | null,
        } | null > | null,
      } | null > | null,
    } | null > | null,
  } | null,
};

export type ModelInputPresetsByUserIDCustomQueryVariables = {
  userID: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelModelInputPresetFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ModelInputPresetsByUserIDCustomQuery = {
  modelInputPresetsByUserID?:  {
    items:  Array< {
      id: string,
      presetName?: string | null,
      description?: string | null,
      isPublic?: boolean | null,
      isOpenSource?: boolean | null,
      modelsID: string,
      userID: string,
      username?: string | null,
      taskName?: string | null,
      modelName?: string | null,
      cost?: number | null,
      createdAt: string,
      updatedAt: string,
      usage?: number | null,
      entries?:  Array< {
        modelInputName?: string | null,
        modelInputType?: ModelInputOutputEntryType | null,
        presetEntryDatas?:  Array< {
          entryType?: InputPresetEntryType | null,
          parameterName?: string | null,
          description?: string | null,
          val?: string | null,
          presetOrder?: number | null,
          metaData?:  Array< {
            key?: string | null,
            value?: string | null,
          } | null > | null,
        } | null > | null,
      } | null > | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ModelInputPresetsByModelsIDCustomQueryVariables = {
  modelsID: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelModelInputPresetFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ModelInputPresetsByModelsIDCustomQuery = {
  modelInputPresetsByModelsID?:  {
    items:  Array< {
      id: string,
      presetName?: string | null,
      description?: string | null,
      isPublic?: boolean | null,
      isOpenSource?: boolean | null,
      modelsID: string,
      userID: string,
      username?: string | null,
      taskName?: string | null,
      modelName?: string | null,
      cost?: number | null,
      createdAt: string,
      updatedAt: string,
      usage?: number | null,
      entries?:  Array< {
        modelInputName?: string | null,
        modelInputType?: ModelInputOutputEntryType | null,
        presetEntryDatas?:  Array< {
          entryType?: InputPresetEntryType | null,
          parameterName?: string | null,
          description?: string | null,
          val?: string | null,
          presetOrder?: number | null,
          metaData?:  Array< {
            key?: string | null,
            value?: string | null,
          } | null > | null,
        } | null > | null,
      } | null > | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type OnUpdateMLTaskExecutionResult2SubscriptionVariables = {
  filter?: ModelSubscriptionMLTaskExecutionResultFilterInput | null,
  userID?: string | null,
};

export type OnUpdateMLTaskExecutionResult2Subscription = {
  onUpdateMLTaskExecutionResult?:  {
    id: string,
    taskName?: string | null,
    modelName?: string | null,
    privacyLevel?: TaskPrivacyLevels | null,
    inputs?:  Array< {
      name?: string | null,
      value?: string | null,
      type?: ModelInputOutputEntryType | null,
    } | null > | null,
    presetUserInput?:  {
      presetName?: string | null,
      presetCreatorName?: string | null,
      presetCreatorUID?: string | null,
      presetValues?:  Array< {
        modelInputName?: string | null,
        presetEntryDatas?:  Array< {
          val?: string | null,
          presetOrder?: number | null,
          parameterName?: string | null,
        } | null > | null,
      } | null > | null,
    } | null,
    output?:  {
      message?: string | null,
      entries?:  Array< {
        name?: string | null,
        value?: string | null,
        type?: ModelInputOutputEntryType | null,
      } | null > | null,
    } | null,
    creditsUsed?: number | null,
    status?: TaskResultStatus | null,
  } | null,
};

export type OnCreateMLTaskExecutionResult2SubscriptionVariables = {
  filter?: ModelSubscriptionMLTaskExecutionResultFilterInput | null,
  userID?: string | null,
};

export type OnCreateMLTaskExecutionResult2Subscription = {
  onCreateMLTaskExecutionResult?:  {
    id: string,
    userID: string,
    taskName?: string | null,
    modelName?: string | null,
    privacyLevel?: TaskPrivacyLevels | null,
    inputs?:  Array< {
      name?: string | null,
      value?: string | null,
      type?: ModelInputOutputEntryType | null,
    } | null > | null,
    output?:  {
      message?: string | null,
      entries?:  Array< {
        name?: string | null,
        value?: string | null,
        type?: ModelInputOutputEntryType | null,
      } | null > | null,
    } | null,
    status?: TaskResultStatus | null,
  } | null,
};

export type getMLTaskDataQueryQueryVariables = {
  id: string,
};

export type getMLTaskDataQueryQuery = {
  getMLTaskData?:  {
    id: string,
    name?: string | null,
    category?: string | null,
    isPublic: boolean,
    Models?:  {
      items:  Array< {
        id: string,
        name?: string | null,
        mltaskdataID: string,
        outputs?:  {
          entries?:  Array< {
            name?: string | null,
            type?: ModelInputOutputEntryType | null,
          } | null > | null,
        } | null,
        inputs?:  {
          optional?:  Array< {
            defaultValue?: string | null,
            description?: string | null,
            examples?: Array< string | null > | null,
            name: string,
            optionType?: OptionType | null,
            options?:  Array< {
              name?: string | null,
              value?: string | null,
              description?: string | null,
            } | null > | null,
            optionStep?: number | null,
            type: ModelInputOutputEntryType,
            constraints?:  Array< {
              type?: ModelInputConstraintType | null,
              value?: string | null,
            } | null > | null,
          } | null > | null,
          required?:  Array< {
            defaultValue?: string | null,
            description?: string | null,
            examples?: Array< string | null > | null,
            name: string,
            optionType?: OptionType | null,
            options?:  Array< {
              name?: string | null,
              value?: string | null,
              description?: string | null,
            } | null > | null,
            optionStep?: number | null,
            type: ModelInputOutputEntryType,
            constraints?:  Array< {
              type?: ModelInputConstraintType | null,
              value?: string | null,
            } | null > | null,
          } | null > | null,
        } | null,
      } | null >,
    } | null,
  } | null,
};

export type ListTasksQueryVariables = {
  filter?: ModelMLTaskDataFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListTasksQuery = {
  listMLTaskData?:  {
    nextToken?: string | null,
    items:  Array< {
      id: string,
      name?: string | null,
      category?: string | null,
      isPublic: boolean,
      Models?:  {
        items:  Array< {
          id: string,
          name?: string | null,
          mltaskdataID: string,
          outputs?:  {
            entries?:  Array< {
              name?: string | null,
              type?: ModelInputOutputEntryType | null,
            } | null > | null,
          } | null,
          inputs?:  {
            optional?:  Array< {
              defaultValue?: string | null,
              description?: string | null,
              examples?: Array< string | null > | null,
              name: string,
              optionType?: OptionType | null,
              options?:  Array< {
                name?: string | null,
                value?: string | null,
                description?: string | null,
              } | null > | null,
              optionStep?: number | null,
              type: ModelInputOutputEntryType,
              constraints?:  Array< {
                type?: ModelInputConstraintType | null,
                value?: string | null,
              } | null > | null,
            } | null > | null,
            required?:  Array< {
              defaultValue?: string | null,
              description?: string | null,
              examples?: Array< string | null > | null,
              name: string,
              optionType?: OptionType | null,
              options?:  Array< {
                name?: string | null,
                value?: string | null,
                description?: string | null,
              } | null > | null,
              optionStep?: number | null,
              type: ModelInputOutputEntryType,
              constraints?:  Array< {
                type?: ModelInputConstraintType | null,
                value?: string | null,
              } | null > | null,
            } | null > | null,
          } | null,
        } | null >,
      } | null,
    } | null >,
  } | null,
};

export type getUserExecutedTasksQueryVariables = {
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
  limit?: number | null,
  userID: string,
};

export type getUserExecutedTasksQuery = {
  listMLTaskExecutionResultsByCreatedAt?:  {
    items:  Array< {
      id: string,
      userID: string,
      createdAt: string,
      status?: TaskResultStatus | null,
      creditsUsed?: number | null,
      modelName?: string | null,
      privacyLevel?: TaskPrivacyLevels | null,
      modelsID: string,
      taskName?: string | null,
      inputs?:  Array< {
        name?: string | null,
        value?: string | null,
        type?: ModelInputOutputEntryType | null,
      } | null > | null,
      output?:  {
        message?: string | null,
        entries?:  Array< {
          name?: string | null,
          type?: ModelInputOutputEntryType | null,
          value?: string | null,
        } | null > | null,
      } | null,
      presetUserInput?:  {
        presetID?: string | null,
        presetName?: string | null,
        presetCreatorName?: string | null,
        presetCreatorUID?: string | null,
        presetValues?:  Array< {
          modelInputName?: string | null,
          presetEntryDatas?:  Array< {
            parameterName?: string | null,
            val?: string | null,
            presetOrder?: number | null,
          } | null > | null,
        } | null > | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type CustomListMLTaskExecutionResultsQueryVariables = {
  filter?: ModelMLTaskExecutionResultFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type CustomListMLTaskExecutionResultsQuery = {
  listMLTaskExecutionResults?:  {
    items:  Array< {
      id: string,
      userID: string,
      createdAt: string,
      status?: TaskResultStatus | null,
      creditsUsed?: number | null,
      modelName?: string | null,
      privacyLevel?: TaskPrivacyLevels | null,
      modelsID: string,
      taskName?: string | null,
      inputs?:  Array< {
        name?: string | null,
        value?: string | null,
        type?: ModelInputOutputEntryType | null,
      } | null > | null,
      output?:  {
        message?: string | null,
        entries?:  Array< {
          name?: string | null,
          type?: ModelInputOutputEntryType | null,
          value?: string | null,
        } | null > | null,
      } | null,
      presetUserInput?:  {
        presetID?: string | null,
        presetName?: string | null,
        presetCreatorName?: string | null,
        presetCreatorUID?: string | null,
        presetValues?:  Array< {
          modelInputName?: string | null,
          presetEntryDatas?:  Array< {
            parameterName?: string | null,
            val?: string | null,
            presetOrder?: number | null,
          } | null > | null,
        } | null > | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetUserCreditsQueryVariables = {
  id: string,
};

export type GetUserCreditsQuery = {
  getUser?:  {
    id: string,
    credits?: number | null,
  } | null,
};

export type CreateMLTaskDataMutationVariables = {
  condition?: ModelMLTaskDataConditionInput | null,
  input: CreateMLTaskDataInput,
};

export type CreateMLTaskDataMutation = {
  createMLTaskData?:  {
    Models?:  {
      items:  Array< {
        createdAt: string,
        id: string,
        isPublic: boolean,
        mltaskdataID: string,
        name?: string | null,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    category?: string | null,
    createdAt: string,
    id: string,
    isPublic: boolean,
    name?: string | null,
    updatedAt: string,
  } | null,
};

export type CreateMLTaskExecutionResultMutationVariables = {
  condition?: ModelMLTaskExecutionResultConditionInput | null,
  input: CreateMLTaskExecutionResultInput,
};

export type CreateMLTaskExecutionResultMutation = {
  createMLTaskExecutionResult?:  {
    ANGRYReactionStats?: number | null,
    CUTEReactionStats?: number | null,
    FUNNYReactionStats?: number | null,
    GROSSReactionStats?: number | null,
    HEARTReactionStats?: number | null,
    POOPReactionStats?: number | null,
    Reactions?:  {
      items:  Array< {
        createdAt: string,
        mltaskexecutionresultID: string,
        type: TaskReactionType,
        updatedAt: string,
        userID: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    SADReactionStats?: number | null,
    SCARYReactionStats?: number | null,
    WOWReactionStats?: number | null,
    createdAt: string,
    creditsUsed?: number | null,
    id: string,
    inputs?:  Array< {
      metaData?:  Array< {
        key?: string | null,
        value?: string | null,
      } | null > | null,
      name?: string | null,
      type?: ModelInputOutputEntryType | null,
      value?: string | null,
    } | null > | null,
    mltasksworkspaceID: string,
    modelName?: string | null,
    modelsID: string,
    output?:  {
      entries?:  Array< {
        name?: string | null,
        type?: ModelInputOutputEntryType | null,
        value?: string | null,
      } | null > | null,
      message?: string | null,
    } | null,
    presetUserInput?:  {
      presetCreatorName?: string | null,
      presetCreatorUID?: string | null,
      presetID?: string | null,
      presetName?: string | null,
      presetValues?:  Array< {
        modelInputName?: string | null,
      } | null > | null,
    } | null,
    privacyLevel?: TaskPrivacyLevels | null,
    status?: TaskResultStatus | null,
    taskName?: string | null,
    updatedAt: string,
    userID: string,
  } | null,
};

export type CreateMLTasksWorkspaceMutationVariables = {
  condition?: ModelMLTasksWorkspaceConditionInput | null,
  input: CreateMLTasksWorkspaceInput,
};

export type CreateMLTasksWorkspaceMutation = {
  createMLTasksWorkspace?:  {
    MLTaskExecutionResults?:  {
      items:  Array< {
        ANGRYReactionStats?: number | null,
        CUTEReactionStats?: number | null,
        FUNNYReactionStats?: number | null,
        GROSSReactionStats?: number | null,
        HEARTReactionStats?: number | null,
        POOPReactionStats?: number | null,
        SADReactionStats?: number | null,
        SCARYReactionStats?: number | null,
        WOWReactionStats?: number | null,
        createdAt: string,
        creditsUsed?: number | null,
        id: string,
        mltasksworkspaceID: string,
        modelName?: string | null,
        modelsID: string,
        privacyLevel?: TaskPrivacyLevels | null,
        status?: TaskResultStatus | null,
        taskName?: string | null,
        updatedAt: string,
        userID: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    description?: string | null,
    id: string,
    name?: string | null,
    updatedAt: string,
    userID: string,
  } | null,
};

export type CreateModelInputPresetMutationVariables = {
  condition?: ModelModelInputPresetConditionInput | null,
  input: CreateModelInputPresetInput,
};

export type CreateModelInputPresetMutation = {
  createModelInputPreset?:  {
    cost?: number | null,
    createdAt: string,
    description?: string | null,
    entries?:  Array< {
      modelInputName?: string | null,
      modelInputType?: ModelInputOutputEntryType | null,
      presetEntryDatas?:  Array< {
        description?: string | null,
        entryType?: InputPresetEntryType | null,
        parameterName?: string | null,
        presetOrder?: number | null,
        val?: string | null,
      } | null > | null,
    } | null > | null,
    id: string,
    isOpenSource?: boolean | null,
    isPublic?: boolean | null,
    modelName?: string | null,
    modelsID: string,
    presetName?: string | null,
    taskName?: string | null,
    updatedAt: string,
    usage?: number | null,
    userID: string,
    username?: string | null,
  } | null,
};

export type CreateModelsMutationVariables = {
  condition?: ModelModelsConditionInput | null,
  input: CreateModelsInput,
};

export type CreateModelsMutation = {
  createModels?:  {
    MLTaskExecutionResults?:  {
      items:  Array< {
        ANGRYReactionStats?: number | null,
        CUTEReactionStats?: number | null,
        FUNNYReactionStats?: number | null,
        GROSSReactionStats?: number | null,
        HEARTReactionStats?: number | null,
        POOPReactionStats?: number | null,
        SADReactionStats?: number | null,
        SCARYReactionStats?: number | null,
        WOWReactionStats?: number | null,
        createdAt: string,
        creditsUsed?: number | null,
        id: string,
        mltasksworkspaceID: string,
        modelName?: string | null,
        modelsID: string,
        privacyLevel?: TaskPrivacyLevels | null,
        status?: TaskResultStatus | null,
        taskName?: string | null,
        updatedAt: string,
        userID: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    ModelInputPresets?:  {
      items:  Array< {
        cost?: number | null,
        createdAt: string,
        description?: string | null,
        id: string,
        isOpenSource?: boolean | null,
        isPublic?: boolean | null,
        modelName?: string | null,
        modelsID: string,
        presetName?: string | null,
        taskName?: string | null,
        updatedAt: string,
        usage?: number | null,
        userID: string,
        username?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    id: string,
    inputs?:  {
      optional?:  Array< {
        defaultValue?: string | null,
        description?: string | null,
        examples?: Array< string | null > | null,
        name: string,
        optionStep?: number | null,
        optionType?: OptionType | null,
        type: ModelInputOutputEntryType,
      } | null > | null,
      required?:  Array< {
        defaultValue?: string | null,
        description?: string | null,
        examples?: Array< string | null > | null,
        name: string,
        optionStep?: number | null,
        optionType?: OptionType | null,
        type: ModelInputOutputEntryType,
      } | null > | null,
    } | null,
    isPublic: boolean,
    mltaskdataID: string,
    name?: string | null,
    outputs?:  {
      entries?:  Array< {
        name?: string | null,
        type?: ModelInputOutputEntryType | null,
      } | null > | null,
    } | null,
    updatedAt: string,
  } | null,
};

export type CreateSocialManPostMutationVariables = {
  condition?: ModelSocialManPostConditionInput | null,
  input: CreateSocialManPostInput,
};

export type CreateSocialManPostMutation = {
  createSocialManPost?:  {
    createdAt: string,
    description?: string | null,
    enabledFacebook?: boolean | null,
    enabledInstagram?: boolean | null,
    enabledLinkedin?: boolean | null,
    enabledPinterest?: boolean | null,
    enabledTiktok?: boolean | null,
    enabledTwitter?: boolean | null,
    enabledYoutube?: boolean | null,
    facebook?:  {
      caption: string,
      postID?: string | null,
      postToStory?: boolean | null,
      storyID?: string | null,
      storyURL?: string | null,
      targetPageID?: string | null,
      thumbnail?: string | null,
    } | null,
    id: string,
    instagram?:  {
      caption: string,
      postID?: string | null,
      postToStory?: boolean | null,
      storyID?: string | null,
      targetAccountID?: string | null,
      thumbnail?: string | null,
    } | null,
    linkedin?:  {
      caption?: string | null,
      mediaDescription?: string | null,
      postID?: string | null,
    } | null,
    pinterest?:  {
      boardID?: string | null,
      description?: string | null,
      link?: string | null,
      pinID?: string | null,
      thumbnailURL?: string | null,
      title?: string | null,
    } | null,
    postedTime?: number | null,
    postedToFacebook?: boolean | null,
    postedToInstagram?: boolean | null,
    postedToLinkedin?: boolean | null,
    postedToPinterest?: boolean | null,
    postedToTiktok?: boolean | null,
    postedToTwitter?: boolean | null,
    postedToYoutube?: boolean | null,
    s3Key?: string | null,
    s3KeyResized?: string | null,
    scheduledPostTime?: number | null,
    status: SocialManPostStatus,
    tiktok?:  {
      caption?: string | null,
      contentDisclosureBrandedContent?: boolean | null,
      contentDisclosureEnabled?: boolean | null,
      contentDisclosureYourBrand?: boolean | null,
      postID?: string | null,
      privacy?: SocialManTiktokPrivacyStatus | null,
      title?: string | null,
      usersCanComment?: boolean | null,
      usersCanDuet?: boolean | null,
      usersCanStitch?: boolean | null,
      videoCoverTimestampMs?: number | null,
    } | null,
    title?: string | null,
    twitter?:  {
      caption: string,
      tweetID?: string | null,
    } | null,
    updatedAt: string,
    userID: string,
    youtube?:  {
      category?: string | null,
      description?: string | null,
      privacy?: string | null,
      tags?: string | null,
      targetChannelID?: string | null,
      thumbnailURL?: string | null,
      title?: string | null,
      videoID?: string | null,
    } | null,
  } | null,
};

export type CreateTaskReactionMutationVariables = {
  condition?: ModelTaskReactionConditionInput | null,
  input: CreateTaskReactionInput,
};

export type CreateTaskReactionMutation = {
  createTaskReaction?:  {
    createdAt: string,
    mltaskexecutionresultID: string,
    type: TaskReactionType,
    updatedAt: string,
    userID: string,
  } | null,
};

export type CreateUserMutationVariables = {
  condition?: ModelUserConditionInput | null,
  input: CreateUserInput,
};

export type CreateUserMutation = {
  createUser?:  {
    MLTaskExecutionResults?:  {
      items:  Array< {
        ANGRYReactionStats?: number | null,
        CUTEReactionStats?: number | null,
        FUNNYReactionStats?: number | null,
        GROSSReactionStats?: number | null,
        HEARTReactionStats?: number | null,
        POOPReactionStats?: number | null,
        SADReactionStats?: number | null,
        SCARYReactionStats?: number | null,
        WOWReactionStats?: number | null,
        createdAt: string,
        creditsUsed?: number | null,
        id: string,
        mltasksworkspaceID: string,
        modelName?: string | null,
        modelsID: string,
        privacyLevel?: TaskPrivacyLevels | null,
        status?: TaskResultStatus | null,
        taskName?: string | null,
        updatedAt: string,
        userID: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    MLTasksWorkspaces?:  {
      items:  Array< {
        createdAt: string,
        description?: string | null,
        id: string,
        name?: string | null,
        updatedAt: string,
        userID: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    ModelInputPresets?:  {
      items:  Array< {
        cost?: number | null,
        createdAt: string,
        description?: string | null,
        id: string,
        isOpenSource?: boolean | null,
        isPublic?: boolean | null,
        modelName?: string | null,
        modelsID: string,
        presetName?: string | null,
        taskName?: string | null,
        updatedAt: string,
        usage?: number | null,
        userID: string,
        username?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    SocialManPosts?:  {
      items:  Array< {
        createdAt: string,
        description?: string | null,
        enabledFacebook?: boolean | null,
        enabledInstagram?: boolean | null,
        enabledLinkedin?: boolean | null,
        enabledPinterest?: boolean | null,
        enabledTiktok?: boolean | null,
        enabledTwitter?: boolean | null,
        enabledYoutube?: boolean | null,
        id: string,
        postedTime?: number | null,
        postedToFacebook?: boolean | null,
        postedToInstagram?: boolean | null,
        postedToLinkedin?: boolean | null,
        postedToPinterest?: boolean | null,
        postedToTiktok?: boolean | null,
        postedToTwitter?: boolean | null,
        postedToYoutube?: boolean | null,
        s3Key?: string | null,
        s3KeyResized?: string | null,
        scheduledPostTime?: number | null,
        status: SocialManPostStatus,
        title?: string | null,
        updatedAt: string,
        userID: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    completedTasksCount?: number | null,
    createdAt: string,
    createdPresetCount?: number | null,
    credits?: number | null,
    currentSubscriptionPlan?: SubscriptionPlan | null,
    email?: string | null,
    facebook?:  {
      instagramsData:  Array< {
        accessToken?: string | null,
        biography?: string | null,
        connectedPageID?: string | null,
        expiresOn?: number | null,
        followersCount?: number | null,
        followsCount?: number | null,
        id?: string | null,
        igID?: string | null,
        mediaCount?: number | null,
        name?: string | null,
        profilePictureURL?: string | null,
        username?: string | null,
      } | null >,
      pagesData:  Array< {
        accessToken?: string | null,
        category?: string | null,
        expiresOn?: number | null,
        id?: string | null,
        name?: string | null,
        picture?: string | null,
      } | null >,
      userData:  {
        accessToken?: string | null,
        expiresOn?: number | null,
        id?: string | null,
        name?: string | null,
        picture?: string | null,
      },
    } | null,
    google?:  {
      channelsData:  Array< {
        customUrl?: string | null,
        description?: string | null,
        id?: string | null,
        thumbnail?: string | null,
        title?: string | null,
      } | null >,
      tokenData:  {
        accessToken?: string | null,
        expiresOn?: number | null,
        idToken?: string | null,
        refreshToken?: string | null,
        scope?: string | null,
        tokenType?: string | null,
      },
      userData:  {
        email?: string | null,
        familyName?: string | null,
        givenName?: string | null,
        id?: string | null,
        locale?: string | null,
        name?: string | null,
        picture?: string | null,
        verifiedEmail?: boolean | null,
      },
    } | null,
    id: string,
    linkedin?:  {
      tokenData:  {
        accessToken: string,
        accessTokenExpirationDate: number,
        scope: string,
      },
      userData:  {
        email?: string | null,
        name?: string | null,
        picture?: string | null,
        sub?: string | null,
      },
    } | null,
    name?: string | null,
    periodEndOfLastSubscriptionCredits?: number | null,
    picture?: string | null,
    pinterest?:  {
      boardsData?:  Array< {
        createdAt?: string | null,
        description?: string | null,
        followerCount?: number | null,
        id?: string | null,
        imageCoverUrl?: string | null,
        name?: string | null,
        pinCount?: number | null,
        privacy?: string | null,
      } | null > | null,
      tokenData:  {
        accessToken: string,
        accessTokenExpirationDate: number,
        refreshToken: string,
        refreshTokenExpirationDate: number,
        scope: string,
      },
      userData:  {
        about?: string | null,
        accountType?: string | null,
        boardCount?: number | null,
        businessName?: string | null,
        followerCount?: number | null,
        followingCount?: number | null,
        id?: string | null,
        monthlyViews?: number | null,
        pinCount?: number | null,
        profileImage?: string | null,
        username?: string | null,
        websiteUrl?: string | null,
      },
    } | null,
    stripeConnectAccountID?: string | null,
    stripeConnectAccountSetupComplete?: boolean | null,
    stripeCustomerID?: string | null,
    tiktok?:  {
      tokenData:  {
        accessToken: string,
        accessTokenExpirationDate: number,
        refreshToken: string,
        refreshTokenExpirationDate: number,
        scope: string,
      },
      userData:  {
        avatarLargeURL?: string | null,
        avatarURL?: string | null,
        avatarURL100?: string | null,
        bioDescription?: string | null,
        commentDisabled?: boolean | null,
        displayName?: string | null,
        duetDisabled?: boolean | null,
        followerCount?: number | null,
        followingCount?: number | null,
        isVerified?: boolean | null,
        likesCount?: number | null,
        maxVideoPostDuration_sec?: number | null,
        privacyLevelOptions?: Array< string | null > | null,
        profileDeepLink?: string | null,
        stitchDisabled?: boolean | null,
        username?: string | null,
        videoCount?: number | null,
      },
    } | null,
    twitter?:  {
      tokenData:  {
        accessToken?: string | null,
        accessTokenSecret?: string | null,
        codeVerifier?: string | null,
        expiresOn?: number | null,
        oauthTokenSecret?: string | null,
        refreshToken?: string | null,
      },
      userData:  {
        description?: string | null,
        id?: string | null,
        name?: string | null,
        profilePictureURL?: string | null,
        username?: string | null,
        verifiedType?: TwitterVerifiedType | null,
      },
    } | null,
    updatedAt: string,
    username?: string | null,
  } | null,
};

export type DeleteMLTaskDataMutationVariables = {
  condition?: ModelMLTaskDataConditionInput | null,
  input: DeleteMLTaskDataInput,
};

export type DeleteMLTaskDataMutation = {
  deleteMLTaskData?:  {
    Models?:  {
      items:  Array< {
        createdAt: string,
        id: string,
        isPublic: boolean,
        mltaskdataID: string,
        name?: string | null,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    category?: string | null,
    createdAt: string,
    id: string,
    isPublic: boolean,
    name?: string | null,
    updatedAt: string,
  } | null,
};

export type DeleteMLTaskExecutionResultMutationVariables = {
  condition?: ModelMLTaskExecutionResultConditionInput | null,
  input: DeleteMLTaskExecutionResultInput,
};

export type DeleteMLTaskExecutionResultMutation = {
  deleteMLTaskExecutionResult?:  {
    ANGRYReactionStats?: number | null,
    CUTEReactionStats?: number | null,
    FUNNYReactionStats?: number | null,
    GROSSReactionStats?: number | null,
    HEARTReactionStats?: number | null,
    POOPReactionStats?: number | null,
    Reactions?:  {
      items:  Array< {
        createdAt: string,
        mltaskexecutionresultID: string,
        type: TaskReactionType,
        updatedAt: string,
        userID: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    SADReactionStats?: number | null,
    SCARYReactionStats?: number | null,
    WOWReactionStats?: number | null,
    createdAt: string,
    creditsUsed?: number | null,
    id: string,
    inputs?:  Array< {
      metaData?:  Array< {
        key?: string | null,
        value?: string | null,
      } | null > | null,
      name?: string | null,
      type?: ModelInputOutputEntryType | null,
      value?: string | null,
    } | null > | null,
    mltasksworkspaceID: string,
    modelName?: string | null,
    modelsID: string,
    output?:  {
      entries?:  Array< {
        name?: string | null,
        type?: ModelInputOutputEntryType | null,
        value?: string | null,
      } | null > | null,
      message?: string | null,
    } | null,
    presetUserInput?:  {
      presetCreatorName?: string | null,
      presetCreatorUID?: string | null,
      presetID?: string | null,
      presetName?: string | null,
      presetValues?:  Array< {
        modelInputName?: string | null,
      } | null > | null,
    } | null,
    privacyLevel?: TaskPrivacyLevels | null,
    status?: TaskResultStatus | null,
    taskName?: string | null,
    updatedAt: string,
    userID: string,
  } | null,
};

export type DeleteMLTasksWorkspaceMutationVariables = {
  condition?: ModelMLTasksWorkspaceConditionInput | null,
  input: DeleteMLTasksWorkspaceInput,
};

export type DeleteMLTasksWorkspaceMutation = {
  deleteMLTasksWorkspace?:  {
    MLTaskExecutionResults?:  {
      items:  Array< {
        ANGRYReactionStats?: number | null,
        CUTEReactionStats?: number | null,
        FUNNYReactionStats?: number | null,
        GROSSReactionStats?: number | null,
        HEARTReactionStats?: number | null,
        POOPReactionStats?: number | null,
        SADReactionStats?: number | null,
        SCARYReactionStats?: number | null,
        WOWReactionStats?: number | null,
        createdAt: string,
        creditsUsed?: number | null,
        id: string,
        mltasksworkspaceID: string,
        modelName?: string | null,
        modelsID: string,
        privacyLevel?: TaskPrivacyLevels | null,
        status?: TaskResultStatus | null,
        taskName?: string | null,
        updatedAt: string,
        userID: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    description?: string | null,
    id: string,
    name?: string | null,
    updatedAt: string,
    userID: string,
  } | null,
};

export type DeleteModelInputPresetMutationVariables = {
  condition?: ModelModelInputPresetConditionInput | null,
  input: DeleteModelInputPresetInput,
};

export type DeleteModelInputPresetMutation = {
  deleteModelInputPreset?:  {
    cost?: number | null,
    createdAt: string,
    description?: string | null,
    entries?:  Array< {
      modelInputName?: string | null,
      modelInputType?: ModelInputOutputEntryType | null,
      presetEntryDatas?:  Array< {
        description?: string | null,
        entryType?: InputPresetEntryType | null,
        parameterName?: string | null,
        presetOrder?: number | null,
        val?: string | null,
      } | null > | null,
    } | null > | null,
    id: string,
    isOpenSource?: boolean | null,
    isPublic?: boolean | null,
    modelName?: string | null,
    modelsID: string,
    presetName?: string | null,
    taskName?: string | null,
    updatedAt: string,
    usage?: number | null,
    userID: string,
    username?: string | null,
  } | null,
};

export type DeleteModelsMutationVariables = {
  condition?: ModelModelsConditionInput | null,
  input: DeleteModelsInput,
};

export type DeleteModelsMutation = {
  deleteModels?:  {
    MLTaskExecutionResults?:  {
      items:  Array< {
        ANGRYReactionStats?: number | null,
        CUTEReactionStats?: number | null,
        FUNNYReactionStats?: number | null,
        GROSSReactionStats?: number | null,
        HEARTReactionStats?: number | null,
        POOPReactionStats?: number | null,
        SADReactionStats?: number | null,
        SCARYReactionStats?: number | null,
        WOWReactionStats?: number | null,
        createdAt: string,
        creditsUsed?: number | null,
        id: string,
        mltasksworkspaceID: string,
        modelName?: string | null,
        modelsID: string,
        privacyLevel?: TaskPrivacyLevels | null,
        status?: TaskResultStatus | null,
        taskName?: string | null,
        updatedAt: string,
        userID: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    ModelInputPresets?:  {
      items:  Array< {
        cost?: number | null,
        createdAt: string,
        description?: string | null,
        id: string,
        isOpenSource?: boolean | null,
        isPublic?: boolean | null,
        modelName?: string | null,
        modelsID: string,
        presetName?: string | null,
        taskName?: string | null,
        updatedAt: string,
        usage?: number | null,
        userID: string,
        username?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    id: string,
    inputs?:  {
      optional?:  Array< {
        defaultValue?: string | null,
        description?: string | null,
        examples?: Array< string | null > | null,
        name: string,
        optionStep?: number | null,
        optionType?: OptionType | null,
        type: ModelInputOutputEntryType,
      } | null > | null,
      required?:  Array< {
        defaultValue?: string | null,
        description?: string | null,
        examples?: Array< string | null > | null,
        name: string,
        optionStep?: number | null,
        optionType?: OptionType | null,
        type: ModelInputOutputEntryType,
      } | null > | null,
    } | null,
    isPublic: boolean,
    mltaskdataID: string,
    name?: string | null,
    outputs?:  {
      entries?:  Array< {
        name?: string | null,
        type?: ModelInputOutputEntryType | null,
      } | null > | null,
    } | null,
    updatedAt: string,
  } | null,
};

export type DeleteSocialManPostMutationVariables = {
  condition?: ModelSocialManPostConditionInput | null,
  input: DeleteSocialManPostInput,
};

export type DeleteSocialManPostMutation = {
  deleteSocialManPost?:  {
    createdAt: string,
    description?: string | null,
    enabledFacebook?: boolean | null,
    enabledInstagram?: boolean | null,
    enabledLinkedin?: boolean | null,
    enabledPinterest?: boolean | null,
    enabledTiktok?: boolean | null,
    enabledTwitter?: boolean | null,
    enabledYoutube?: boolean | null,
    facebook?:  {
      caption: string,
      postID?: string | null,
      postToStory?: boolean | null,
      storyID?: string | null,
      storyURL?: string | null,
      targetPageID?: string | null,
      thumbnail?: string | null,
    } | null,
    id: string,
    instagram?:  {
      caption: string,
      postID?: string | null,
      postToStory?: boolean | null,
      storyID?: string | null,
      targetAccountID?: string | null,
      thumbnail?: string | null,
    } | null,
    linkedin?:  {
      caption?: string | null,
      mediaDescription?: string | null,
      postID?: string | null,
    } | null,
    pinterest?:  {
      boardID?: string | null,
      description?: string | null,
      link?: string | null,
      pinID?: string | null,
      thumbnailURL?: string | null,
      title?: string | null,
    } | null,
    postedTime?: number | null,
    postedToFacebook?: boolean | null,
    postedToInstagram?: boolean | null,
    postedToLinkedin?: boolean | null,
    postedToPinterest?: boolean | null,
    postedToTiktok?: boolean | null,
    postedToTwitter?: boolean | null,
    postedToYoutube?: boolean | null,
    s3Key?: string | null,
    s3KeyResized?: string | null,
    scheduledPostTime?: number | null,
    status: SocialManPostStatus,
    tiktok?:  {
      caption?: string | null,
      contentDisclosureBrandedContent?: boolean | null,
      contentDisclosureEnabled?: boolean | null,
      contentDisclosureYourBrand?: boolean | null,
      postID?: string | null,
      privacy?: SocialManTiktokPrivacyStatus | null,
      title?: string | null,
      usersCanComment?: boolean | null,
      usersCanDuet?: boolean | null,
      usersCanStitch?: boolean | null,
      videoCoverTimestampMs?: number | null,
    } | null,
    title?: string | null,
    twitter?:  {
      caption: string,
      tweetID?: string | null,
    } | null,
    updatedAt: string,
    userID: string,
    youtube?:  {
      category?: string | null,
      description?: string | null,
      privacy?: string | null,
      tags?: string | null,
      targetChannelID?: string | null,
      thumbnailURL?: string | null,
      title?: string | null,
      videoID?: string | null,
    } | null,
  } | null,
};

export type DeleteTaskReactionMutationVariables = {
  condition?: ModelTaskReactionConditionInput | null,
  input: DeleteTaskReactionInput,
};

export type DeleteTaskReactionMutation = {
  deleteTaskReaction?:  {
    createdAt: string,
    mltaskexecutionresultID: string,
    type: TaskReactionType,
    updatedAt: string,
    userID: string,
  } | null,
};

export type DeleteUserMutationVariables = {
  condition?: ModelUserConditionInput | null,
  input: DeleteUserInput,
};

export type DeleteUserMutation = {
  deleteUser?:  {
    MLTaskExecutionResults?:  {
      items:  Array< {
        ANGRYReactionStats?: number | null,
        CUTEReactionStats?: number | null,
        FUNNYReactionStats?: number | null,
        GROSSReactionStats?: number | null,
        HEARTReactionStats?: number | null,
        POOPReactionStats?: number | null,
        SADReactionStats?: number | null,
        SCARYReactionStats?: number | null,
        WOWReactionStats?: number | null,
        createdAt: string,
        creditsUsed?: number | null,
        id: string,
        mltasksworkspaceID: string,
        modelName?: string | null,
        modelsID: string,
        privacyLevel?: TaskPrivacyLevels | null,
        status?: TaskResultStatus | null,
        taskName?: string | null,
        updatedAt: string,
        userID: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    MLTasksWorkspaces?:  {
      items:  Array< {
        createdAt: string,
        description?: string | null,
        id: string,
        name?: string | null,
        updatedAt: string,
        userID: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    ModelInputPresets?:  {
      items:  Array< {
        cost?: number | null,
        createdAt: string,
        description?: string | null,
        id: string,
        isOpenSource?: boolean | null,
        isPublic?: boolean | null,
        modelName?: string | null,
        modelsID: string,
        presetName?: string | null,
        taskName?: string | null,
        updatedAt: string,
        usage?: number | null,
        userID: string,
        username?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    SocialManPosts?:  {
      items:  Array< {
        createdAt: string,
        description?: string | null,
        enabledFacebook?: boolean | null,
        enabledInstagram?: boolean | null,
        enabledLinkedin?: boolean | null,
        enabledPinterest?: boolean | null,
        enabledTiktok?: boolean | null,
        enabledTwitter?: boolean | null,
        enabledYoutube?: boolean | null,
        id: string,
        postedTime?: number | null,
        postedToFacebook?: boolean | null,
        postedToInstagram?: boolean | null,
        postedToLinkedin?: boolean | null,
        postedToPinterest?: boolean | null,
        postedToTiktok?: boolean | null,
        postedToTwitter?: boolean | null,
        postedToYoutube?: boolean | null,
        s3Key?: string | null,
        s3KeyResized?: string | null,
        scheduledPostTime?: number | null,
        status: SocialManPostStatus,
        title?: string | null,
        updatedAt: string,
        userID: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    completedTasksCount?: number | null,
    createdAt: string,
    createdPresetCount?: number | null,
    credits?: number | null,
    currentSubscriptionPlan?: SubscriptionPlan | null,
    email?: string | null,
    facebook?:  {
      instagramsData:  Array< {
        accessToken?: string | null,
        biography?: string | null,
        connectedPageID?: string | null,
        expiresOn?: number | null,
        followersCount?: number | null,
        followsCount?: number | null,
        id?: string | null,
        igID?: string | null,
        mediaCount?: number | null,
        name?: string | null,
        profilePictureURL?: string | null,
        username?: string | null,
      } | null >,
      pagesData:  Array< {
        accessToken?: string | null,
        category?: string | null,
        expiresOn?: number | null,
        id?: string | null,
        name?: string | null,
        picture?: string | null,
      } | null >,
      userData:  {
        accessToken?: string | null,
        expiresOn?: number | null,
        id?: string | null,
        name?: string | null,
        picture?: string | null,
      },
    } | null,
    google?:  {
      channelsData:  Array< {
        customUrl?: string | null,
        description?: string | null,
        id?: string | null,
        thumbnail?: string | null,
        title?: string | null,
      } | null >,
      tokenData:  {
        accessToken?: string | null,
        expiresOn?: number | null,
        idToken?: string | null,
        refreshToken?: string | null,
        scope?: string | null,
        tokenType?: string | null,
      },
      userData:  {
        email?: string | null,
        familyName?: string | null,
        givenName?: string | null,
        id?: string | null,
        locale?: string | null,
        name?: string | null,
        picture?: string | null,
        verifiedEmail?: boolean | null,
      },
    } | null,
    id: string,
    linkedin?:  {
      tokenData:  {
        accessToken: string,
        accessTokenExpirationDate: number,
        scope: string,
      },
      userData:  {
        email?: string | null,
        name?: string | null,
        picture?: string | null,
        sub?: string | null,
      },
    } | null,
    name?: string | null,
    periodEndOfLastSubscriptionCredits?: number | null,
    picture?: string | null,
    pinterest?:  {
      boardsData?:  Array< {
        createdAt?: string | null,
        description?: string | null,
        followerCount?: number | null,
        id?: string | null,
        imageCoverUrl?: string | null,
        name?: string | null,
        pinCount?: number | null,
        privacy?: string | null,
      } | null > | null,
      tokenData:  {
        accessToken: string,
        accessTokenExpirationDate: number,
        refreshToken: string,
        refreshTokenExpirationDate: number,
        scope: string,
      },
      userData:  {
        about?: string | null,
        accountType?: string | null,
        boardCount?: number | null,
        businessName?: string | null,
        followerCount?: number | null,
        followingCount?: number | null,
        id?: string | null,
        monthlyViews?: number | null,
        pinCount?: number | null,
        profileImage?: string | null,
        username?: string | null,
        websiteUrl?: string | null,
      },
    } | null,
    stripeConnectAccountID?: string | null,
    stripeConnectAccountSetupComplete?: boolean | null,
    stripeCustomerID?: string | null,
    tiktok?:  {
      tokenData:  {
        accessToken: string,
        accessTokenExpirationDate: number,
        refreshToken: string,
        refreshTokenExpirationDate: number,
        scope: string,
      },
      userData:  {
        avatarLargeURL?: string | null,
        avatarURL?: string | null,
        avatarURL100?: string | null,
        bioDescription?: string | null,
        commentDisabled?: boolean | null,
        displayName?: string | null,
        duetDisabled?: boolean | null,
        followerCount?: number | null,
        followingCount?: number | null,
        isVerified?: boolean | null,
        likesCount?: number | null,
        maxVideoPostDuration_sec?: number | null,
        privacyLevelOptions?: Array< string | null > | null,
        profileDeepLink?: string | null,
        stitchDisabled?: boolean | null,
        username?: string | null,
        videoCount?: number | null,
      },
    } | null,
    twitter?:  {
      tokenData:  {
        accessToken?: string | null,
        accessTokenSecret?: string | null,
        codeVerifier?: string | null,
        expiresOn?: number | null,
        oauthTokenSecret?: string | null,
        refreshToken?: string | null,
      },
      userData:  {
        description?: string | null,
        id?: string | null,
        name?: string | null,
        profilePictureURL?: string | null,
        username?: string | null,
        verifiedType?: TwitterVerifiedType | null,
      },
    } | null,
    updatedAt: string,
    username?: string | null,
  } | null,
};

export type UpdateMLTaskDataMutationVariables = {
  condition?: ModelMLTaskDataConditionInput | null,
  input: UpdateMLTaskDataInput,
};

export type UpdateMLTaskDataMutation = {
  updateMLTaskData?:  {
    Models?:  {
      items:  Array< {
        createdAt: string,
        id: string,
        isPublic: boolean,
        mltaskdataID: string,
        name?: string | null,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    category?: string | null,
    createdAt: string,
    id: string,
    isPublic: boolean,
    name?: string | null,
    updatedAt: string,
  } | null,
};

export type UpdateMLTaskExecutionResultMutationVariables = {
  condition?: ModelMLTaskExecutionResultConditionInput | null,
  input: UpdateMLTaskExecutionResultInput,
};

export type UpdateMLTaskExecutionResultMutation = {
  updateMLTaskExecutionResult?:  {
    ANGRYReactionStats?: number | null,
    CUTEReactionStats?: number | null,
    FUNNYReactionStats?: number | null,
    GROSSReactionStats?: number | null,
    HEARTReactionStats?: number | null,
    POOPReactionStats?: number | null,
    Reactions?:  {
      items:  Array< {
        createdAt: string,
        mltaskexecutionresultID: string,
        type: TaskReactionType,
        updatedAt: string,
        userID: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    SADReactionStats?: number | null,
    SCARYReactionStats?: number | null,
    WOWReactionStats?: number | null,
    createdAt: string,
    creditsUsed?: number | null,
    id: string,
    inputs?:  Array< {
      metaData?:  Array< {
        key?: string | null,
        value?: string | null,
      } | null > | null,
      name?: string | null,
      type?: ModelInputOutputEntryType | null,
      value?: string | null,
    } | null > | null,
    mltasksworkspaceID: string,
    modelName?: string | null,
    modelsID: string,
    output?:  {
      entries?:  Array< {
        name?: string | null,
        type?: ModelInputOutputEntryType | null,
        value?: string | null,
      } | null > | null,
      message?: string | null,
    } | null,
    presetUserInput?:  {
      presetCreatorName?: string | null,
      presetCreatorUID?: string | null,
      presetID?: string | null,
      presetName?: string | null,
      presetValues?:  Array< {
        modelInputName?: string | null,
      } | null > | null,
    } | null,
    privacyLevel?: TaskPrivacyLevels | null,
    status?: TaskResultStatus | null,
    taskName?: string | null,
    updatedAt: string,
    userID: string,
  } | null,
};

export type UpdateMLTasksWorkspaceMutationVariables = {
  condition?: ModelMLTasksWorkspaceConditionInput | null,
  input: UpdateMLTasksWorkspaceInput,
};

export type UpdateMLTasksWorkspaceMutation = {
  updateMLTasksWorkspace?:  {
    MLTaskExecutionResults?:  {
      items:  Array< {
        ANGRYReactionStats?: number | null,
        CUTEReactionStats?: number | null,
        FUNNYReactionStats?: number | null,
        GROSSReactionStats?: number | null,
        HEARTReactionStats?: number | null,
        POOPReactionStats?: number | null,
        SADReactionStats?: number | null,
        SCARYReactionStats?: number | null,
        WOWReactionStats?: number | null,
        createdAt: string,
        creditsUsed?: number | null,
        id: string,
        mltasksworkspaceID: string,
        modelName?: string | null,
        modelsID: string,
        privacyLevel?: TaskPrivacyLevels | null,
        status?: TaskResultStatus | null,
        taskName?: string | null,
        updatedAt: string,
        userID: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    description?: string | null,
    id: string,
    name?: string | null,
    updatedAt: string,
    userID: string,
  } | null,
};

export type UpdateModelInputPresetMutationVariables = {
  condition?: ModelModelInputPresetConditionInput | null,
  input: UpdateModelInputPresetInput,
};

export type UpdateModelInputPresetMutation = {
  updateModelInputPreset?:  {
    cost?: number | null,
    createdAt: string,
    description?: string | null,
    entries?:  Array< {
      modelInputName?: string | null,
      modelInputType?: ModelInputOutputEntryType | null,
      presetEntryDatas?:  Array< {
        description?: string | null,
        entryType?: InputPresetEntryType | null,
        parameterName?: string | null,
        presetOrder?: number | null,
        val?: string | null,
      } | null > | null,
    } | null > | null,
    id: string,
    isOpenSource?: boolean | null,
    isPublic?: boolean | null,
    modelName?: string | null,
    modelsID: string,
    presetName?: string | null,
    taskName?: string | null,
    updatedAt: string,
    usage?: number | null,
    userID: string,
    username?: string | null,
  } | null,
};

export type UpdateModelsMutationVariables = {
  condition?: ModelModelsConditionInput | null,
  input: UpdateModelsInput,
};

export type UpdateModelsMutation = {
  updateModels?:  {
    MLTaskExecutionResults?:  {
      items:  Array< {
        ANGRYReactionStats?: number | null,
        CUTEReactionStats?: number | null,
        FUNNYReactionStats?: number | null,
        GROSSReactionStats?: number | null,
        HEARTReactionStats?: number | null,
        POOPReactionStats?: number | null,
        SADReactionStats?: number | null,
        SCARYReactionStats?: number | null,
        WOWReactionStats?: number | null,
        createdAt: string,
        creditsUsed?: number | null,
        id: string,
        mltasksworkspaceID: string,
        modelName?: string | null,
        modelsID: string,
        privacyLevel?: TaskPrivacyLevels | null,
        status?: TaskResultStatus | null,
        taskName?: string | null,
        updatedAt: string,
        userID: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    ModelInputPresets?:  {
      items:  Array< {
        cost?: number | null,
        createdAt: string,
        description?: string | null,
        id: string,
        isOpenSource?: boolean | null,
        isPublic?: boolean | null,
        modelName?: string | null,
        modelsID: string,
        presetName?: string | null,
        taskName?: string | null,
        updatedAt: string,
        usage?: number | null,
        userID: string,
        username?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    id: string,
    inputs?:  {
      optional?:  Array< {
        defaultValue?: string | null,
        description?: string | null,
        examples?: Array< string | null > | null,
        name: string,
        optionStep?: number | null,
        optionType?: OptionType | null,
        type: ModelInputOutputEntryType,
      } | null > | null,
      required?:  Array< {
        defaultValue?: string | null,
        description?: string | null,
        examples?: Array< string | null > | null,
        name: string,
        optionStep?: number | null,
        optionType?: OptionType | null,
        type: ModelInputOutputEntryType,
      } | null > | null,
    } | null,
    isPublic: boolean,
    mltaskdataID: string,
    name?: string | null,
    outputs?:  {
      entries?:  Array< {
        name?: string | null,
        type?: ModelInputOutputEntryType | null,
      } | null > | null,
    } | null,
    updatedAt: string,
  } | null,
};

export type UpdateSocialManPostMutationVariables = {
  condition?: ModelSocialManPostConditionInput | null,
  input: UpdateSocialManPostInput,
};

export type UpdateSocialManPostMutation = {
  updateSocialManPost?:  {
    createdAt: string,
    description?: string | null,
    enabledFacebook?: boolean | null,
    enabledInstagram?: boolean | null,
    enabledLinkedin?: boolean | null,
    enabledPinterest?: boolean | null,
    enabledTiktok?: boolean | null,
    enabledTwitter?: boolean | null,
    enabledYoutube?: boolean | null,
    facebook?:  {
      caption: string,
      postID?: string | null,
      postToStory?: boolean | null,
      storyID?: string | null,
      storyURL?: string | null,
      targetPageID?: string | null,
      thumbnail?: string | null,
    } | null,
    id: string,
    instagram?:  {
      caption: string,
      postID?: string | null,
      postToStory?: boolean | null,
      storyID?: string | null,
      targetAccountID?: string | null,
      thumbnail?: string | null,
    } | null,
    linkedin?:  {
      caption?: string | null,
      mediaDescription?: string | null,
      postID?: string | null,
    } | null,
    pinterest?:  {
      boardID?: string | null,
      description?: string | null,
      link?: string | null,
      pinID?: string | null,
      thumbnailURL?: string | null,
      title?: string | null,
    } | null,
    postedTime?: number | null,
    postedToFacebook?: boolean | null,
    postedToInstagram?: boolean | null,
    postedToLinkedin?: boolean | null,
    postedToPinterest?: boolean | null,
    postedToTiktok?: boolean | null,
    postedToTwitter?: boolean | null,
    postedToYoutube?: boolean | null,
    s3Key?: string | null,
    s3KeyResized?: string | null,
    scheduledPostTime?: number | null,
    status: SocialManPostStatus,
    tiktok?:  {
      caption?: string | null,
      contentDisclosureBrandedContent?: boolean | null,
      contentDisclosureEnabled?: boolean | null,
      contentDisclosureYourBrand?: boolean | null,
      postID?: string | null,
      privacy?: SocialManTiktokPrivacyStatus | null,
      title?: string | null,
      usersCanComment?: boolean | null,
      usersCanDuet?: boolean | null,
      usersCanStitch?: boolean | null,
      videoCoverTimestampMs?: number | null,
    } | null,
    title?: string | null,
    twitter?:  {
      caption: string,
      tweetID?: string | null,
    } | null,
    updatedAt: string,
    userID: string,
    youtube?:  {
      category?: string | null,
      description?: string | null,
      privacy?: string | null,
      tags?: string | null,
      targetChannelID?: string | null,
      thumbnailURL?: string | null,
      title?: string | null,
      videoID?: string | null,
    } | null,
  } | null,
};

export type UpdateTaskReactionMutationVariables = {
  condition?: ModelTaskReactionConditionInput | null,
  input: UpdateTaskReactionInput,
};

export type UpdateTaskReactionMutation = {
  updateTaskReaction?:  {
    createdAt: string,
    mltaskexecutionresultID: string,
    type: TaskReactionType,
    updatedAt: string,
    userID: string,
  } | null,
};

export type UpdateUserMutationVariables = {
  condition?: ModelUserConditionInput | null,
  input: UpdateUserInput,
};

export type UpdateUserMutation = {
  updateUser?:  {
    MLTaskExecutionResults?:  {
      items:  Array< {
        ANGRYReactionStats?: number | null,
        CUTEReactionStats?: number | null,
        FUNNYReactionStats?: number | null,
        GROSSReactionStats?: number | null,
        HEARTReactionStats?: number | null,
        POOPReactionStats?: number | null,
        SADReactionStats?: number | null,
        SCARYReactionStats?: number | null,
        WOWReactionStats?: number | null,
        createdAt: string,
        creditsUsed?: number | null,
        id: string,
        mltasksworkspaceID: string,
        modelName?: string | null,
        modelsID: string,
        privacyLevel?: TaskPrivacyLevels | null,
        status?: TaskResultStatus | null,
        taskName?: string | null,
        updatedAt: string,
        userID: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    MLTasksWorkspaces?:  {
      items:  Array< {
        createdAt: string,
        description?: string | null,
        id: string,
        name?: string | null,
        updatedAt: string,
        userID: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    ModelInputPresets?:  {
      items:  Array< {
        cost?: number | null,
        createdAt: string,
        description?: string | null,
        id: string,
        isOpenSource?: boolean | null,
        isPublic?: boolean | null,
        modelName?: string | null,
        modelsID: string,
        presetName?: string | null,
        taskName?: string | null,
        updatedAt: string,
        usage?: number | null,
        userID: string,
        username?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    SocialManPosts?:  {
      items:  Array< {
        createdAt: string,
        description?: string | null,
        enabledFacebook?: boolean | null,
        enabledInstagram?: boolean | null,
        enabledLinkedin?: boolean | null,
        enabledPinterest?: boolean | null,
        enabledTiktok?: boolean | null,
        enabledTwitter?: boolean | null,
        enabledYoutube?: boolean | null,
        id: string,
        postedTime?: number | null,
        postedToFacebook?: boolean | null,
        postedToInstagram?: boolean | null,
        postedToLinkedin?: boolean | null,
        postedToPinterest?: boolean | null,
        postedToTiktok?: boolean | null,
        postedToTwitter?: boolean | null,
        postedToYoutube?: boolean | null,
        s3Key?: string | null,
        s3KeyResized?: string | null,
        scheduledPostTime?: number | null,
        status: SocialManPostStatus,
        title?: string | null,
        updatedAt: string,
        userID: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    completedTasksCount?: number | null,
    createdAt: string,
    createdPresetCount?: number | null,
    credits?: number | null,
    currentSubscriptionPlan?: SubscriptionPlan | null,
    email?: string | null,
    facebook?:  {
      instagramsData:  Array< {
        accessToken?: string | null,
        biography?: string | null,
        connectedPageID?: string | null,
        expiresOn?: number | null,
        followersCount?: number | null,
        followsCount?: number | null,
        id?: string | null,
        igID?: string | null,
        mediaCount?: number | null,
        name?: string | null,
        profilePictureURL?: string | null,
        username?: string | null,
      } | null >,
      pagesData:  Array< {
        accessToken?: string | null,
        category?: string | null,
        expiresOn?: number | null,
        id?: string | null,
        name?: string | null,
        picture?: string | null,
      } | null >,
      userData:  {
        accessToken?: string | null,
        expiresOn?: number | null,
        id?: string | null,
        name?: string | null,
        picture?: string | null,
      },
    } | null,
    google?:  {
      channelsData:  Array< {
        customUrl?: string | null,
        description?: string | null,
        id?: string | null,
        thumbnail?: string | null,
        title?: string | null,
      } | null >,
      tokenData:  {
        accessToken?: string | null,
        expiresOn?: number | null,
        idToken?: string | null,
        refreshToken?: string | null,
        scope?: string | null,
        tokenType?: string | null,
      },
      userData:  {
        email?: string | null,
        familyName?: string | null,
        givenName?: string | null,
        id?: string | null,
        locale?: string | null,
        name?: string | null,
        picture?: string | null,
        verifiedEmail?: boolean | null,
      },
    } | null,
    id: string,
    linkedin?:  {
      tokenData:  {
        accessToken: string,
        accessTokenExpirationDate: number,
        scope: string,
      },
      userData:  {
        email?: string | null,
        name?: string | null,
        picture?: string | null,
        sub?: string | null,
      },
    } | null,
    name?: string | null,
    periodEndOfLastSubscriptionCredits?: number | null,
    picture?: string | null,
    pinterest?:  {
      boardsData?:  Array< {
        createdAt?: string | null,
        description?: string | null,
        followerCount?: number | null,
        id?: string | null,
        imageCoverUrl?: string | null,
        name?: string | null,
        pinCount?: number | null,
        privacy?: string | null,
      } | null > | null,
      tokenData:  {
        accessToken: string,
        accessTokenExpirationDate: number,
        refreshToken: string,
        refreshTokenExpirationDate: number,
        scope: string,
      },
      userData:  {
        about?: string | null,
        accountType?: string | null,
        boardCount?: number | null,
        businessName?: string | null,
        followerCount?: number | null,
        followingCount?: number | null,
        id?: string | null,
        monthlyViews?: number | null,
        pinCount?: number | null,
        profileImage?: string | null,
        username?: string | null,
        websiteUrl?: string | null,
      },
    } | null,
    stripeConnectAccountID?: string | null,
    stripeConnectAccountSetupComplete?: boolean | null,
    stripeCustomerID?: string | null,
    tiktok?:  {
      tokenData:  {
        accessToken: string,
        accessTokenExpirationDate: number,
        refreshToken: string,
        refreshTokenExpirationDate: number,
        scope: string,
      },
      userData:  {
        avatarLargeURL?: string | null,
        avatarURL?: string | null,
        avatarURL100?: string | null,
        bioDescription?: string | null,
        commentDisabled?: boolean | null,
        displayName?: string | null,
        duetDisabled?: boolean | null,
        followerCount?: number | null,
        followingCount?: number | null,
        isVerified?: boolean | null,
        likesCount?: number | null,
        maxVideoPostDuration_sec?: number | null,
        privacyLevelOptions?: Array< string | null > | null,
        profileDeepLink?: string | null,
        stitchDisabled?: boolean | null,
        username?: string | null,
        videoCount?: number | null,
      },
    } | null,
    twitter?:  {
      tokenData:  {
        accessToken?: string | null,
        accessTokenSecret?: string | null,
        codeVerifier?: string | null,
        expiresOn?: number | null,
        oauthTokenSecret?: string | null,
        refreshToken?: string | null,
      },
      userData:  {
        description?: string | null,
        id?: string | null,
        name?: string | null,
        profilePictureURL?: string | null,
        username?: string | null,
        verifiedType?: TwitterVerifiedType | null,
      },
    } | null,
    updatedAt: string,
    username?: string | null,
  } | null,
};

export type UpdateUserUsernameMutationVariables = {
  userID: string,
  username: string,
};

export type UpdateUserUsernameMutation = {
  updateUserUsername?:  {
    MLTaskExecutionResults?:  {
      items:  Array< {
        ANGRYReactionStats?: number | null,
        CUTEReactionStats?: number | null,
        FUNNYReactionStats?: number | null,
        GROSSReactionStats?: number | null,
        HEARTReactionStats?: number | null,
        POOPReactionStats?: number | null,
        SADReactionStats?: number | null,
        SCARYReactionStats?: number | null,
        WOWReactionStats?: number | null,
        createdAt: string,
        creditsUsed?: number | null,
        id: string,
        mltasksworkspaceID: string,
        modelName?: string | null,
        modelsID: string,
        privacyLevel?: TaskPrivacyLevels | null,
        status?: TaskResultStatus | null,
        taskName?: string | null,
        updatedAt: string,
        userID: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    MLTasksWorkspaces?:  {
      items:  Array< {
        createdAt: string,
        description?: string | null,
        id: string,
        name?: string | null,
        updatedAt: string,
        userID: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    ModelInputPresets?:  {
      items:  Array< {
        cost?: number | null,
        createdAt: string,
        description?: string | null,
        id: string,
        isOpenSource?: boolean | null,
        isPublic?: boolean | null,
        modelName?: string | null,
        modelsID: string,
        presetName?: string | null,
        taskName?: string | null,
        updatedAt: string,
        usage?: number | null,
        userID: string,
        username?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    SocialManPosts?:  {
      items:  Array< {
        createdAt: string,
        description?: string | null,
        enabledFacebook?: boolean | null,
        enabledInstagram?: boolean | null,
        enabledLinkedin?: boolean | null,
        enabledPinterest?: boolean | null,
        enabledTiktok?: boolean | null,
        enabledTwitter?: boolean | null,
        enabledYoutube?: boolean | null,
        id: string,
        postedTime?: number | null,
        postedToFacebook?: boolean | null,
        postedToInstagram?: boolean | null,
        postedToLinkedin?: boolean | null,
        postedToPinterest?: boolean | null,
        postedToTiktok?: boolean | null,
        postedToTwitter?: boolean | null,
        postedToYoutube?: boolean | null,
        s3Key?: string | null,
        s3KeyResized?: string | null,
        scheduledPostTime?: number | null,
        status: SocialManPostStatus,
        title?: string | null,
        updatedAt: string,
        userID: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    completedTasksCount?: number | null,
    createdAt: string,
    createdPresetCount?: number | null,
    credits?: number | null,
    currentSubscriptionPlan?: SubscriptionPlan | null,
    email?: string | null,
    facebook?:  {
      instagramsData:  Array< {
        accessToken?: string | null,
        biography?: string | null,
        connectedPageID?: string | null,
        expiresOn?: number | null,
        followersCount?: number | null,
        followsCount?: number | null,
        id?: string | null,
        igID?: string | null,
        mediaCount?: number | null,
        name?: string | null,
        profilePictureURL?: string | null,
        username?: string | null,
      } | null >,
      pagesData:  Array< {
        accessToken?: string | null,
        category?: string | null,
        expiresOn?: number | null,
        id?: string | null,
        name?: string | null,
        picture?: string | null,
      } | null >,
      userData:  {
        accessToken?: string | null,
        expiresOn?: number | null,
        id?: string | null,
        name?: string | null,
        picture?: string | null,
      },
    } | null,
    google?:  {
      channelsData:  Array< {
        customUrl?: string | null,
        description?: string | null,
        id?: string | null,
        thumbnail?: string | null,
        title?: string | null,
      } | null >,
      tokenData:  {
        accessToken?: string | null,
        expiresOn?: number | null,
        idToken?: string | null,
        refreshToken?: string | null,
        scope?: string | null,
        tokenType?: string | null,
      },
      userData:  {
        email?: string | null,
        familyName?: string | null,
        givenName?: string | null,
        id?: string | null,
        locale?: string | null,
        name?: string | null,
        picture?: string | null,
        verifiedEmail?: boolean | null,
      },
    } | null,
    id: string,
    linkedin?:  {
      tokenData:  {
        accessToken: string,
        accessTokenExpirationDate: number,
        scope: string,
      },
      userData:  {
        email?: string | null,
        name?: string | null,
        picture?: string | null,
        sub?: string | null,
      },
    } | null,
    name?: string | null,
    periodEndOfLastSubscriptionCredits?: number | null,
    picture?: string | null,
    pinterest?:  {
      boardsData?:  Array< {
        createdAt?: string | null,
        description?: string | null,
        followerCount?: number | null,
        id?: string | null,
        imageCoverUrl?: string | null,
        name?: string | null,
        pinCount?: number | null,
        privacy?: string | null,
      } | null > | null,
      tokenData:  {
        accessToken: string,
        accessTokenExpirationDate: number,
        refreshToken: string,
        refreshTokenExpirationDate: number,
        scope: string,
      },
      userData:  {
        about?: string | null,
        accountType?: string | null,
        boardCount?: number | null,
        businessName?: string | null,
        followerCount?: number | null,
        followingCount?: number | null,
        id?: string | null,
        monthlyViews?: number | null,
        pinCount?: number | null,
        profileImage?: string | null,
        username?: string | null,
        websiteUrl?: string | null,
      },
    } | null,
    stripeConnectAccountID?: string | null,
    stripeConnectAccountSetupComplete?: boolean | null,
    stripeCustomerID?: string | null,
    tiktok?:  {
      tokenData:  {
        accessToken: string,
        accessTokenExpirationDate: number,
        refreshToken: string,
        refreshTokenExpirationDate: number,
        scope: string,
      },
      userData:  {
        avatarLargeURL?: string | null,
        avatarURL?: string | null,
        avatarURL100?: string | null,
        bioDescription?: string | null,
        commentDisabled?: boolean | null,
        displayName?: string | null,
        duetDisabled?: boolean | null,
        followerCount?: number | null,
        followingCount?: number | null,
        isVerified?: boolean | null,
        likesCount?: number | null,
        maxVideoPostDuration_sec?: number | null,
        privacyLevelOptions?: Array< string | null > | null,
        profileDeepLink?: string | null,
        stitchDisabled?: boolean | null,
        username?: string | null,
        videoCount?: number | null,
      },
    } | null,
    twitter?:  {
      tokenData:  {
        accessToken?: string | null,
        accessTokenSecret?: string | null,
        codeVerifier?: string | null,
        expiresOn?: number | null,
        oauthTokenSecret?: string | null,
        refreshToken?: string | null,
      },
      userData:  {
        description?: string | null,
        id?: string | null,
        name?: string | null,
        profilePictureURL?: string | null,
        username?: string | null,
        verifiedType?: TwitterVerifiedType | null,
      },
    } | null,
    updatedAt: string,
    username?: string | null,
  } | null,
};

export type AdjustUserCreditMutationVariables = {
  input: UpdateUserInput,
  incrementValue: number,
};

export type AdjustUserCreditMutation = {
  adjustUserCredit?:  {
    MLTaskExecutionResults?:  {
      items:  Array< {
        ANGRYReactionStats?: number | null,
        CUTEReactionStats?: number | null,
        FUNNYReactionStats?: number | null,
        GROSSReactionStats?: number | null,
        HEARTReactionStats?: number | null,
        POOPReactionStats?: number | null,
        SADReactionStats?: number | null,
        SCARYReactionStats?: number | null,
        WOWReactionStats?: number | null,
        createdAt: string,
        creditsUsed?: number | null,
        id: string,
        mltasksworkspaceID: string,
        modelName?: string | null,
        modelsID: string,
        privacyLevel?: TaskPrivacyLevels | null,
        status?: TaskResultStatus | null,
        taskName?: string | null,
        updatedAt: string,
        userID: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    MLTasksWorkspaces?:  {
      items:  Array< {
        createdAt: string,
        description?: string | null,
        id: string,
        name?: string | null,
        updatedAt: string,
        userID: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    ModelInputPresets?:  {
      items:  Array< {
        cost?: number | null,
        createdAt: string,
        description?: string | null,
        id: string,
        isOpenSource?: boolean | null,
        isPublic?: boolean | null,
        modelName?: string | null,
        modelsID: string,
        presetName?: string | null,
        taskName?: string | null,
        updatedAt: string,
        usage?: number | null,
        userID: string,
        username?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    SocialManPosts?:  {
      items:  Array< {
        createdAt: string,
        description?: string | null,
        enabledFacebook?: boolean | null,
        enabledInstagram?: boolean | null,
        enabledLinkedin?: boolean | null,
        enabledPinterest?: boolean | null,
        enabledTiktok?: boolean | null,
        enabledTwitter?: boolean | null,
        enabledYoutube?: boolean | null,
        id: string,
        postedTime?: number | null,
        postedToFacebook?: boolean | null,
        postedToInstagram?: boolean | null,
        postedToLinkedin?: boolean | null,
        postedToPinterest?: boolean | null,
        postedToTiktok?: boolean | null,
        postedToTwitter?: boolean | null,
        postedToYoutube?: boolean | null,
        s3Key?: string | null,
        s3KeyResized?: string | null,
        scheduledPostTime?: number | null,
        status: SocialManPostStatus,
        title?: string | null,
        updatedAt: string,
        userID: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    completedTasksCount?: number | null,
    createdAt: string,
    createdPresetCount?: number | null,
    credits?: number | null,
    currentSubscriptionPlan?: SubscriptionPlan | null,
    email?: string | null,
    facebook?:  {
      instagramsData:  Array< {
        accessToken?: string | null,
        biography?: string | null,
        connectedPageID?: string | null,
        expiresOn?: number | null,
        followersCount?: number | null,
        followsCount?: number | null,
        id?: string | null,
        igID?: string | null,
        mediaCount?: number | null,
        name?: string | null,
        profilePictureURL?: string | null,
        username?: string | null,
      } | null >,
      pagesData:  Array< {
        accessToken?: string | null,
        category?: string | null,
        expiresOn?: number | null,
        id?: string | null,
        name?: string | null,
        picture?: string | null,
      } | null >,
      userData:  {
        accessToken?: string | null,
        expiresOn?: number | null,
        id?: string | null,
        name?: string | null,
        picture?: string | null,
      },
    } | null,
    google?:  {
      channelsData:  Array< {
        customUrl?: string | null,
        description?: string | null,
        id?: string | null,
        thumbnail?: string | null,
        title?: string | null,
      } | null >,
      tokenData:  {
        accessToken?: string | null,
        expiresOn?: number | null,
        idToken?: string | null,
        refreshToken?: string | null,
        scope?: string | null,
        tokenType?: string | null,
      },
      userData:  {
        email?: string | null,
        familyName?: string | null,
        givenName?: string | null,
        id?: string | null,
        locale?: string | null,
        name?: string | null,
        picture?: string | null,
        verifiedEmail?: boolean | null,
      },
    } | null,
    id: string,
    linkedin?:  {
      tokenData:  {
        accessToken: string,
        accessTokenExpirationDate: number,
        scope: string,
      },
      userData:  {
        email?: string | null,
        name?: string | null,
        picture?: string | null,
        sub?: string | null,
      },
    } | null,
    name?: string | null,
    periodEndOfLastSubscriptionCredits?: number | null,
    picture?: string | null,
    pinterest?:  {
      boardsData?:  Array< {
        createdAt?: string | null,
        description?: string | null,
        followerCount?: number | null,
        id?: string | null,
        imageCoverUrl?: string | null,
        name?: string | null,
        pinCount?: number | null,
        privacy?: string | null,
      } | null > | null,
      tokenData:  {
        accessToken: string,
        accessTokenExpirationDate: number,
        refreshToken: string,
        refreshTokenExpirationDate: number,
        scope: string,
      },
      userData:  {
        about?: string | null,
        accountType?: string | null,
        boardCount?: number | null,
        businessName?: string | null,
        followerCount?: number | null,
        followingCount?: number | null,
        id?: string | null,
        monthlyViews?: number | null,
        pinCount?: number | null,
        profileImage?: string | null,
        username?: string | null,
        websiteUrl?: string | null,
      },
    } | null,
    stripeConnectAccountID?: string | null,
    stripeConnectAccountSetupComplete?: boolean | null,
    stripeCustomerID?: string | null,
    tiktok?:  {
      tokenData:  {
        accessToken: string,
        accessTokenExpirationDate: number,
        refreshToken: string,
        refreshTokenExpirationDate: number,
        scope: string,
      },
      userData:  {
        avatarLargeURL?: string | null,
        avatarURL?: string | null,
        avatarURL100?: string | null,
        bioDescription?: string | null,
        commentDisabled?: boolean | null,
        displayName?: string | null,
        duetDisabled?: boolean | null,
        followerCount?: number | null,
        followingCount?: number | null,
        isVerified?: boolean | null,
        likesCount?: number | null,
        maxVideoPostDuration_sec?: number | null,
        privacyLevelOptions?: Array< string | null > | null,
        profileDeepLink?: string | null,
        stitchDisabled?: boolean | null,
        username?: string | null,
        videoCount?: number | null,
      },
    } | null,
    twitter?:  {
      tokenData:  {
        accessToken?: string | null,
        accessTokenSecret?: string | null,
        codeVerifier?: string | null,
        expiresOn?: number | null,
        oauthTokenSecret?: string | null,
        refreshToken?: string | null,
      },
      userData:  {
        description?: string | null,
        id?: string | null,
        name?: string | null,
        profilePictureURL?: string | null,
        username?: string | null,
        verifiedType?: TwitterVerifiedType | null,
      },
    } | null,
    updatedAt: string,
    username?: string | null,
  } | null,
};

export type UpdateModelInputPresetIncrementUsageMutationVariables = {
  input: UpdateModelInputPresetInput,
  incrementValue?: number | null,
  condition?: ModelModelInputPresetConditionInput | null,
};

export type UpdateModelInputPresetIncrementUsageMutation = {
  updateModelInputPresetIncrementUsage?:  {
    cost?: number | null,
    createdAt: string,
    description?: string | null,
    entries?:  Array< {
      modelInputName?: string | null,
      modelInputType?: ModelInputOutputEntryType | null,
      presetEntryDatas?:  Array< {
        description?: string | null,
        entryType?: InputPresetEntryType | null,
        parameterName?: string | null,
        presetOrder?: number | null,
        val?: string | null,
      } | null > | null,
    } | null > | null,
    id: string,
    isOpenSource?: boolean | null,
    isPublic?: boolean | null,
    modelName?: string | null,
    modelsID: string,
    presetName?: string | null,
    taskName?: string | null,
    updatedAt: string,
    usage?: number | null,
    userID: string,
    username?: string | null,
  } | null,
};

export type BatchGetUsersQueryVariables = {
  ids: Array< string | null >,
};

export type BatchGetUsersQuery = {
  batchGetUsers?:  Array< {
    MLTaskExecutionResults?:  {
      items:  Array< {
        ANGRYReactionStats?: number | null,
        CUTEReactionStats?: number | null,
        FUNNYReactionStats?: number | null,
        GROSSReactionStats?: number | null,
        HEARTReactionStats?: number | null,
        POOPReactionStats?: number | null,
        SADReactionStats?: number | null,
        SCARYReactionStats?: number | null,
        WOWReactionStats?: number | null,
        createdAt: string,
        creditsUsed?: number | null,
        id: string,
        mltasksworkspaceID: string,
        modelName?: string | null,
        modelsID: string,
        privacyLevel?: TaskPrivacyLevels | null,
        status?: TaskResultStatus | null,
        taskName?: string | null,
        updatedAt: string,
        userID: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    MLTasksWorkspaces?:  {
      items:  Array< {
        createdAt: string,
        description?: string | null,
        id: string,
        name?: string | null,
        updatedAt: string,
        userID: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    ModelInputPresets?:  {
      items:  Array< {
        cost?: number | null,
        createdAt: string,
        description?: string | null,
        id: string,
        isOpenSource?: boolean | null,
        isPublic?: boolean | null,
        modelName?: string | null,
        modelsID: string,
        presetName?: string | null,
        taskName?: string | null,
        updatedAt: string,
        usage?: number | null,
        userID: string,
        username?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    SocialManPosts?:  {
      items:  Array< {
        createdAt: string,
        description?: string | null,
        enabledFacebook?: boolean | null,
        enabledInstagram?: boolean | null,
        enabledLinkedin?: boolean | null,
        enabledPinterest?: boolean | null,
        enabledTiktok?: boolean | null,
        enabledTwitter?: boolean | null,
        enabledYoutube?: boolean | null,
        id: string,
        postedTime?: number | null,
        postedToFacebook?: boolean | null,
        postedToInstagram?: boolean | null,
        postedToLinkedin?: boolean | null,
        postedToPinterest?: boolean | null,
        postedToTiktok?: boolean | null,
        postedToTwitter?: boolean | null,
        postedToYoutube?: boolean | null,
        s3Key?: string | null,
        s3KeyResized?: string | null,
        scheduledPostTime?: number | null,
        status: SocialManPostStatus,
        title?: string | null,
        updatedAt: string,
        userID: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    completedTasksCount?: number | null,
    createdAt: string,
    createdPresetCount?: number | null,
    credits?: number | null,
    currentSubscriptionPlan?: SubscriptionPlan | null,
    email?: string | null,
    facebook?:  {
      instagramsData:  Array< {
        accessToken?: string | null,
        biography?: string | null,
        connectedPageID?: string | null,
        expiresOn?: number | null,
        followersCount?: number | null,
        followsCount?: number | null,
        id?: string | null,
        igID?: string | null,
        mediaCount?: number | null,
        name?: string | null,
        profilePictureURL?: string | null,
        username?: string | null,
      } | null >,
      pagesData:  Array< {
        accessToken?: string | null,
        category?: string | null,
        expiresOn?: number | null,
        id?: string | null,
        name?: string | null,
        picture?: string | null,
      } | null >,
      userData:  {
        accessToken?: string | null,
        expiresOn?: number | null,
        id?: string | null,
        name?: string | null,
        picture?: string | null,
      },
    } | null,
    google?:  {
      channelsData:  Array< {
        customUrl?: string | null,
        description?: string | null,
        id?: string | null,
        thumbnail?: string | null,
        title?: string | null,
      } | null >,
      tokenData:  {
        accessToken?: string | null,
        expiresOn?: number | null,
        idToken?: string | null,
        refreshToken?: string | null,
        scope?: string | null,
        tokenType?: string | null,
      },
      userData:  {
        email?: string | null,
        familyName?: string | null,
        givenName?: string | null,
        id?: string | null,
        locale?: string | null,
        name?: string | null,
        picture?: string | null,
        verifiedEmail?: boolean | null,
      },
    } | null,
    id: string,
    linkedin?:  {
      tokenData:  {
        accessToken: string,
        accessTokenExpirationDate: number,
        scope: string,
      },
      userData:  {
        email?: string | null,
        name?: string | null,
        picture?: string | null,
        sub?: string | null,
      },
    } | null,
    name?: string | null,
    periodEndOfLastSubscriptionCredits?: number | null,
    picture?: string | null,
    pinterest?:  {
      boardsData?:  Array< {
        createdAt?: string | null,
        description?: string | null,
        followerCount?: number | null,
        id?: string | null,
        imageCoverUrl?: string | null,
        name?: string | null,
        pinCount?: number | null,
        privacy?: string | null,
      } | null > | null,
      tokenData:  {
        accessToken: string,
        accessTokenExpirationDate: number,
        refreshToken: string,
        refreshTokenExpirationDate: number,
        scope: string,
      },
      userData:  {
        about?: string | null,
        accountType?: string | null,
        boardCount?: number | null,
        businessName?: string | null,
        followerCount?: number | null,
        followingCount?: number | null,
        id?: string | null,
        monthlyViews?: number | null,
        pinCount?: number | null,
        profileImage?: string | null,
        username?: string | null,
        websiteUrl?: string | null,
      },
    } | null,
    stripeConnectAccountID?: string | null,
    stripeConnectAccountSetupComplete?: boolean | null,
    stripeCustomerID?: string | null,
    tiktok?:  {
      tokenData:  {
        accessToken: string,
        accessTokenExpirationDate: number,
        refreshToken: string,
        refreshTokenExpirationDate: number,
        scope: string,
      },
      userData:  {
        avatarLargeURL?: string | null,
        avatarURL?: string | null,
        avatarURL100?: string | null,
        bioDescription?: string | null,
        commentDisabled?: boolean | null,
        displayName?: string | null,
        duetDisabled?: boolean | null,
        followerCount?: number | null,
        followingCount?: number | null,
        isVerified?: boolean | null,
        likesCount?: number | null,
        maxVideoPostDuration_sec?: number | null,
        privacyLevelOptions?: Array< string | null > | null,
        profileDeepLink?: string | null,
        stitchDisabled?: boolean | null,
        username?: string | null,
        videoCount?: number | null,
      },
    } | null,
    twitter?:  {
      tokenData:  {
        accessToken?: string | null,
        accessTokenSecret?: string | null,
        codeVerifier?: string | null,
        expiresOn?: number | null,
        oauthTokenSecret?: string | null,
        refreshToken?: string | null,
      },
      userData:  {
        description?: string | null,
        id?: string | null,
        name?: string | null,
        profilePictureURL?: string | null,
        username?: string | null,
        verifiedType?: TwitterVerifiedType | null,
      },
    } | null,
    updatedAt: string,
    username?: string | null,
  } | null > | null,
};

export type GetMLTaskDataQueryVariables = {
  id: string,
};

export type GetMLTaskDataQuery = {
  getMLTaskData?:  {
    Models?:  {
      items:  Array< {
        createdAt: string,
        id: string,
        isPublic: boolean,
        mltaskdataID: string,
        name?: string | null,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    category?: string | null,
    createdAt: string,
    id: string,
    isPublic: boolean,
    name?: string | null,
    updatedAt: string,
  } | null,
};

export type GetMLTaskExecutionResultQueryVariables = {
  id: string,
};

export type GetMLTaskExecutionResultQuery = {
  getMLTaskExecutionResult?:  {
    ANGRYReactionStats?: number | null,
    CUTEReactionStats?: number | null,
    FUNNYReactionStats?: number | null,
    GROSSReactionStats?: number | null,
    HEARTReactionStats?: number | null,
    POOPReactionStats?: number | null,
    Reactions?:  {
      items:  Array< {
        createdAt: string,
        mltaskexecutionresultID: string,
        type: TaskReactionType,
        updatedAt: string,
        userID: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    SADReactionStats?: number | null,
    SCARYReactionStats?: number | null,
    WOWReactionStats?: number | null,
    createdAt: string,
    creditsUsed?: number | null,
    id: string,
    inputs?:  Array< {
      metaData?:  Array< {
        key?: string | null,
        value?: string | null,
      } | null > | null,
      name?: string | null,
      type?: ModelInputOutputEntryType | null,
      value?: string | null,
    } | null > | null,
    mltasksworkspaceID: string,
    modelName?: string | null,
    modelsID: string,
    output?:  {
      entries?:  Array< {
        name?: string | null,
        type?: ModelInputOutputEntryType | null,
        value?: string | null,
      } | null > | null,
      message?: string | null,
    } | null,
    presetUserInput?:  {
      presetCreatorName?: string | null,
      presetCreatorUID?: string | null,
      presetID?: string | null,
      presetName?: string | null,
      presetValues?:  Array< {
        modelInputName?: string | null,
      } | null > | null,
    } | null,
    privacyLevel?: TaskPrivacyLevels | null,
    status?: TaskResultStatus | null,
    taskName?: string | null,
    updatedAt: string,
    userID: string,
  } | null,
};

export type GetMLTasksWorkspaceQueryVariables = {
  id: string,
};

export type GetMLTasksWorkspaceQuery = {
  getMLTasksWorkspace?:  {
    MLTaskExecutionResults?:  {
      items:  Array< {
        ANGRYReactionStats?: number | null,
        CUTEReactionStats?: number | null,
        FUNNYReactionStats?: number | null,
        GROSSReactionStats?: number | null,
        HEARTReactionStats?: number | null,
        POOPReactionStats?: number | null,
        SADReactionStats?: number | null,
        SCARYReactionStats?: number | null,
        WOWReactionStats?: number | null,
        createdAt: string,
        creditsUsed?: number | null,
        id: string,
        mltasksworkspaceID: string,
        modelName?: string | null,
        modelsID: string,
        privacyLevel?: TaskPrivacyLevels | null,
        status?: TaskResultStatus | null,
        taskName?: string | null,
        updatedAt: string,
        userID: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    description?: string | null,
    id: string,
    name?: string | null,
    updatedAt: string,
    userID: string,
  } | null,
};

export type GetModelInputPresetQueryVariables = {
  id: string,
};

export type GetModelInputPresetQuery = {
  getModelInputPreset?:  {
    cost?: number | null,
    createdAt: string,
    description?: string | null,
    entries?:  Array< {
      modelInputName?: string | null,
      modelInputType?: ModelInputOutputEntryType | null,
      presetEntryDatas?:  Array< {
        description?: string | null,
        entryType?: InputPresetEntryType | null,
        parameterName?: string | null,
        presetOrder?: number | null,
        val?: string | null,
      } | null > | null,
    } | null > | null,
    id: string,
    isOpenSource?: boolean | null,
    isPublic?: boolean | null,
    modelName?: string | null,
    modelsID: string,
    presetName?: string | null,
    taskName?: string | null,
    updatedAt: string,
    usage?: number | null,
    userID: string,
    username?: string | null,
  } | null,
};

export type GetModelsQueryVariables = {
  id: string,
};

export type GetModelsQuery = {
  getModels?:  {
    MLTaskExecutionResults?:  {
      items:  Array< {
        ANGRYReactionStats?: number | null,
        CUTEReactionStats?: number | null,
        FUNNYReactionStats?: number | null,
        GROSSReactionStats?: number | null,
        HEARTReactionStats?: number | null,
        POOPReactionStats?: number | null,
        SADReactionStats?: number | null,
        SCARYReactionStats?: number | null,
        WOWReactionStats?: number | null,
        createdAt: string,
        creditsUsed?: number | null,
        id: string,
        mltasksworkspaceID: string,
        modelName?: string | null,
        modelsID: string,
        privacyLevel?: TaskPrivacyLevels | null,
        status?: TaskResultStatus | null,
        taskName?: string | null,
        updatedAt: string,
        userID: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    ModelInputPresets?:  {
      items:  Array< {
        cost?: number | null,
        createdAt: string,
        description?: string | null,
        id: string,
        isOpenSource?: boolean | null,
        isPublic?: boolean | null,
        modelName?: string | null,
        modelsID: string,
        presetName?: string | null,
        taskName?: string | null,
        updatedAt: string,
        usage?: number | null,
        userID: string,
        username?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    id: string,
    inputs?:  {
      optional?:  Array< {
        defaultValue?: string | null,
        description?: string | null,
        examples?: Array< string | null > | null,
        name: string,
        optionStep?: number | null,
        optionType?: OptionType | null,
        type: ModelInputOutputEntryType,
      } | null > | null,
      required?:  Array< {
        defaultValue?: string | null,
        description?: string | null,
        examples?: Array< string | null > | null,
        name: string,
        optionStep?: number | null,
        optionType?: OptionType | null,
        type: ModelInputOutputEntryType,
      } | null > | null,
    } | null,
    isPublic: boolean,
    mltaskdataID: string,
    name?: string | null,
    outputs?:  {
      entries?:  Array< {
        name?: string | null,
        type?: ModelInputOutputEntryType | null,
      } | null > | null,
    } | null,
    updatedAt: string,
  } | null,
};

export type GetSocialManPostQueryVariables = {
  id: string,
};

export type GetSocialManPostQuery = {
  getSocialManPost?:  {
    createdAt: string,
    description?: string | null,
    enabledFacebook?: boolean | null,
    enabledInstagram?: boolean | null,
    enabledLinkedin?: boolean | null,
    enabledPinterest?: boolean | null,
    enabledTiktok?: boolean | null,
    enabledTwitter?: boolean | null,
    enabledYoutube?: boolean | null,
    facebook?:  {
      caption: string,
      postID?: string | null,
      postToStory?: boolean | null,
      storyID?: string | null,
      storyURL?: string | null,
      targetPageID?: string | null,
      thumbnail?: string | null,
    } | null,
    id: string,
    instagram?:  {
      caption: string,
      postID?: string | null,
      postToStory?: boolean | null,
      storyID?: string | null,
      targetAccountID?: string | null,
      thumbnail?: string | null,
    } | null,
    linkedin?:  {
      caption?: string | null,
      mediaDescription?: string | null,
      postID?: string | null,
    } | null,
    pinterest?:  {
      boardID?: string | null,
      description?: string | null,
      link?: string | null,
      pinID?: string | null,
      thumbnailURL?: string | null,
      title?: string | null,
    } | null,
    postedTime?: number | null,
    postedToFacebook?: boolean | null,
    postedToInstagram?: boolean | null,
    postedToLinkedin?: boolean | null,
    postedToPinterest?: boolean | null,
    postedToTiktok?: boolean | null,
    postedToTwitter?: boolean | null,
    postedToYoutube?: boolean | null,
    s3Key?: string | null,
    s3KeyResized?: string | null,
    scheduledPostTime?: number | null,
    status: SocialManPostStatus,
    tiktok?:  {
      caption?: string | null,
      contentDisclosureBrandedContent?: boolean | null,
      contentDisclosureEnabled?: boolean | null,
      contentDisclosureYourBrand?: boolean | null,
      postID?: string | null,
      privacy?: SocialManTiktokPrivacyStatus | null,
      title?: string | null,
      usersCanComment?: boolean | null,
      usersCanDuet?: boolean | null,
      usersCanStitch?: boolean | null,
      videoCoverTimestampMs?: number | null,
    } | null,
    title?: string | null,
    twitter?:  {
      caption: string,
      tweetID?: string | null,
    } | null,
    updatedAt: string,
    userID: string,
    youtube?:  {
      category?: string | null,
      description?: string | null,
      privacy?: string | null,
      tags?: string | null,
      targetChannelID?: string | null,
      thumbnailURL?: string | null,
      title?: string | null,
      videoID?: string | null,
    } | null,
  } | null,
};

export type GetTaskReactionQueryVariables = {
  userID: string,
};

export type GetTaskReactionQuery = {
  getTaskReaction?:  {
    createdAt: string,
    mltaskexecutionresultID: string,
    type: TaskReactionType,
    updatedAt: string,
    userID: string,
  } | null,
};

export type GetUserQueryVariables = {
  id: string,
};

export type GetUserQuery = {
  getUser?:  {
    MLTaskExecutionResults?:  {
      items:  Array< {
        ANGRYReactionStats?: number | null,
        CUTEReactionStats?: number | null,
        FUNNYReactionStats?: number | null,
        GROSSReactionStats?: number | null,
        HEARTReactionStats?: number | null,
        POOPReactionStats?: number | null,
        SADReactionStats?: number | null,
        SCARYReactionStats?: number | null,
        WOWReactionStats?: number | null,
        createdAt: string,
        creditsUsed?: number | null,
        id: string,
        mltasksworkspaceID: string,
        modelName?: string | null,
        modelsID: string,
        privacyLevel?: TaskPrivacyLevels | null,
        status?: TaskResultStatus | null,
        taskName?: string | null,
        updatedAt: string,
        userID: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    MLTasksWorkspaces?:  {
      items:  Array< {
        createdAt: string,
        description?: string | null,
        id: string,
        name?: string | null,
        updatedAt: string,
        userID: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    ModelInputPresets?:  {
      items:  Array< {
        cost?: number | null,
        createdAt: string,
        description?: string | null,
        id: string,
        isOpenSource?: boolean | null,
        isPublic?: boolean | null,
        modelName?: string | null,
        modelsID: string,
        presetName?: string | null,
        taskName?: string | null,
        updatedAt: string,
        usage?: number | null,
        userID: string,
        username?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    SocialManPosts?:  {
      items:  Array< {
        createdAt: string,
        description?: string | null,
        enabledFacebook?: boolean | null,
        enabledInstagram?: boolean | null,
        enabledLinkedin?: boolean | null,
        enabledPinterest?: boolean | null,
        enabledTiktok?: boolean | null,
        enabledTwitter?: boolean | null,
        enabledYoutube?: boolean | null,
        id: string,
        postedTime?: number | null,
        postedToFacebook?: boolean | null,
        postedToInstagram?: boolean | null,
        postedToLinkedin?: boolean | null,
        postedToPinterest?: boolean | null,
        postedToTiktok?: boolean | null,
        postedToTwitter?: boolean | null,
        postedToYoutube?: boolean | null,
        s3Key?: string | null,
        s3KeyResized?: string | null,
        scheduledPostTime?: number | null,
        status: SocialManPostStatus,
        title?: string | null,
        updatedAt: string,
        userID: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    completedTasksCount?: number | null,
    createdAt: string,
    createdPresetCount?: number | null,
    credits?: number | null,
    currentSubscriptionPlan?: SubscriptionPlan | null,
    email?: string | null,
    facebook?:  {
      instagramsData:  Array< {
        accessToken?: string | null,
        biography?: string | null,
        connectedPageID?: string | null,
        expiresOn?: number | null,
        followersCount?: number | null,
        followsCount?: number | null,
        id?: string | null,
        igID?: string | null,
        mediaCount?: number | null,
        name?: string | null,
        profilePictureURL?: string | null,
        username?: string | null,
      } | null >,
      pagesData:  Array< {
        accessToken?: string | null,
        category?: string | null,
        expiresOn?: number | null,
        id?: string | null,
        name?: string | null,
        picture?: string | null,
      } | null >,
      userData:  {
        accessToken?: string | null,
        expiresOn?: number | null,
        id?: string | null,
        name?: string | null,
        picture?: string | null,
      },
    } | null,
    google?:  {
      channelsData:  Array< {
        customUrl?: string | null,
        description?: string | null,
        id?: string | null,
        thumbnail?: string | null,
        title?: string | null,
      } | null >,
      tokenData:  {
        accessToken?: string | null,
        expiresOn?: number | null,
        idToken?: string | null,
        refreshToken?: string | null,
        scope?: string | null,
        tokenType?: string | null,
      },
      userData:  {
        email?: string | null,
        familyName?: string | null,
        givenName?: string | null,
        id?: string | null,
        locale?: string | null,
        name?: string | null,
        picture?: string | null,
        verifiedEmail?: boolean | null,
      },
    } | null,
    id: string,
    linkedin?:  {
      tokenData:  {
        accessToken: string,
        accessTokenExpirationDate: number,
        scope: string,
      },
      userData:  {
        email?: string | null,
        name?: string | null,
        picture?: string | null,
        sub?: string | null,
      },
    } | null,
    name?: string | null,
    periodEndOfLastSubscriptionCredits?: number | null,
    picture?: string | null,
    pinterest?:  {
      boardsData?:  Array< {
        createdAt?: string | null,
        description?: string | null,
        followerCount?: number | null,
        id?: string | null,
        imageCoverUrl?: string | null,
        name?: string | null,
        pinCount?: number | null,
        privacy?: string | null,
      } | null > | null,
      tokenData:  {
        accessToken: string,
        accessTokenExpirationDate: number,
        refreshToken: string,
        refreshTokenExpirationDate: number,
        scope: string,
      },
      userData:  {
        about?: string | null,
        accountType?: string | null,
        boardCount?: number | null,
        businessName?: string | null,
        followerCount?: number | null,
        followingCount?: number | null,
        id?: string | null,
        monthlyViews?: number | null,
        pinCount?: number | null,
        profileImage?: string | null,
        username?: string | null,
        websiteUrl?: string | null,
      },
    } | null,
    stripeConnectAccountID?: string | null,
    stripeConnectAccountSetupComplete?: boolean | null,
    stripeCustomerID?: string | null,
    tiktok?:  {
      tokenData:  {
        accessToken: string,
        accessTokenExpirationDate: number,
        refreshToken: string,
        refreshTokenExpirationDate: number,
        scope: string,
      },
      userData:  {
        avatarLargeURL?: string | null,
        avatarURL?: string | null,
        avatarURL100?: string | null,
        bioDescription?: string | null,
        commentDisabled?: boolean | null,
        displayName?: string | null,
        duetDisabled?: boolean | null,
        followerCount?: number | null,
        followingCount?: number | null,
        isVerified?: boolean | null,
        likesCount?: number | null,
        maxVideoPostDuration_sec?: number | null,
        privacyLevelOptions?: Array< string | null > | null,
        profileDeepLink?: string | null,
        stitchDisabled?: boolean | null,
        username?: string | null,
        videoCount?: number | null,
      },
    } | null,
    twitter?:  {
      tokenData:  {
        accessToken?: string | null,
        accessTokenSecret?: string | null,
        codeVerifier?: string | null,
        expiresOn?: number | null,
        oauthTokenSecret?: string | null,
        refreshToken?: string | null,
      },
      userData:  {
        description?: string | null,
        id?: string | null,
        name?: string | null,
        profilePictureURL?: string | null,
        username?: string | null,
        verifiedType?: TwitterVerifiedType | null,
      },
    } | null,
    updatedAt: string,
    username?: string | null,
  } | null,
};

export type GetUserFromUsernameQueryVariables = {
  username: string,
};

export type GetUserFromUsernameQuery = {
  getUserFromUsername?:  {
    MLTaskExecutionResults?:  {
      items:  Array< {
        ANGRYReactionStats?: number | null,
        CUTEReactionStats?: number | null,
        FUNNYReactionStats?: number | null,
        GROSSReactionStats?: number | null,
        HEARTReactionStats?: number | null,
        POOPReactionStats?: number | null,
        SADReactionStats?: number | null,
        SCARYReactionStats?: number | null,
        WOWReactionStats?: number | null,
        createdAt: string,
        creditsUsed?: number | null,
        id: string,
        mltasksworkspaceID: string,
        modelName?: string | null,
        modelsID: string,
        privacyLevel?: TaskPrivacyLevels | null,
        status?: TaskResultStatus | null,
        taskName?: string | null,
        updatedAt: string,
        userID: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    MLTasksWorkspaces?:  {
      items:  Array< {
        createdAt: string,
        description?: string | null,
        id: string,
        name?: string | null,
        updatedAt: string,
        userID: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    ModelInputPresets?:  {
      items:  Array< {
        cost?: number | null,
        createdAt: string,
        description?: string | null,
        id: string,
        isOpenSource?: boolean | null,
        isPublic?: boolean | null,
        modelName?: string | null,
        modelsID: string,
        presetName?: string | null,
        taskName?: string | null,
        updatedAt: string,
        usage?: number | null,
        userID: string,
        username?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    SocialManPosts?:  {
      items:  Array< {
        createdAt: string,
        description?: string | null,
        enabledFacebook?: boolean | null,
        enabledInstagram?: boolean | null,
        enabledLinkedin?: boolean | null,
        enabledPinterest?: boolean | null,
        enabledTiktok?: boolean | null,
        enabledTwitter?: boolean | null,
        enabledYoutube?: boolean | null,
        id: string,
        postedTime?: number | null,
        postedToFacebook?: boolean | null,
        postedToInstagram?: boolean | null,
        postedToLinkedin?: boolean | null,
        postedToPinterest?: boolean | null,
        postedToTiktok?: boolean | null,
        postedToTwitter?: boolean | null,
        postedToYoutube?: boolean | null,
        s3Key?: string | null,
        s3KeyResized?: string | null,
        scheduledPostTime?: number | null,
        status: SocialManPostStatus,
        title?: string | null,
        updatedAt: string,
        userID: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    completedTasksCount?: number | null,
    createdAt: string,
    createdPresetCount?: number | null,
    credits?: number | null,
    currentSubscriptionPlan?: SubscriptionPlan | null,
    email?: string | null,
    facebook?:  {
      instagramsData:  Array< {
        accessToken?: string | null,
        biography?: string | null,
        connectedPageID?: string | null,
        expiresOn?: number | null,
        followersCount?: number | null,
        followsCount?: number | null,
        id?: string | null,
        igID?: string | null,
        mediaCount?: number | null,
        name?: string | null,
        profilePictureURL?: string | null,
        username?: string | null,
      } | null >,
      pagesData:  Array< {
        accessToken?: string | null,
        category?: string | null,
        expiresOn?: number | null,
        id?: string | null,
        name?: string | null,
        picture?: string | null,
      } | null >,
      userData:  {
        accessToken?: string | null,
        expiresOn?: number | null,
        id?: string | null,
        name?: string | null,
        picture?: string | null,
      },
    } | null,
    google?:  {
      channelsData:  Array< {
        customUrl?: string | null,
        description?: string | null,
        id?: string | null,
        thumbnail?: string | null,
        title?: string | null,
      } | null >,
      tokenData:  {
        accessToken?: string | null,
        expiresOn?: number | null,
        idToken?: string | null,
        refreshToken?: string | null,
        scope?: string | null,
        tokenType?: string | null,
      },
      userData:  {
        email?: string | null,
        familyName?: string | null,
        givenName?: string | null,
        id?: string | null,
        locale?: string | null,
        name?: string | null,
        picture?: string | null,
        verifiedEmail?: boolean | null,
      },
    } | null,
    id: string,
    linkedin?:  {
      tokenData:  {
        accessToken: string,
        accessTokenExpirationDate: number,
        scope: string,
      },
      userData:  {
        email?: string | null,
        name?: string | null,
        picture?: string | null,
        sub?: string | null,
      },
    } | null,
    name?: string | null,
    periodEndOfLastSubscriptionCredits?: number | null,
    picture?: string | null,
    pinterest?:  {
      boardsData?:  Array< {
        createdAt?: string | null,
        description?: string | null,
        followerCount?: number | null,
        id?: string | null,
        imageCoverUrl?: string | null,
        name?: string | null,
        pinCount?: number | null,
        privacy?: string | null,
      } | null > | null,
      tokenData:  {
        accessToken: string,
        accessTokenExpirationDate: number,
        refreshToken: string,
        refreshTokenExpirationDate: number,
        scope: string,
      },
      userData:  {
        about?: string | null,
        accountType?: string | null,
        boardCount?: number | null,
        businessName?: string | null,
        followerCount?: number | null,
        followingCount?: number | null,
        id?: string | null,
        monthlyViews?: number | null,
        pinCount?: number | null,
        profileImage?: string | null,
        username?: string | null,
        websiteUrl?: string | null,
      },
    } | null,
    stripeConnectAccountID?: string | null,
    stripeConnectAccountSetupComplete?: boolean | null,
    stripeCustomerID?: string | null,
    tiktok?:  {
      tokenData:  {
        accessToken: string,
        accessTokenExpirationDate: number,
        refreshToken: string,
        refreshTokenExpirationDate: number,
        scope: string,
      },
      userData:  {
        avatarLargeURL?: string | null,
        avatarURL?: string | null,
        avatarURL100?: string | null,
        bioDescription?: string | null,
        commentDisabled?: boolean | null,
        displayName?: string | null,
        duetDisabled?: boolean | null,
        followerCount?: number | null,
        followingCount?: number | null,
        isVerified?: boolean | null,
        likesCount?: number | null,
        maxVideoPostDuration_sec?: number | null,
        privacyLevelOptions?: Array< string | null > | null,
        profileDeepLink?: string | null,
        stitchDisabled?: boolean | null,
        username?: string | null,
        videoCount?: number | null,
      },
    } | null,
    twitter?:  {
      tokenData:  {
        accessToken?: string | null,
        accessTokenSecret?: string | null,
        codeVerifier?: string | null,
        expiresOn?: number | null,
        oauthTokenSecret?: string | null,
        refreshToken?: string | null,
      },
      userData:  {
        description?: string | null,
        id?: string | null,
        name?: string | null,
        profilePictureURL?: string | null,
        username?: string | null,
        verifiedType?: TwitterVerifiedType | null,
      },
    } | null,
    updatedAt: string,
    username?: string | null,
  } | null,
};

export type ListMLTaskDataQueryVariables = {
  filter?: ModelMLTaskDataFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListMLTaskDataQuery = {
  listMLTaskData?:  {
    items:  Array< {
      Models?:  {
        nextToken?: string | null,
      } | null,
      category?: string | null,
      createdAt: string,
      id: string,
      isPublic: boolean,
      name?: string | null,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ListMLTaskExecutionResultsQueryVariables = {
  filter?: ModelMLTaskExecutionResultFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListMLTaskExecutionResultsQuery = {
  listMLTaskExecutionResults?:  {
    items:  Array< {
      ANGRYReactionStats?: number | null,
      CUTEReactionStats?: number | null,
      FUNNYReactionStats?: number | null,
      GROSSReactionStats?: number | null,
      HEARTReactionStats?: number | null,
      POOPReactionStats?: number | null,
      Reactions?:  {
        nextToken?: string | null,
      } | null,
      SADReactionStats?: number | null,
      SCARYReactionStats?: number | null,
      WOWReactionStats?: number | null,
      createdAt: string,
      creditsUsed?: number | null,
      id: string,
      inputs?:  Array< {
        name?: string | null,
        type?: ModelInputOutputEntryType | null,
        value?: string | null,
      } | null > | null,
      mltasksworkspaceID: string,
      modelName?: string | null,
      modelsID: string,
      output?:  {
        message?: string | null,
      } | null,
      presetUserInput?:  {
        presetCreatorName?: string | null,
        presetCreatorUID?: string | null,
        presetID?: string | null,
        presetName?: string | null,
      } | null,
      privacyLevel?: TaskPrivacyLevels | null,
      status?: TaskResultStatus | null,
      taskName?: string | null,
      updatedAt: string,
      userID: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ListMLTaskExecutionResultsByCreatedAtQueryVariables = {
  createdAt?: ModelStringKeyConditionInput | null,
  filter?: ModelMLTaskExecutionResultFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
  userID: string,
};

export type ListMLTaskExecutionResultsByCreatedAtQuery = {
  listMLTaskExecutionResultsByCreatedAt?:  {
    items:  Array< {
      ANGRYReactionStats?: number | null,
      CUTEReactionStats?: number | null,
      FUNNYReactionStats?: number | null,
      GROSSReactionStats?: number | null,
      HEARTReactionStats?: number | null,
      POOPReactionStats?: number | null,
      Reactions?:  {
        nextToken?: string | null,
      } | null,
      SADReactionStats?: number | null,
      SCARYReactionStats?: number | null,
      WOWReactionStats?: number | null,
      createdAt: string,
      creditsUsed?: number | null,
      id: string,
      inputs?:  Array< {
        name?: string | null,
        type?: ModelInputOutputEntryType | null,
        value?: string | null,
      } | null > | null,
      mltasksworkspaceID: string,
      modelName?: string | null,
      modelsID: string,
      output?:  {
        message?: string | null,
      } | null,
      presetUserInput?:  {
        presetCreatorName?: string | null,
        presetCreatorUID?: string | null,
        presetID?: string | null,
        presetName?: string | null,
      } | null,
      privacyLevel?: TaskPrivacyLevels | null,
      status?: TaskResultStatus | null,
      taskName?: string | null,
      updatedAt: string,
      userID: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ListMLTasksWorkspaceByCreatedAtQueryVariables = {
  createdAt?: ModelStringKeyConditionInput | null,
  filter?: ModelMLTasksWorkspaceFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
  userID: string,
};

export type ListMLTasksWorkspaceByCreatedAtQuery = {
  listMLTasksWorkspaceByCreatedAt?:  {
    items:  Array< {
      MLTaskExecutionResults?:  {
        nextToken?: string | null,
      } | null,
      createdAt: string,
      description?: string | null,
      id: string,
      name?: string | null,
      updatedAt: string,
      userID: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ListMLTasksWorkspacesQueryVariables = {
  filter?: ModelMLTasksWorkspaceFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListMLTasksWorkspacesQuery = {
  listMLTasksWorkspaces?:  {
    items:  Array< {
      MLTaskExecutionResults?:  {
        nextToken?: string | null,
      } | null,
      createdAt: string,
      description?: string | null,
      id: string,
      name?: string | null,
      updatedAt: string,
      userID: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ListModelInputPresetsQueryVariables = {
  filter?: ModelModelInputPresetFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListModelInputPresetsQuery = {
  listModelInputPresets?:  {
    items:  Array< {
      cost?: number | null,
      createdAt: string,
      description?: string | null,
      entries?:  Array< {
        modelInputName?: string | null,
        modelInputType?: ModelInputOutputEntryType | null,
      } | null > | null,
      id: string,
      isOpenSource?: boolean | null,
      isPublic?: boolean | null,
      modelName?: string | null,
      modelsID: string,
      presetName?: string | null,
      taskName?: string | null,
      updatedAt: string,
      usage?: number | null,
      userID: string,
      username?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ListModelsQueryVariables = {
  filter?: ModelModelsFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListModelsQuery = {
  listModels?:  {
    items:  Array< {
      MLTaskExecutionResults?:  {
        nextToken?: string | null,
      } | null,
      ModelInputPresets?:  {
        nextToken?: string | null,
      } | null,
      createdAt: string,
      id: string,
      isPublic: boolean,
      mltaskdataID: string,
      name?: string | null,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ListSocialManPostsQueryVariables = {
  filter?: ModelSocialManPostFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListSocialManPostsQuery = {
  listSocialManPosts?:  {
    items:  Array< {
      createdAt: string,
      description?: string | null,
      enabledFacebook?: boolean | null,
      enabledInstagram?: boolean | null,
      enabledLinkedin?: boolean | null,
      enabledPinterest?: boolean | null,
      enabledTiktok?: boolean | null,
      enabledTwitter?: boolean | null,
      enabledYoutube?: boolean | null,
      facebook?:  {
        caption: string,
        postID?: string | null,
        postToStory?: boolean | null,
        storyID?: string | null,
        storyURL?: string | null,
        targetPageID?: string | null,
        thumbnail?: string | null,
      } | null,
      id: string,
      instagram?:  {
        caption: string,
        postID?: string | null,
        postToStory?: boolean | null,
        storyID?: string | null,
        targetAccountID?: string | null,
        thumbnail?: string | null,
      } | null,
      linkedin?:  {
        caption?: string | null,
        mediaDescription?: string | null,
        postID?: string | null,
      } | null,
      pinterest?:  {
        boardID?: string | null,
        description?: string | null,
        link?: string | null,
        pinID?: string | null,
        thumbnailURL?: string | null,
        title?: string | null,
      } | null,
      postedTime?: number | null,
      postedToFacebook?: boolean | null,
      postedToInstagram?: boolean | null,
      postedToLinkedin?: boolean | null,
      postedToPinterest?: boolean | null,
      postedToTiktok?: boolean | null,
      postedToTwitter?: boolean | null,
      postedToYoutube?: boolean | null,
      s3Key?: string | null,
      s3KeyResized?: string | null,
      scheduledPostTime?: number | null,
      status: SocialManPostStatus,
      tiktok?:  {
        caption?: string | null,
        contentDisclosureBrandedContent?: boolean | null,
        contentDisclosureEnabled?: boolean | null,
        contentDisclosureYourBrand?: boolean | null,
        postID?: string | null,
        privacy?: SocialManTiktokPrivacyStatus | null,
        title?: string | null,
        usersCanComment?: boolean | null,
        usersCanDuet?: boolean | null,
        usersCanStitch?: boolean | null,
        videoCoverTimestampMs?: number | null,
      } | null,
      title?: string | null,
      twitter?:  {
        caption: string,
        tweetID?: string | null,
      } | null,
      updatedAt: string,
      userID: string,
      youtube?:  {
        category?: string | null,
        description?: string | null,
        privacy?: string | null,
        tags?: string | null,
        targetChannelID?: string | null,
        thumbnailURL?: string | null,
        title?: string | null,
        videoID?: string | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ListSocialManPostsByCreatedAtQueryVariables = {
  createdAt?: ModelStringKeyConditionInput | null,
  filter?: ModelSocialManPostFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
  userID: string,
};

export type ListSocialManPostsByCreatedAtQuery = {
  listSocialManPostsByCreatedAt?:  {
    items:  Array< {
      createdAt: string,
      description?: string | null,
      enabledFacebook?: boolean | null,
      enabledInstagram?: boolean | null,
      enabledLinkedin?: boolean | null,
      enabledPinterest?: boolean | null,
      enabledTiktok?: boolean | null,
      enabledTwitter?: boolean | null,
      enabledYoutube?: boolean | null,
      facebook?:  {
        caption: string,
        postID?: string | null,
        postToStory?: boolean | null,
        storyID?: string | null,
        storyURL?: string | null,
        targetPageID?: string | null,
        thumbnail?: string | null,
      } | null,
      id: string,
      instagram?:  {
        caption: string,
        postID?: string | null,
        postToStory?: boolean | null,
        storyID?: string | null,
        targetAccountID?: string | null,
        thumbnail?: string | null,
      } | null,
      linkedin?:  {
        caption?: string | null,
        mediaDescription?: string | null,
        postID?: string | null,
      } | null,
      pinterest?:  {
        boardID?: string | null,
        description?: string | null,
        link?: string | null,
        pinID?: string | null,
        thumbnailURL?: string | null,
        title?: string | null,
      } | null,
      postedTime?: number | null,
      postedToFacebook?: boolean | null,
      postedToInstagram?: boolean | null,
      postedToLinkedin?: boolean | null,
      postedToPinterest?: boolean | null,
      postedToTiktok?: boolean | null,
      postedToTwitter?: boolean | null,
      postedToYoutube?: boolean | null,
      s3Key?: string | null,
      s3KeyResized?: string | null,
      scheduledPostTime?: number | null,
      status: SocialManPostStatus,
      tiktok?:  {
        caption?: string | null,
        contentDisclosureBrandedContent?: boolean | null,
        contentDisclosureEnabled?: boolean | null,
        contentDisclosureYourBrand?: boolean | null,
        postID?: string | null,
        privacy?: SocialManTiktokPrivacyStatus | null,
        title?: string | null,
        usersCanComment?: boolean | null,
        usersCanDuet?: boolean | null,
        usersCanStitch?: boolean | null,
        videoCoverTimestampMs?: number | null,
      } | null,
      title?: string | null,
      twitter?:  {
        caption: string,
        tweetID?: string | null,
      } | null,
      updatedAt: string,
      userID: string,
      youtube?:  {
        category?: string | null,
        description?: string | null,
        privacy?: string | null,
        tags?: string | null,
        targetChannelID?: string | null,
        thumbnailURL?: string | null,
        title?: string | null,
        videoID?: string | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ListTaskReactionsQueryVariables = {
  filter?: ModelTaskReactionFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
  userID?: string | null,
};

export type ListTaskReactionsQuery = {
  listTaskReactions?:  {
    items:  Array< {
      createdAt: string,
      mltaskexecutionresultID: string,
      type: TaskReactionType,
      updatedAt: string,
      userID: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ListUsersQueryVariables = {
  filter?: ModelUserFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListUsersQuery = {
  listUsers?:  {
    items:  Array< {
      MLTaskExecutionResults?:  {
        nextToken?: string | null,
      } | null,
      MLTasksWorkspaces?:  {
        nextToken?: string | null,
      } | null,
      ModelInputPresets?:  {
        nextToken?: string | null,
      } | null,
      SocialManPosts?:  {
        nextToken?: string | null,
      } | null,
      completedTasksCount?: number | null,
      createdAt: string,
      createdPresetCount?: number | null,
      credits?: number | null,
      currentSubscriptionPlan?: SubscriptionPlan | null,
      email?: string | null,
      id: string,
      name?: string | null,
      periodEndOfLastSubscriptionCredits?: number | null,
      picture?: string | null,
      stripeConnectAccountID?: string | null,
      stripeConnectAccountSetupComplete?: boolean | null,
      stripeCustomerID?: string | null,
      updatedAt: string,
      username?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type MLTaskExecutionResultsByMltasksworkspaceIDQueryVariables = {
  filter?: ModelMLTaskExecutionResultFilterInput | null,
  limit?: number | null,
  mltasksworkspaceID: string,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type MLTaskExecutionResultsByMltasksworkspaceIDQuery = {
  mLTaskExecutionResultsByMltasksworkspaceID?:  {
    items:  Array< {
      ANGRYReactionStats?: number | null,
      CUTEReactionStats?: number | null,
      FUNNYReactionStats?: number | null,
      GROSSReactionStats?: number | null,
      HEARTReactionStats?: number | null,
      POOPReactionStats?: number | null,
      Reactions?:  {
        nextToken?: string | null,
      } | null,
      SADReactionStats?: number | null,
      SCARYReactionStats?: number | null,
      WOWReactionStats?: number | null,
      createdAt: string,
      creditsUsed?: number | null,
      id: string,
      inputs?:  Array< {
        name?: string | null,
        type?: ModelInputOutputEntryType | null,
        value?: string | null,
      } | null > | null,
      mltasksworkspaceID: string,
      modelName?: string | null,
      modelsID: string,
      output?:  {
        message?: string | null,
      } | null,
      presetUserInput?:  {
        presetCreatorName?: string | null,
        presetCreatorUID?: string | null,
        presetID?: string | null,
        presetName?: string | null,
      } | null,
      privacyLevel?: TaskPrivacyLevels | null,
      status?: TaskResultStatus | null,
      taskName?: string | null,
      updatedAt: string,
      userID: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type MLTaskExecutionResultsByModelsIDQueryVariables = {
  filter?: ModelMLTaskExecutionResultFilterInput | null,
  limit?: number | null,
  modelsID: string,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type MLTaskExecutionResultsByModelsIDQuery = {
  mLTaskExecutionResultsByModelsID?:  {
    items:  Array< {
      ANGRYReactionStats?: number | null,
      CUTEReactionStats?: number | null,
      FUNNYReactionStats?: number | null,
      GROSSReactionStats?: number | null,
      HEARTReactionStats?: number | null,
      POOPReactionStats?: number | null,
      Reactions?:  {
        nextToken?: string | null,
      } | null,
      SADReactionStats?: number | null,
      SCARYReactionStats?: number | null,
      WOWReactionStats?: number | null,
      createdAt: string,
      creditsUsed?: number | null,
      id: string,
      inputs?:  Array< {
        name?: string | null,
        type?: ModelInputOutputEntryType | null,
        value?: string | null,
      } | null > | null,
      mltasksworkspaceID: string,
      modelName?: string | null,
      modelsID: string,
      output?:  {
        message?: string | null,
      } | null,
      presetUserInput?:  {
        presetCreatorName?: string | null,
        presetCreatorUID?: string | null,
        presetID?: string | null,
        presetName?: string | null,
      } | null,
      privacyLevel?: TaskPrivacyLevels | null,
      status?: TaskResultStatus | null,
      taskName?: string | null,
      updatedAt: string,
      userID: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ModelInputPresetsByModelsIDQueryVariables = {
  filter?: ModelModelInputPresetFilterInput | null,
  limit?: number | null,
  modelsID: string,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ModelInputPresetsByModelsIDQuery = {
  modelInputPresetsByModelsID?:  {
    items:  Array< {
      cost?: number | null,
      createdAt: string,
      description?: string | null,
      entries?:  Array< {
        modelInputName?: string | null,
        modelInputType?: ModelInputOutputEntryType | null,
      } | null > | null,
      id: string,
      isOpenSource?: boolean | null,
      isPublic?: boolean | null,
      modelName?: string | null,
      modelsID: string,
      presetName?: string | null,
      taskName?: string | null,
      updatedAt: string,
      usage?: number | null,
      userID: string,
      username?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ModelInputPresetsByUserIDQueryVariables = {
  filter?: ModelModelInputPresetFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
  userID: string,
};

export type ModelInputPresetsByUserIDQuery = {
  modelInputPresetsByUserID?:  {
    items:  Array< {
      cost?: number | null,
      createdAt: string,
      description?: string | null,
      entries?:  Array< {
        modelInputName?: string | null,
        modelInputType?: ModelInputOutputEntryType | null,
      } | null > | null,
      id: string,
      isOpenSource?: boolean | null,
      isPublic?: boolean | null,
      modelName?: string | null,
      modelsID: string,
      presetName?: string | null,
      taskName?: string | null,
      updatedAt: string,
      usage?: number | null,
      userID: string,
      username?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ModelsByMltaskdataIDQueryVariables = {
  filter?: ModelModelsFilterInput | null,
  limit?: number | null,
  mltaskdataID: string,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ModelsByMltaskdataIDQuery = {
  modelsByMltaskdataID?:  {
    items:  Array< {
      MLTaskExecutionResults?:  {
        nextToken?: string | null,
      } | null,
      ModelInputPresets?:  {
        nextToken?: string | null,
      } | null,
      createdAt: string,
      id: string,
      isPublic: boolean,
      mltaskdataID: string,
      name?: string | null,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type TaskReactionsByMltaskexecutionresultIDQueryVariables = {
  filter?: ModelTaskReactionFilterInput | null,
  limit?: number | null,
  mltaskexecutionresultID: string,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type TaskReactionsByMltaskexecutionresultIDQuery = {
  taskReactionsByMltaskexecutionresultID?:  {
    items:  Array< {
      createdAt: string,
      mltaskexecutionresultID: string,
      type: TaskReactionType,
      updatedAt: string,
      userID: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type OnCreateMLTaskDataSubscriptionVariables = {
  filter?: ModelSubscriptionMLTaskDataFilterInput | null,
};

export type OnCreateMLTaskDataSubscription = {
  onCreateMLTaskData?:  {
    Models?:  {
      items:  Array< {
        createdAt: string,
        id: string,
        isPublic: boolean,
        mltaskdataID: string,
        name?: string | null,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    category?: string | null,
    createdAt: string,
    id: string,
    isPublic: boolean,
    name?: string | null,
    updatedAt: string,
  } | null,
};

export type OnCreateMLTaskExecutionResultSubscriptionVariables = {
  filter?: ModelSubscriptionMLTaskExecutionResultFilterInput | null,
  userID?: string | null,
};

export type OnCreateMLTaskExecutionResultSubscription = {
  onCreateMLTaskExecutionResult?:  {
    ANGRYReactionStats?: number | null,
    CUTEReactionStats?: number | null,
    FUNNYReactionStats?: number | null,
    GROSSReactionStats?: number | null,
    HEARTReactionStats?: number | null,
    POOPReactionStats?: number | null,
    Reactions?:  {
      items:  Array< {
        createdAt: string,
        mltaskexecutionresultID: string,
        type: TaskReactionType,
        updatedAt: string,
        userID: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    SADReactionStats?: number | null,
    SCARYReactionStats?: number | null,
    WOWReactionStats?: number | null,
    createdAt: string,
    creditsUsed?: number | null,
    id: string,
    inputs?:  Array< {
      metaData?:  Array< {
        key?: string | null,
        value?: string | null,
      } | null > | null,
      name?: string | null,
      type?: ModelInputOutputEntryType | null,
      value?: string | null,
    } | null > | null,
    mltasksworkspaceID: string,
    modelName?: string | null,
    modelsID: string,
    output?:  {
      entries?:  Array< {
        name?: string | null,
        type?: ModelInputOutputEntryType | null,
        value?: string | null,
      } | null > | null,
      message?: string | null,
    } | null,
    presetUserInput?:  {
      presetCreatorName?: string | null,
      presetCreatorUID?: string | null,
      presetID?: string | null,
      presetName?: string | null,
      presetValues?:  Array< {
        modelInputName?: string | null,
      } | null > | null,
    } | null,
    privacyLevel?: TaskPrivacyLevels | null,
    status?: TaskResultStatus | null,
    taskName?: string | null,
    updatedAt: string,
    userID: string,
  } | null,
};

export type OnCreateMLTasksWorkspaceSubscriptionVariables = {
  filter?: ModelSubscriptionMLTasksWorkspaceFilterInput | null,
  userID?: string | null,
};

export type OnCreateMLTasksWorkspaceSubscription = {
  onCreateMLTasksWorkspace?:  {
    MLTaskExecutionResults?:  {
      items:  Array< {
        ANGRYReactionStats?: number | null,
        CUTEReactionStats?: number | null,
        FUNNYReactionStats?: number | null,
        GROSSReactionStats?: number | null,
        HEARTReactionStats?: number | null,
        POOPReactionStats?: number | null,
        SADReactionStats?: number | null,
        SCARYReactionStats?: number | null,
        WOWReactionStats?: number | null,
        createdAt: string,
        creditsUsed?: number | null,
        id: string,
        mltasksworkspaceID: string,
        modelName?: string | null,
        modelsID: string,
        privacyLevel?: TaskPrivacyLevels | null,
        status?: TaskResultStatus | null,
        taskName?: string | null,
        updatedAt: string,
        userID: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    description?: string | null,
    id: string,
    name?: string | null,
    updatedAt: string,
    userID: string,
  } | null,
};

export type OnCreateModelInputPresetSubscriptionVariables = {
  filter?: ModelSubscriptionModelInputPresetFilterInput | null,
  userID?: string | null,
};

export type OnCreateModelInputPresetSubscription = {
  onCreateModelInputPreset?:  {
    cost?: number | null,
    createdAt: string,
    description?: string | null,
    entries?:  Array< {
      modelInputName?: string | null,
      modelInputType?: ModelInputOutputEntryType | null,
      presetEntryDatas?:  Array< {
        description?: string | null,
        entryType?: InputPresetEntryType | null,
        parameterName?: string | null,
        presetOrder?: number | null,
        val?: string | null,
      } | null > | null,
    } | null > | null,
    id: string,
    isOpenSource?: boolean | null,
    isPublic?: boolean | null,
    modelName?: string | null,
    modelsID: string,
    presetName?: string | null,
    taskName?: string | null,
    updatedAt: string,
    usage?: number | null,
    userID: string,
    username?: string | null,
  } | null,
};

export type OnCreateModelsSubscriptionVariables = {
  filter?: ModelSubscriptionModelsFilterInput | null,
};

export type OnCreateModelsSubscription = {
  onCreateModels?:  {
    MLTaskExecutionResults?:  {
      items:  Array< {
        ANGRYReactionStats?: number | null,
        CUTEReactionStats?: number | null,
        FUNNYReactionStats?: number | null,
        GROSSReactionStats?: number | null,
        HEARTReactionStats?: number | null,
        POOPReactionStats?: number | null,
        SADReactionStats?: number | null,
        SCARYReactionStats?: number | null,
        WOWReactionStats?: number | null,
        createdAt: string,
        creditsUsed?: number | null,
        id: string,
        mltasksworkspaceID: string,
        modelName?: string | null,
        modelsID: string,
        privacyLevel?: TaskPrivacyLevels | null,
        status?: TaskResultStatus | null,
        taskName?: string | null,
        updatedAt: string,
        userID: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    ModelInputPresets?:  {
      items:  Array< {
        cost?: number | null,
        createdAt: string,
        description?: string | null,
        id: string,
        isOpenSource?: boolean | null,
        isPublic?: boolean | null,
        modelName?: string | null,
        modelsID: string,
        presetName?: string | null,
        taskName?: string | null,
        updatedAt: string,
        usage?: number | null,
        userID: string,
        username?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    id: string,
    inputs?:  {
      optional?:  Array< {
        defaultValue?: string | null,
        description?: string | null,
        examples?: Array< string | null > | null,
        name: string,
        optionStep?: number | null,
        optionType?: OptionType | null,
        type: ModelInputOutputEntryType,
      } | null > | null,
      required?:  Array< {
        defaultValue?: string | null,
        description?: string | null,
        examples?: Array< string | null > | null,
        name: string,
        optionStep?: number | null,
        optionType?: OptionType | null,
        type: ModelInputOutputEntryType,
      } | null > | null,
    } | null,
    isPublic: boolean,
    mltaskdataID: string,
    name?: string | null,
    outputs?:  {
      entries?:  Array< {
        name?: string | null,
        type?: ModelInputOutputEntryType | null,
      } | null > | null,
    } | null,
    updatedAt: string,
  } | null,
};

export type OnCreateSocialManPostSubscriptionVariables = {
  filter?: ModelSubscriptionSocialManPostFilterInput | null,
  userID?: string | null,
};

export type OnCreateSocialManPostSubscription = {
  onCreateSocialManPost?:  {
    createdAt: string,
    description?: string | null,
    enabledFacebook?: boolean | null,
    enabledInstagram?: boolean | null,
    enabledLinkedin?: boolean | null,
    enabledPinterest?: boolean | null,
    enabledTiktok?: boolean | null,
    enabledTwitter?: boolean | null,
    enabledYoutube?: boolean | null,
    facebook?:  {
      caption: string,
      postID?: string | null,
      postToStory?: boolean | null,
      storyID?: string | null,
      storyURL?: string | null,
      targetPageID?: string | null,
      thumbnail?: string | null,
    } | null,
    id: string,
    instagram?:  {
      caption: string,
      postID?: string | null,
      postToStory?: boolean | null,
      storyID?: string | null,
      targetAccountID?: string | null,
      thumbnail?: string | null,
    } | null,
    linkedin?:  {
      caption?: string | null,
      mediaDescription?: string | null,
      postID?: string | null,
    } | null,
    pinterest?:  {
      boardID?: string | null,
      description?: string | null,
      link?: string | null,
      pinID?: string | null,
      thumbnailURL?: string | null,
      title?: string | null,
    } | null,
    postedTime?: number | null,
    postedToFacebook?: boolean | null,
    postedToInstagram?: boolean | null,
    postedToLinkedin?: boolean | null,
    postedToPinterest?: boolean | null,
    postedToTiktok?: boolean | null,
    postedToTwitter?: boolean | null,
    postedToYoutube?: boolean | null,
    s3Key?: string | null,
    s3KeyResized?: string | null,
    scheduledPostTime?: number | null,
    status: SocialManPostStatus,
    tiktok?:  {
      caption?: string | null,
      contentDisclosureBrandedContent?: boolean | null,
      contentDisclosureEnabled?: boolean | null,
      contentDisclosureYourBrand?: boolean | null,
      postID?: string | null,
      privacy?: SocialManTiktokPrivacyStatus | null,
      title?: string | null,
      usersCanComment?: boolean | null,
      usersCanDuet?: boolean | null,
      usersCanStitch?: boolean | null,
      videoCoverTimestampMs?: number | null,
    } | null,
    title?: string | null,
    twitter?:  {
      caption: string,
      tweetID?: string | null,
    } | null,
    updatedAt: string,
    userID: string,
    youtube?:  {
      category?: string | null,
      description?: string | null,
      privacy?: string | null,
      tags?: string | null,
      targetChannelID?: string | null,
      thumbnailURL?: string | null,
      title?: string | null,
      videoID?: string | null,
    } | null,
  } | null,
};

export type OnCreateTaskReactionSubscriptionVariables = {
  filter?: ModelSubscriptionTaskReactionFilterInput | null,
};

export type OnCreateTaskReactionSubscription = {
  onCreateTaskReaction?:  {
    createdAt: string,
    mltaskexecutionresultID: string,
    type: TaskReactionType,
    updatedAt: string,
    userID: string,
  } | null,
};

export type OnCreateUserSubscriptionVariables = {
  filter?: ModelSubscriptionUserFilterInput | null,
  id?: string | null,
};

export type OnCreateUserSubscription = {
  onCreateUser?:  {
    MLTaskExecutionResults?:  {
      items:  Array< {
        ANGRYReactionStats?: number | null,
        CUTEReactionStats?: number | null,
        FUNNYReactionStats?: number | null,
        GROSSReactionStats?: number | null,
        HEARTReactionStats?: number | null,
        POOPReactionStats?: number | null,
        SADReactionStats?: number | null,
        SCARYReactionStats?: number | null,
        WOWReactionStats?: number | null,
        createdAt: string,
        creditsUsed?: number | null,
        id: string,
        mltasksworkspaceID: string,
        modelName?: string | null,
        modelsID: string,
        privacyLevel?: TaskPrivacyLevels | null,
        status?: TaskResultStatus | null,
        taskName?: string | null,
        updatedAt: string,
        userID: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    MLTasksWorkspaces?:  {
      items:  Array< {
        createdAt: string,
        description?: string | null,
        id: string,
        name?: string | null,
        updatedAt: string,
        userID: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    ModelInputPresets?:  {
      items:  Array< {
        cost?: number | null,
        createdAt: string,
        description?: string | null,
        id: string,
        isOpenSource?: boolean | null,
        isPublic?: boolean | null,
        modelName?: string | null,
        modelsID: string,
        presetName?: string | null,
        taskName?: string | null,
        updatedAt: string,
        usage?: number | null,
        userID: string,
        username?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    SocialManPosts?:  {
      items:  Array< {
        createdAt: string,
        description?: string | null,
        enabledFacebook?: boolean | null,
        enabledInstagram?: boolean | null,
        enabledLinkedin?: boolean | null,
        enabledPinterest?: boolean | null,
        enabledTiktok?: boolean | null,
        enabledTwitter?: boolean | null,
        enabledYoutube?: boolean | null,
        id: string,
        postedTime?: number | null,
        postedToFacebook?: boolean | null,
        postedToInstagram?: boolean | null,
        postedToLinkedin?: boolean | null,
        postedToPinterest?: boolean | null,
        postedToTiktok?: boolean | null,
        postedToTwitter?: boolean | null,
        postedToYoutube?: boolean | null,
        s3Key?: string | null,
        s3KeyResized?: string | null,
        scheduledPostTime?: number | null,
        status: SocialManPostStatus,
        title?: string | null,
        updatedAt: string,
        userID: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    completedTasksCount?: number | null,
    createdAt: string,
    createdPresetCount?: number | null,
    credits?: number | null,
    currentSubscriptionPlan?: SubscriptionPlan | null,
    email?: string | null,
    facebook?:  {
      instagramsData:  Array< {
        accessToken?: string | null,
        biography?: string | null,
        connectedPageID?: string | null,
        expiresOn?: number | null,
        followersCount?: number | null,
        followsCount?: number | null,
        id?: string | null,
        igID?: string | null,
        mediaCount?: number | null,
        name?: string | null,
        profilePictureURL?: string | null,
        username?: string | null,
      } | null >,
      pagesData:  Array< {
        accessToken?: string | null,
        category?: string | null,
        expiresOn?: number | null,
        id?: string | null,
        name?: string | null,
        picture?: string | null,
      } | null >,
      userData:  {
        accessToken?: string | null,
        expiresOn?: number | null,
        id?: string | null,
        name?: string | null,
        picture?: string | null,
      },
    } | null,
    google?:  {
      channelsData:  Array< {
        customUrl?: string | null,
        description?: string | null,
        id?: string | null,
        thumbnail?: string | null,
        title?: string | null,
      } | null >,
      tokenData:  {
        accessToken?: string | null,
        expiresOn?: number | null,
        idToken?: string | null,
        refreshToken?: string | null,
        scope?: string | null,
        tokenType?: string | null,
      },
      userData:  {
        email?: string | null,
        familyName?: string | null,
        givenName?: string | null,
        id?: string | null,
        locale?: string | null,
        name?: string | null,
        picture?: string | null,
        verifiedEmail?: boolean | null,
      },
    } | null,
    id: string,
    linkedin?:  {
      tokenData:  {
        accessToken: string,
        accessTokenExpirationDate: number,
        scope: string,
      },
      userData:  {
        email?: string | null,
        name?: string | null,
        picture?: string | null,
        sub?: string | null,
      },
    } | null,
    name?: string | null,
    periodEndOfLastSubscriptionCredits?: number | null,
    picture?: string | null,
    pinterest?:  {
      boardsData?:  Array< {
        createdAt?: string | null,
        description?: string | null,
        followerCount?: number | null,
        id?: string | null,
        imageCoverUrl?: string | null,
        name?: string | null,
        pinCount?: number | null,
        privacy?: string | null,
      } | null > | null,
      tokenData:  {
        accessToken: string,
        accessTokenExpirationDate: number,
        refreshToken: string,
        refreshTokenExpirationDate: number,
        scope: string,
      },
      userData:  {
        about?: string | null,
        accountType?: string | null,
        boardCount?: number | null,
        businessName?: string | null,
        followerCount?: number | null,
        followingCount?: number | null,
        id?: string | null,
        monthlyViews?: number | null,
        pinCount?: number | null,
        profileImage?: string | null,
        username?: string | null,
        websiteUrl?: string | null,
      },
    } | null,
    stripeConnectAccountID?: string | null,
    stripeConnectAccountSetupComplete?: boolean | null,
    stripeCustomerID?: string | null,
    tiktok?:  {
      tokenData:  {
        accessToken: string,
        accessTokenExpirationDate: number,
        refreshToken: string,
        refreshTokenExpirationDate: number,
        scope: string,
      },
      userData:  {
        avatarLargeURL?: string | null,
        avatarURL?: string | null,
        avatarURL100?: string | null,
        bioDescription?: string | null,
        commentDisabled?: boolean | null,
        displayName?: string | null,
        duetDisabled?: boolean | null,
        followerCount?: number | null,
        followingCount?: number | null,
        isVerified?: boolean | null,
        likesCount?: number | null,
        maxVideoPostDuration_sec?: number | null,
        privacyLevelOptions?: Array< string | null > | null,
        profileDeepLink?: string | null,
        stitchDisabled?: boolean | null,
        username?: string | null,
        videoCount?: number | null,
      },
    } | null,
    twitter?:  {
      tokenData:  {
        accessToken?: string | null,
        accessTokenSecret?: string | null,
        codeVerifier?: string | null,
        expiresOn?: number | null,
        oauthTokenSecret?: string | null,
        refreshToken?: string | null,
      },
      userData:  {
        description?: string | null,
        id?: string | null,
        name?: string | null,
        profilePictureURL?: string | null,
        username?: string | null,
        verifiedType?: TwitterVerifiedType | null,
      },
    } | null,
    updatedAt: string,
    username?: string | null,
  } | null,
};

export type OnDeleteMLTaskDataSubscriptionVariables = {
  filter?: ModelSubscriptionMLTaskDataFilterInput | null,
};

export type OnDeleteMLTaskDataSubscription = {
  onDeleteMLTaskData?:  {
    Models?:  {
      items:  Array< {
        createdAt: string,
        id: string,
        isPublic: boolean,
        mltaskdataID: string,
        name?: string | null,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    category?: string | null,
    createdAt: string,
    id: string,
    isPublic: boolean,
    name?: string | null,
    updatedAt: string,
  } | null,
};

export type OnDeleteMLTaskExecutionResultSubscriptionVariables = {
  filter?: ModelSubscriptionMLTaskExecutionResultFilterInput | null,
  userID?: string | null,
};

export type OnDeleteMLTaskExecutionResultSubscription = {
  onDeleteMLTaskExecutionResult?:  {
    ANGRYReactionStats?: number | null,
    CUTEReactionStats?: number | null,
    FUNNYReactionStats?: number | null,
    GROSSReactionStats?: number | null,
    HEARTReactionStats?: number | null,
    POOPReactionStats?: number | null,
    Reactions?:  {
      items:  Array< {
        createdAt: string,
        mltaskexecutionresultID: string,
        type: TaskReactionType,
        updatedAt: string,
        userID: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    SADReactionStats?: number | null,
    SCARYReactionStats?: number | null,
    WOWReactionStats?: number | null,
    createdAt: string,
    creditsUsed?: number | null,
    id: string,
    inputs?:  Array< {
      metaData?:  Array< {
        key?: string | null,
        value?: string | null,
      } | null > | null,
      name?: string | null,
      type?: ModelInputOutputEntryType | null,
      value?: string | null,
    } | null > | null,
    mltasksworkspaceID: string,
    modelName?: string | null,
    modelsID: string,
    output?:  {
      entries?:  Array< {
        name?: string | null,
        type?: ModelInputOutputEntryType | null,
        value?: string | null,
      } | null > | null,
      message?: string | null,
    } | null,
    presetUserInput?:  {
      presetCreatorName?: string | null,
      presetCreatorUID?: string | null,
      presetID?: string | null,
      presetName?: string | null,
      presetValues?:  Array< {
        modelInputName?: string | null,
      } | null > | null,
    } | null,
    privacyLevel?: TaskPrivacyLevels | null,
    status?: TaskResultStatus | null,
    taskName?: string | null,
    updatedAt: string,
    userID: string,
  } | null,
};

export type OnDeleteMLTasksWorkspaceSubscriptionVariables = {
  filter?: ModelSubscriptionMLTasksWorkspaceFilterInput | null,
  userID?: string | null,
};

export type OnDeleteMLTasksWorkspaceSubscription = {
  onDeleteMLTasksWorkspace?:  {
    MLTaskExecutionResults?:  {
      items:  Array< {
        ANGRYReactionStats?: number | null,
        CUTEReactionStats?: number | null,
        FUNNYReactionStats?: number | null,
        GROSSReactionStats?: number | null,
        HEARTReactionStats?: number | null,
        POOPReactionStats?: number | null,
        SADReactionStats?: number | null,
        SCARYReactionStats?: number | null,
        WOWReactionStats?: number | null,
        createdAt: string,
        creditsUsed?: number | null,
        id: string,
        mltasksworkspaceID: string,
        modelName?: string | null,
        modelsID: string,
        privacyLevel?: TaskPrivacyLevels | null,
        status?: TaskResultStatus | null,
        taskName?: string | null,
        updatedAt: string,
        userID: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    description?: string | null,
    id: string,
    name?: string | null,
    updatedAt: string,
    userID: string,
  } | null,
};

export type OnDeleteModelInputPresetSubscriptionVariables = {
  filter?: ModelSubscriptionModelInputPresetFilterInput | null,
  userID?: string | null,
};

export type OnDeleteModelInputPresetSubscription = {
  onDeleteModelInputPreset?:  {
    cost?: number | null,
    createdAt: string,
    description?: string | null,
    entries?:  Array< {
      modelInputName?: string | null,
      modelInputType?: ModelInputOutputEntryType | null,
      presetEntryDatas?:  Array< {
        description?: string | null,
        entryType?: InputPresetEntryType | null,
        parameterName?: string | null,
        presetOrder?: number | null,
        val?: string | null,
      } | null > | null,
    } | null > | null,
    id: string,
    isOpenSource?: boolean | null,
    isPublic?: boolean | null,
    modelName?: string | null,
    modelsID: string,
    presetName?: string | null,
    taskName?: string | null,
    updatedAt: string,
    usage?: number | null,
    userID: string,
    username?: string | null,
  } | null,
};

export type OnDeleteModelsSubscriptionVariables = {
  filter?: ModelSubscriptionModelsFilterInput | null,
};

export type OnDeleteModelsSubscription = {
  onDeleteModels?:  {
    MLTaskExecutionResults?:  {
      items:  Array< {
        ANGRYReactionStats?: number | null,
        CUTEReactionStats?: number | null,
        FUNNYReactionStats?: number | null,
        GROSSReactionStats?: number | null,
        HEARTReactionStats?: number | null,
        POOPReactionStats?: number | null,
        SADReactionStats?: number | null,
        SCARYReactionStats?: number | null,
        WOWReactionStats?: number | null,
        createdAt: string,
        creditsUsed?: number | null,
        id: string,
        mltasksworkspaceID: string,
        modelName?: string | null,
        modelsID: string,
        privacyLevel?: TaskPrivacyLevels | null,
        status?: TaskResultStatus | null,
        taskName?: string | null,
        updatedAt: string,
        userID: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    ModelInputPresets?:  {
      items:  Array< {
        cost?: number | null,
        createdAt: string,
        description?: string | null,
        id: string,
        isOpenSource?: boolean | null,
        isPublic?: boolean | null,
        modelName?: string | null,
        modelsID: string,
        presetName?: string | null,
        taskName?: string | null,
        updatedAt: string,
        usage?: number | null,
        userID: string,
        username?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    id: string,
    inputs?:  {
      optional?:  Array< {
        defaultValue?: string | null,
        description?: string | null,
        examples?: Array< string | null > | null,
        name: string,
        optionStep?: number | null,
        optionType?: OptionType | null,
        type: ModelInputOutputEntryType,
      } | null > | null,
      required?:  Array< {
        defaultValue?: string | null,
        description?: string | null,
        examples?: Array< string | null > | null,
        name: string,
        optionStep?: number | null,
        optionType?: OptionType | null,
        type: ModelInputOutputEntryType,
      } | null > | null,
    } | null,
    isPublic: boolean,
    mltaskdataID: string,
    name?: string | null,
    outputs?:  {
      entries?:  Array< {
        name?: string | null,
        type?: ModelInputOutputEntryType | null,
      } | null > | null,
    } | null,
    updatedAt: string,
  } | null,
};

export type OnDeleteSocialManPostSubscriptionVariables = {
  filter?: ModelSubscriptionSocialManPostFilterInput | null,
  userID?: string | null,
};

export type OnDeleteSocialManPostSubscription = {
  onDeleteSocialManPost?:  {
    createdAt: string,
    description?: string | null,
    enabledFacebook?: boolean | null,
    enabledInstagram?: boolean | null,
    enabledLinkedin?: boolean | null,
    enabledPinterest?: boolean | null,
    enabledTiktok?: boolean | null,
    enabledTwitter?: boolean | null,
    enabledYoutube?: boolean | null,
    facebook?:  {
      caption: string,
      postID?: string | null,
      postToStory?: boolean | null,
      storyID?: string | null,
      storyURL?: string | null,
      targetPageID?: string | null,
      thumbnail?: string | null,
    } | null,
    id: string,
    instagram?:  {
      caption: string,
      postID?: string | null,
      postToStory?: boolean | null,
      storyID?: string | null,
      targetAccountID?: string | null,
      thumbnail?: string | null,
    } | null,
    linkedin?:  {
      caption?: string | null,
      mediaDescription?: string | null,
      postID?: string | null,
    } | null,
    pinterest?:  {
      boardID?: string | null,
      description?: string | null,
      link?: string | null,
      pinID?: string | null,
      thumbnailURL?: string | null,
      title?: string | null,
    } | null,
    postedTime?: number | null,
    postedToFacebook?: boolean | null,
    postedToInstagram?: boolean | null,
    postedToLinkedin?: boolean | null,
    postedToPinterest?: boolean | null,
    postedToTiktok?: boolean | null,
    postedToTwitter?: boolean | null,
    postedToYoutube?: boolean | null,
    s3Key?: string | null,
    s3KeyResized?: string | null,
    scheduledPostTime?: number | null,
    status: SocialManPostStatus,
    tiktok?:  {
      caption?: string | null,
      contentDisclosureBrandedContent?: boolean | null,
      contentDisclosureEnabled?: boolean | null,
      contentDisclosureYourBrand?: boolean | null,
      postID?: string | null,
      privacy?: SocialManTiktokPrivacyStatus | null,
      title?: string | null,
      usersCanComment?: boolean | null,
      usersCanDuet?: boolean | null,
      usersCanStitch?: boolean | null,
      videoCoverTimestampMs?: number | null,
    } | null,
    title?: string | null,
    twitter?:  {
      caption: string,
      tweetID?: string | null,
    } | null,
    updatedAt: string,
    userID: string,
    youtube?:  {
      category?: string | null,
      description?: string | null,
      privacy?: string | null,
      tags?: string | null,
      targetChannelID?: string | null,
      thumbnailURL?: string | null,
      title?: string | null,
      videoID?: string | null,
    } | null,
  } | null,
};

export type OnDeleteTaskReactionSubscriptionVariables = {
  filter?: ModelSubscriptionTaskReactionFilterInput | null,
};

export type OnDeleteTaskReactionSubscription = {
  onDeleteTaskReaction?:  {
    createdAt: string,
    mltaskexecutionresultID: string,
    type: TaskReactionType,
    updatedAt: string,
    userID: string,
  } | null,
};

export type OnDeleteUserSubscriptionVariables = {
  filter?: ModelSubscriptionUserFilterInput | null,
  id?: string | null,
};

export type OnDeleteUserSubscription = {
  onDeleteUser?:  {
    MLTaskExecutionResults?:  {
      items:  Array< {
        ANGRYReactionStats?: number | null,
        CUTEReactionStats?: number | null,
        FUNNYReactionStats?: number | null,
        GROSSReactionStats?: number | null,
        HEARTReactionStats?: number | null,
        POOPReactionStats?: number | null,
        SADReactionStats?: number | null,
        SCARYReactionStats?: number | null,
        WOWReactionStats?: number | null,
        createdAt: string,
        creditsUsed?: number | null,
        id: string,
        mltasksworkspaceID: string,
        modelName?: string | null,
        modelsID: string,
        privacyLevel?: TaskPrivacyLevels | null,
        status?: TaskResultStatus | null,
        taskName?: string | null,
        updatedAt: string,
        userID: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    MLTasksWorkspaces?:  {
      items:  Array< {
        createdAt: string,
        description?: string | null,
        id: string,
        name?: string | null,
        updatedAt: string,
        userID: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    ModelInputPresets?:  {
      items:  Array< {
        cost?: number | null,
        createdAt: string,
        description?: string | null,
        id: string,
        isOpenSource?: boolean | null,
        isPublic?: boolean | null,
        modelName?: string | null,
        modelsID: string,
        presetName?: string | null,
        taskName?: string | null,
        updatedAt: string,
        usage?: number | null,
        userID: string,
        username?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    SocialManPosts?:  {
      items:  Array< {
        createdAt: string,
        description?: string | null,
        enabledFacebook?: boolean | null,
        enabledInstagram?: boolean | null,
        enabledLinkedin?: boolean | null,
        enabledPinterest?: boolean | null,
        enabledTiktok?: boolean | null,
        enabledTwitter?: boolean | null,
        enabledYoutube?: boolean | null,
        id: string,
        postedTime?: number | null,
        postedToFacebook?: boolean | null,
        postedToInstagram?: boolean | null,
        postedToLinkedin?: boolean | null,
        postedToPinterest?: boolean | null,
        postedToTiktok?: boolean | null,
        postedToTwitter?: boolean | null,
        postedToYoutube?: boolean | null,
        s3Key?: string | null,
        s3KeyResized?: string | null,
        scheduledPostTime?: number | null,
        status: SocialManPostStatus,
        title?: string | null,
        updatedAt: string,
        userID: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    completedTasksCount?: number | null,
    createdAt: string,
    createdPresetCount?: number | null,
    credits?: number | null,
    currentSubscriptionPlan?: SubscriptionPlan | null,
    email?: string | null,
    facebook?:  {
      instagramsData:  Array< {
        accessToken?: string | null,
        biography?: string | null,
        connectedPageID?: string | null,
        expiresOn?: number | null,
        followersCount?: number | null,
        followsCount?: number | null,
        id?: string | null,
        igID?: string | null,
        mediaCount?: number | null,
        name?: string | null,
        profilePictureURL?: string | null,
        username?: string | null,
      } | null >,
      pagesData:  Array< {
        accessToken?: string | null,
        category?: string | null,
        expiresOn?: number | null,
        id?: string | null,
        name?: string | null,
        picture?: string | null,
      } | null >,
      userData:  {
        accessToken?: string | null,
        expiresOn?: number | null,
        id?: string | null,
        name?: string | null,
        picture?: string | null,
      },
    } | null,
    google?:  {
      channelsData:  Array< {
        customUrl?: string | null,
        description?: string | null,
        id?: string | null,
        thumbnail?: string | null,
        title?: string | null,
      } | null >,
      tokenData:  {
        accessToken?: string | null,
        expiresOn?: number | null,
        idToken?: string | null,
        refreshToken?: string | null,
        scope?: string | null,
        tokenType?: string | null,
      },
      userData:  {
        email?: string | null,
        familyName?: string | null,
        givenName?: string | null,
        id?: string | null,
        locale?: string | null,
        name?: string | null,
        picture?: string | null,
        verifiedEmail?: boolean | null,
      },
    } | null,
    id: string,
    linkedin?:  {
      tokenData:  {
        accessToken: string,
        accessTokenExpirationDate: number,
        scope: string,
      },
      userData:  {
        email?: string | null,
        name?: string | null,
        picture?: string | null,
        sub?: string | null,
      },
    } | null,
    name?: string | null,
    periodEndOfLastSubscriptionCredits?: number | null,
    picture?: string | null,
    pinterest?:  {
      boardsData?:  Array< {
        createdAt?: string | null,
        description?: string | null,
        followerCount?: number | null,
        id?: string | null,
        imageCoverUrl?: string | null,
        name?: string | null,
        pinCount?: number | null,
        privacy?: string | null,
      } | null > | null,
      tokenData:  {
        accessToken: string,
        accessTokenExpirationDate: number,
        refreshToken: string,
        refreshTokenExpirationDate: number,
        scope: string,
      },
      userData:  {
        about?: string | null,
        accountType?: string | null,
        boardCount?: number | null,
        businessName?: string | null,
        followerCount?: number | null,
        followingCount?: number | null,
        id?: string | null,
        monthlyViews?: number | null,
        pinCount?: number | null,
        profileImage?: string | null,
        username?: string | null,
        websiteUrl?: string | null,
      },
    } | null,
    stripeConnectAccountID?: string | null,
    stripeConnectAccountSetupComplete?: boolean | null,
    stripeCustomerID?: string | null,
    tiktok?:  {
      tokenData:  {
        accessToken: string,
        accessTokenExpirationDate: number,
        refreshToken: string,
        refreshTokenExpirationDate: number,
        scope: string,
      },
      userData:  {
        avatarLargeURL?: string | null,
        avatarURL?: string | null,
        avatarURL100?: string | null,
        bioDescription?: string | null,
        commentDisabled?: boolean | null,
        displayName?: string | null,
        duetDisabled?: boolean | null,
        followerCount?: number | null,
        followingCount?: number | null,
        isVerified?: boolean | null,
        likesCount?: number | null,
        maxVideoPostDuration_sec?: number | null,
        privacyLevelOptions?: Array< string | null > | null,
        profileDeepLink?: string | null,
        stitchDisabled?: boolean | null,
        username?: string | null,
        videoCount?: number | null,
      },
    } | null,
    twitter?:  {
      tokenData:  {
        accessToken?: string | null,
        accessTokenSecret?: string | null,
        codeVerifier?: string | null,
        expiresOn?: number | null,
        oauthTokenSecret?: string | null,
        refreshToken?: string | null,
      },
      userData:  {
        description?: string | null,
        id?: string | null,
        name?: string | null,
        profilePictureURL?: string | null,
        username?: string | null,
        verifiedType?: TwitterVerifiedType | null,
      },
    } | null,
    updatedAt: string,
    username?: string | null,
  } | null,
};

export type OnUpdateMLTaskDataSubscriptionVariables = {
  filter?: ModelSubscriptionMLTaskDataFilterInput | null,
};

export type OnUpdateMLTaskDataSubscription = {
  onUpdateMLTaskData?:  {
    Models?:  {
      items:  Array< {
        createdAt: string,
        id: string,
        isPublic: boolean,
        mltaskdataID: string,
        name?: string | null,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    category?: string | null,
    createdAt: string,
    id: string,
    isPublic: boolean,
    name?: string | null,
    updatedAt: string,
  } | null,
};

export type OnUpdateMLTaskExecutionResultSubscriptionVariables = {
  filter?: ModelSubscriptionMLTaskExecutionResultFilterInput | null,
  userID?: string | null,
};

export type OnUpdateMLTaskExecutionResultSubscription = {
  onUpdateMLTaskExecutionResult?:  {
    ANGRYReactionStats?: number | null,
    CUTEReactionStats?: number | null,
    FUNNYReactionStats?: number | null,
    GROSSReactionStats?: number | null,
    HEARTReactionStats?: number | null,
    POOPReactionStats?: number | null,
    Reactions?:  {
      items:  Array< {
        createdAt: string,
        mltaskexecutionresultID: string,
        type: TaskReactionType,
        updatedAt: string,
        userID: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    SADReactionStats?: number | null,
    SCARYReactionStats?: number | null,
    WOWReactionStats?: number | null,
    createdAt: string,
    creditsUsed?: number | null,
    id: string,
    inputs?:  Array< {
      metaData?:  Array< {
        key?: string | null,
        value?: string | null,
      } | null > | null,
      name?: string | null,
      type?: ModelInputOutputEntryType | null,
      value?: string | null,
    } | null > | null,
    mltasksworkspaceID: string,
    modelName?: string | null,
    modelsID: string,
    output?:  {
      entries?:  Array< {
        name?: string | null,
        type?: ModelInputOutputEntryType | null,
        value?: string | null,
      } | null > | null,
      message?: string | null,
    } | null,
    presetUserInput?:  {
      presetCreatorName?: string | null,
      presetCreatorUID?: string | null,
      presetID?: string | null,
      presetName?: string | null,
      presetValues?:  Array< {
        modelInputName?: string | null,
      } | null > | null,
    } | null,
    privacyLevel?: TaskPrivacyLevels | null,
    status?: TaskResultStatus | null,
    taskName?: string | null,
    updatedAt: string,
    userID: string,
  } | null,
};

export type OnUpdateMLTasksWorkspaceSubscriptionVariables = {
  filter?: ModelSubscriptionMLTasksWorkspaceFilterInput | null,
  userID?: string | null,
};

export type OnUpdateMLTasksWorkspaceSubscription = {
  onUpdateMLTasksWorkspace?:  {
    MLTaskExecutionResults?:  {
      items:  Array< {
        ANGRYReactionStats?: number | null,
        CUTEReactionStats?: number | null,
        FUNNYReactionStats?: number | null,
        GROSSReactionStats?: number | null,
        HEARTReactionStats?: number | null,
        POOPReactionStats?: number | null,
        SADReactionStats?: number | null,
        SCARYReactionStats?: number | null,
        WOWReactionStats?: number | null,
        createdAt: string,
        creditsUsed?: number | null,
        id: string,
        mltasksworkspaceID: string,
        modelName?: string | null,
        modelsID: string,
        privacyLevel?: TaskPrivacyLevels | null,
        status?: TaskResultStatus | null,
        taskName?: string | null,
        updatedAt: string,
        userID: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    description?: string | null,
    id: string,
    name?: string | null,
    updatedAt: string,
    userID: string,
  } | null,
};

export type OnUpdateModelInputPresetSubscriptionVariables = {
  filter?: ModelSubscriptionModelInputPresetFilterInput | null,
  userID?: string | null,
};

export type OnUpdateModelInputPresetSubscription = {
  onUpdateModelInputPreset?:  {
    cost?: number | null,
    createdAt: string,
    description?: string | null,
    entries?:  Array< {
      modelInputName?: string | null,
      modelInputType?: ModelInputOutputEntryType | null,
      presetEntryDatas?:  Array< {
        description?: string | null,
        entryType?: InputPresetEntryType | null,
        parameterName?: string | null,
        presetOrder?: number | null,
        val?: string | null,
      } | null > | null,
    } | null > | null,
    id: string,
    isOpenSource?: boolean | null,
    isPublic?: boolean | null,
    modelName?: string | null,
    modelsID: string,
    presetName?: string | null,
    taskName?: string | null,
    updatedAt: string,
    usage?: number | null,
    userID: string,
    username?: string | null,
  } | null,
};

export type OnUpdateModelsSubscriptionVariables = {
  filter?: ModelSubscriptionModelsFilterInput | null,
};

export type OnUpdateModelsSubscription = {
  onUpdateModels?:  {
    MLTaskExecutionResults?:  {
      items:  Array< {
        ANGRYReactionStats?: number | null,
        CUTEReactionStats?: number | null,
        FUNNYReactionStats?: number | null,
        GROSSReactionStats?: number | null,
        HEARTReactionStats?: number | null,
        POOPReactionStats?: number | null,
        SADReactionStats?: number | null,
        SCARYReactionStats?: number | null,
        WOWReactionStats?: number | null,
        createdAt: string,
        creditsUsed?: number | null,
        id: string,
        mltasksworkspaceID: string,
        modelName?: string | null,
        modelsID: string,
        privacyLevel?: TaskPrivacyLevels | null,
        status?: TaskResultStatus | null,
        taskName?: string | null,
        updatedAt: string,
        userID: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    ModelInputPresets?:  {
      items:  Array< {
        cost?: number | null,
        createdAt: string,
        description?: string | null,
        id: string,
        isOpenSource?: boolean | null,
        isPublic?: boolean | null,
        modelName?: string | null,
        modelsID: string,
        presetName?: string | null,
        taskName?: string | null,
        updatedAt: string,
        usage?: number | null,
        userID: string,
        username?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    id: string,
    inputs?:  {
      optional?:  Array< {
        defaultValue?: string | null,
        description?: string | null,
        examples?: Array< string | null > | null,
        name: string,
        optionStep?: number | null,
        optionType?: OptionType | null,
        type: ModelInputOutputEntryType,
      } | null > | null,
      required?:  Array< {
        defaultValue?: string | null,
        description?: string | null,
        examples?: Array< string | null > | null,
        name: string,
        optionStep?: number | null,
        optionType?: OptionType | null,
        type: ModelInputOutputEntryType,
      } | null > | null,
    } | null,
    isPublic: boolean,
    mltaskdataID: string,
    name?: string | null,
    outputs?:  {
      entries?:  Array< {
        name?: string | null,
        type?: ModelInputOutputEntryType | null,
      } | null > | null,
    } | null,
    updatedAt: string,
  } | null,
};

export type OnUpdateSocialManPostSubscriptionVariables = {
  filter?: ModelSubscriptionSocialManPostFilterInput | null,
  userID?: string | null,
};

export type OnUpdateSocialManPostSubscription = {
  onUpdateSocialManPost?:  {
    createdAt: string,
    description?: string | null,
    enabledFacebook?: boolean | null,
    enabledInstagram?: boolean | null,
    enabledLinkedin?: boolean | null,
    enabledPinterest?: boolean | null,
    enabledTiktok?: boolean | null,
    enabledTwitter?: boolean | null,
    enabledYoutube?: boolean | null,
    facebook?:  {
      caption: string,
      postID?: string | null,
      postToStory?: boolean | null,
      storyID?: string | null,
      storyURL?: string | null,
      targetPageID?: string | null,
      thumbnail?: string | null,
    } | null,
    id: string,
    instagram?:  {
      caption: string,
      postID?: string | null,
      postToStory?: boolean | null,
      storyID?: string | null,
      targetAccountID?: string | null,
      thumbnail?: string | null,
    } | null,
    linkedin?:  {
      caption?: string | null,
      mediaDescription?: string | null,
      postID?: string | null,
    } | null,
    pinterest?:  {
      boardID?: string | null,
      description?: string | null,
      link?: string | null,
      pinID?: string | null,
      thumbnailURL?: string | null,
      title?: string | null,
    } | null,
    postedTime?: number | null,
    postedToFacebook?: boolean | null,
    postedToInstagram?: boolean | null,
    postedToLinkedin?: boolean | null,
    postedToPinterest?: boolean | null,
    postedToTiktok?: boolean | null,
    postedToTwitter?: boolean | null,
    postedToYoutube?: boolean | null,
    s3Key?: string | null,
    s3KeyResized?: string | null,
    scheduledPostTime?: number | null,
    status: SocialManPostStatus,
    tiktok?:  {
      caption?: string | null,
      contentDisclosureBrandedContent?: boolean | null,
      contentDisclosureEnabled?: boolean | null,
      contentDisclosureYourBrand?: boolean | null,
      postID?: string | null,
      privacy?: SocialManTiktokPrivacyStatus | null,
      title?: string | null,
      usersCanComment?: boolean | null,
      usersCanDuet?: boolean | null,
      usersCanStitch?: boolean | null,
      videoCoverTimestampMs?: number | null,
    } | null,
    title?: string | null,
    twitter?:  {
      caption: string,
      tweetID?: string | null,
    } | null,
    updatedAt: string,
    userID: string,
    youtube?:  {
      category?: string | null,
      description?: string | null,
      privacy?: string | null,
      tags?: string | null,
      targetChannelID?: string | null,
      thumbnailURL?: string | null,
      title?: string | null,
      videoID?: string | null,
    } | null,
  } | null,
};

export type OnUpdateTaskReactionSubscriptionVariables = {
  filter?: ModelSubscriptionTaskReactionFilterInput | null,
};

export type OnUpdateTaskReactionSubscription = {
  onUpdateTaskReaction?:  {
    createdAt: string,
    mltaskexecutionresultID: string,
    type: TaskReactionType,
    updatedAt: string,
    userID: string,
  } | null,
};

export type OnUpdateUserSubscriptionVariables = {
  filter?: ModelSubscriptionUserFilterInput | null,
  id?: string | null,
};

export type OnUpdateUserSubscription = {
  onUpdateUser?:  {
    MLTaskExecutionResults?:  {
      items:  Array< {
        ANGRYReactionStats?: number | null,
        CUTEReactionStats?: number | null,
        FUNNYReactionStats?: number | null,
        GROSSReactionStats?: number | null,
        HEARTReactionStats?: number | null,
        POOPReactionStats?: number | null,
        SADReactionStats?: number | null,
        SCARYReactionStats?: number | null,
        WOWReactionStats?: number | null,
        createdAt: string,
        creditsUsed?: number | null,
        id: string,
        mltasksworkspaceID: string,
        modelName?: string | null,
        modelsID: string,
        privacyLevel?: TaskPrivacyLevels | null,
        status?: TaskResultStatus | null,
        taskName?: string | null,
        updatedAt: string,
        userID: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    MLTasksWorkspaces?:  {
      items:  Array< {
        createdAt: string,
        description?: string | null,
        id: string,
        name?: string | null,
        updatedAt: string,
        userID: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    ModelInputPresets?:  {
      items:  Array< {
        cost?: number | null,
        createdAt: string,
        description?: string | null,
        id: string,
        isOpenSource?: boolean | null,
        isPublic?: boolean | null,
        modelName?: string | null,
        modelsID: string,
        presetName?: string | null,
        taskName?: string | null,
        updatedAt: string,
        usage?: number | null,
        userID: string,
        username?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    SocialManPosts?:  {
      items:  Array< {
        createdAt: string,
        description?: string | null,
        enabledFacebook?: boolean | null,
        enabledInstagram?: boolean | null,
        enabledLinkedin?: boolean | null,
        enabledPinterest?: boolean | null,
        enabledTiktok?: boolean | null,
        enabledTwitter?: boolean | null,
        enabledYoutube?: boolean | null,
        id: string,
        postedTime?: number | null,
        postedToFacebook?: boolean | null,
        postedToInstagram?: boolean | null,
        postedToLinkedin?: boolean | null,
        postedToPinterest?: boolean | null,
        postedToTiktok?: boolean | null,
        postedToTwitter?: boolean | null,
        postedToYoutube?: boolean | null,
        s3Key?: string | null,
        s3KeyResized?: string | null,
        scheduledPostTime?: number | null,
        status: SocialManPostStatus,
        title?: string | null,
        updatedAt: string,
        userID: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    completedTasksCount?: number | null,
    createdAt: string,
    createdPresetCount?: number | null,
    credits?: number | null,
    currentSubscriptionPlan?: SubscriptionPlan | null,
    email?: string | null,
    facebook?:  {
      instagramsData:  Array< {
        accessToken?: string | null,
        biography?: string | null,
        connectedPageID?: string | null,
        expiresOn?: number | null,
        followersCount?: number | null,
        followsCount?: number | null,
        id?: string | null,
        igID?: string | null,
        mediaCount?: number | null,
        name?: string | null,
        profilePictureURL?: string | null,
        username?: string | null,
      } | null >,
      pagesData:  Array< {
        accessToken?: string | null,
        category?: string | null,
        expiresOn?: number | null,
        id?: string | null,
        name?: string | null,
        picture?: string | null,
      } | null >,
      userData:  {
        accessToken?: string | null,
        expiresOn?: number | null,
        id?: string | null,
        name?: string | null,
        picture?: string | null,
      },
    } | null,
    google?:  {
      channelsData:  Array< {
        customUrl?: string | null,
        description?: string | null,
        id?: string | null,
        thumbnail?: string | null,
        title?: string | null,
      } | null >,
      tokenData:  {
        accessToken?: string | null,
        expiresOn?: number | null,
        idToken?: string | null,
        refreshToken?: string | null,
        scope?: string | null,
        tokenType?: string | null,
      },
      userData:  {
        email?: string | null,
        familyName?: string | null,
        givenName?: string | null,
        id?: string | null,
        locale?: string | null,
        name?: string | null,
        picture?: string | null,
        verifiedEmail?: boolean | null,
      },
    } | null,
    id: string,
    linkedin?:  {
      tokenData:  {
        accessToken: string,
        accessTokenExpirationDate: number,
        scope: string,
      },
      userData:  {
        email?: string | null,
        name?: string | null,
        picture?: string | null,
        sub?: string | null,
      },
    } | null,
    name?: string | null,
    periodEndOfLastSubscriptionCredits?: number | null,
    picture?: string | null,
    pinterest?:  {
      boardsData?:  Array< {
        createdAt?: string | null,
        description?: string | null,
        followerCount?: number | null,
        id?: string | null,
        imageCoverUrl?: string | null,
        name?: string | null,
        pinCount?: number | null,
        privacy?: string | null,
      } | null > | null,
      tokenData:  {
        accessToken: string,
        accessTokenExpirationDate: number,
        refreshToken: string,
        refreshTokenExpirationDate: number,
        scope: string,
      },
      userData:  {
        about?: string | null,
        accountType?: string | null,
        boardCount?: number | null,
        businessName?: string | null,
        followerCount?: number | null,
        followingCount?: number | null,
        id?: string | null,
        monthlyViews?: number | null,
        pinCount?: number | null,
        profileImage?: string | null,
        username?: string | null,
        websiteUrl?: string | null,
      },
    } | null,
    stripeConnectAccountID?: string | null,
    stripeConnectAccountSetupComplete?: boolean | null,
    stripeCustomerID?: string | null,
    tiktok?:  {
      tokenData:  {
        accessToken: string,
        accessTokenExpirationDate: number,
        refreshToken: string,
        refreshTokenExpirationDate: number,
        scope: string,
      },
      userData:  {
        avatarLargeURL?: string | null,
        avatarURL?: string | null,
        avatarURL100?: string | null,
        bioDescription?: string | null,
        commentDisabled?: boolean | null,
        displayName?: string | null,
        duetDisabled?: boolean | null,
        followerCount?: number | null,
        followingCount?: number | null,
        isVerified?: boolean | null,
        likesCount?: number | null,
        maxVideoPostDuration_sec?: number | null,
        privacyLevelOptions?: Array< string | null > | null,
        profileDeepLink?: string | null,
        stitchDisabled?: boolean | null,
        username?: string | null,
        videoCount?: number | null,
      },
    } | null,
    twitter?:  {
      tokenData:  {
        accessToken?: string | null,
        accessTokenSecret?: string | null,
        codeVerifier?: string | null,
        expiresOn?: number | null,
        oauthTokenSecret?: string | null,
        refreshToken?: string | null,
      },
      userData:  {
        description?: string | null,
        id?: string | null,
        name?: string | null,
        profilePictureURL?: string | null,
        username?: string | null,
        verifiedType?: TwitterVerifiedType | null,
      },
    } | null,
    updatedAt: string,
    username?: string | null,
  } | null,
};
